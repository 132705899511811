import { cn } from "lib/utils";
import { PlateContent, useEditorRef } from "@udecode/plate";
import { LinkFloatingToolbar } from "../../EditorView/Menus/LinkFloatingToolbar";
import React, { useCallback, useMemo, useRef } from "react";
import FixedToolbar from "../../EditorView/Menus/FixedToolbar";
import { useEditorDocData } from "../../../providers/RequirementContentEditorProvider";
import Spinner from "../../common/Spinner";
import { PlateEditor, Value, focusEditor } from "@udecode/plate-common";
import { OdoCursorData } from "odo";
import { useHooksComments } from "lib/plate/plugins/useHooksComments";
import { PlateYjsEditorProps } from "@udecode/plate-yjs";
import { RemoteCursorOverlay } from "components/cursors/RemoteCursorOverlay";
import {
  CursorCaret,
  CursorSelectionRect,
} from "components/cursors/RemoteCursor";
import Columns from "components/common/containers/Columns";
import {
  RightEditorToolbar,
  useRightEditorContent,
} from "./panels/RightEditorPanel";
import ProposalDragDrop from "./ProposalDragDrop";
import {
  LeftEditorToolbar,
  useLeftEditorContent,
} from "./panels/LeftEditorPanel";
import { SplitPaneContainer } from "components/common/containers/split-pane-container/SplitPaneContainer";
import { SplitPane } from "components/common/containers/split-pane-container/SplitPane";
import Pill from "components/common/Pill";
import { useChoose } from "providers/AlertProvider";
import Icon from "components/common/Icon";
import Rows from "components/common/containers/Rows";
import useBeforeUnload from "hooks/useBeforeUnload";
import CommentList from "components/comments/CommentList";
import SectionMenuOverlay from "components/EditorView/SectionMenuOverlay";
import SectionHighlightOverlay from "components/EditorView/SectionHighlightOverlay";
import useImageUpload from "api/useImageUpload";

interface ProposalAnswerEditorContentProps {
  proposalId: string;
  containerRef: React.RefObject<HTMLDivElement>;
  canvasRef: React.RefObject<HTMLDivElement>;
  isTestEditor?: boolean;
}

const ProposalAnswerEditorContent: React.FC<
  ProposalAnswerEditorContentProps
> = ({ containerRef, canvasRef, isTestEditor, proposalId }) => {
  const { status, reconnect } = useEditorDocData();
  const editor = useEditorRef<Value, PlateEditor & PlateYjsEditorProps>();
  const editorBackgroundRef = useRef<HTMLDivElement>(null);
  const leftContent = useLeftEditorContent();
  const choose = useChoose();
  const { insertImage } = useImageUpload(proposalId);
  const { visible: rightVisible, element: rightContent } =
    useRightEditorContent();
  useHooksComments();

  useBeforeUnload((event) => {
    if (status === "reconnecting") {
      event.preventDefault();
    }
    return undefined;
  });

  const handleClickBelow = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      // When the user clicks below the editor, we want to focus the editor
      // at the end. Plus, if the editor does not end in a paragraph, we want
      // to add a paragraph at the end.
      if (!editor.selection || editor.node(editor.selection)) {
        if (editor.children.length > 0) {
          let lastNode = editor.children[editor.children.length - 1];
          if (lastNode.type !== "p") {
            editor.insertNodes(
              {
                // @ts-ignore
                type: "p",
                children: [{ text: "" }],
              },
              { at: [editor.children.length] }
            );
          }
          const end = editor.end([editor.children.length - 1]);
          focusEditor(editor, end);
        } else {
          focusEditor(editor);
        }
      }
    },
    [editor]
  );

  const handleClickConnectionLost = useCallback(async () => {
    const choice = await choose("", {
      dismissId: "cancel",
      body: (
        <Rows className="items-center gap-md max-w-[260px] mx-auto text-center">
          <Icon name="signal-bars-slash" size="xlarge" />
          <h1 className="font-semibold text-lg">Connection Lost</h1>
          <p>We lost connection to the server.</p>
          <p>Changes will not be saved until the connection is restored.</p>
        </Rows>
      ),
      choices: [
        {
          id: "contact",
          icon: "envelope",
          text: "Contact Support",
        },
        {
          id: "again",
          icon: "refresh",
          text: "Reconnect",
        },
      ],
    });

    switch (choice) {
      case "contact":
        window.open("mailto:support@odo.do");
        break;
      case "again":
        reconnect();
        break;
      case "cancel":
        break;
    }
  }, [choose, reconnect]);

  const handleDrop = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();

      for (const file of e.dataTransfer.files) {
        if (file.type.startsWith("image/")) {
          insertImage(file, { atEnd: true });
          return;
        }
      }
    },
    [insertImage]
  );

  const content = useMemo(
    () => (
      <div className="grow flex flex-row relative border rounded-sm overflow-hidden">
        <div className="grow flex absolute top-0 bottom-0 left-0 right-0 flex-col justify-stretch">
          {status === "not-found" ? (
            <div className="text-center">Not Found</div>
          ) : (
            <>
              <FixedToolbar />
              <ProposalDragDrop>
                <Columns>
                  {!isTestEditor && <LeftEditorToolbar />}
                  <SplitPaneContainer
                    className="grow"
                    localStorageKey="main-content-panels"
                  >
                    {!isTestEditor && leftContent && (
                      <SplitPane
                        uniqueId="left-panel"
                        className="border-r overflow-hidden grow flex"
                        minSize={240}
                        defaultSize={500}
                        maxSize={700}
                      >
                        {leftContent}
                      </SplitPane>
                    )}
                    <SplitPane
                      className="overflow-x-hidden bg-accent drag-scrollable"
                      uniqueId="content"
                      minSize={200}
                      priority={1}
                      ref={canvasRef as any}
                    >
                      <div
                        ref={editorBackgroundRef}
                        onDrop={handleDrop}
                        onDragOver={(e) => {
                          e.preventDefault();
                        }}
                        className={cn(
                          "flex flex-row min-h-[80vh] bg-accent justify-center w-full"
                        )}
                      >
                        <Columns className="bg-background overflow-x-auto">
                          <div
                            className={cn(
                              "basis-0 grow-[7] pt-xl relative overflow-x-visible max-w-[800px] w-[99999999px]",
                              isTestEditor && "pr-0"
                            )}
                            ref={containerRef as any}
                          >
                            <PlateContent
                              id="main"
                              /**
                               * Don't add top padding to the first element
                               * Don't let firefox add an outline when focusing
                               */
                              className="[&>*:first-child]:pt-0 focus:outline-none"
                              renderEditable={(editable) => {
                                return (
                                  <>
                                    {editable}
                                    <RemoteCursorOverlay<OdoCursorData>
                                      className="pt-3xl"
                                      onRenderCaret={CursorCaret}
                                      onRenderSelectionRect={
                                        CursorSelectionRect
                                      }
                                      containerRef={containerRef}
                                    />
                                    <LinkFloatingToolbar />
                                    <SectionHighlightOverlay />
                                  </>
                                );
                              }}
                            />
                            <div
                              className="h-[90px]"
                              onClick={handleClickBelow}
                            />
                          </div>
                        </Columns>
                        <CommentList
                          hidden={isTestEditor}
                          className="grow-[4] basis-0 pt-[20px] bg-accent"
                        >
                          <SectionMenuOverlay />
                        </CommentList>
                      </div>
                    </SplitPane>
                    {!isTestEditor && rightContent && (
                      <SplitPane
                        uniqueId="right-panel"
                        hidden={!rightVisible}
                        className={cn("border-l flex")}
                        maxSize={1000}
                        defaultSize={500}
                        minSize={240}
                      >
                        {rightContent}
                      </SplitPane>
                    )}
                  </SplitPaneContainer>
                  {!isTestEditor && <RightEditorToolbar />}
                </Columns>
              </ProposalDragDrop>
              {status === "connecting" && (
                <div className="text-center flex gap-sm justify-center items-center absolute inset-0 bg-background opacity-90">
                  <Spinner />
                  Connecting...
                </div>
              )}
              {status === "reconnecting" && (
                <div className="text-center flex gap-sm justify-center items-center absolute bottom-sm left-0 right-0">
                  <Pill
                    text="Connection Lost"
                    className="bg-destructive text-background cursor-pointer"
                    icon="signal-bars-slash"
                    onClick={handleClickConnectionLost}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    ),
    [
      canvasRef,
      containerRef,
      handleClickBelow,
      handleClickConnectionLost,
      isTestEditor,
      leftContent,
      rightContent,
      rightVisible,
      status,
      handleDrop,
    ]
  );
  return <>{content}</>;
};

export default ProposalAnswerEditorContent;
