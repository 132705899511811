import CenteredContainer from "components/common/containers/CenteredContainer";
import Rows from "components/common/containers/Rows";
import { SaveState } from "components/common/forms/SavedStatusIndicator";
import Icon from "components/common/Icon";
import { Category, LibraryItem } from "hooks/useContentLibrary";
import { cn } from "lib/utils";
import { ReactNode } from "react";
import ProjectDetailsView from "./ProjectDetailsView";
import PersonDetailsView from "./PeopleDetailsView";
import OverviewDetailsView from "./OverviewDetailsView";
import CoverLetterDetailsView from "./CoverLetterDetailsView";
import SubcontractorDetailsView from "./SubcontractorDetailsView";

interface ContentLibraryProjectDetailsProps {
  proposalId: string | null;
  activeCategory: Category;
  selectedItem: LibraryItem | null;
  setSelectedItemSaveState: (state: SaveState) => void;
  updateItem: (item: LibraryItem) => void;
  deleteItem: () => Promise<void>;
  className?: string;
}

const ContentLibraryProjectDetails: React.FC<
  ContentLibraryProjectDetailsProps
> = ({
  activeCategory,
  selectedItem,
  proposalId,
  setSelectedItemSaveState: setSelectedProjectSaveState,
  updateItem,
  deleteItem,
  className,
}) => {
  let content: ReactNode = null;
  if (selectedItem) {
    switch (activeCategory) {
      case "projects":
        content = (
          <ProjectDetailsView
            key={selectedItem.id}
            proposalId={proposalId}
            projectId={selectedItem.id}
            deleteItem={deleteItem}
            onChanged={(project) =>
              updateItem({
                id: project.id!,
                title: project.title ?? "",
                customer: project.customer ?? "",
                location: project.location ?? "",
                startMonth: project.start_month ?? null,
                startYear: project.start_year ?? null,
              })
            }
            onSaveStateChanged={setSelectedProjectSaveState}
          />
        );
        break;
      case "people":
        content = (
          <PersonDetailsView
            key={selectedItem.id}
            proposalId={proposalId}
            personId={selectedItem.id}
            deleteItem={deleteItem}
            onChanged={(person) =>
              updateItem({
                id: person.id!,
                name: person.name ?? "",
                title: person.title ?? "",
              })
            }
            onSaveStateChanged={setSelectedProjectSaveState}
          />
        );
        break;
      case "overviews":
        content = (
          <OverviewDetailsView
            key={selectedItem.id}
            proposalId={proposalId}
            overviewId={selectedItem.id}
            deleteItem={deleteItem}
            onChanged={(overview) =>
              updateItem({
                id: overview.id!,
                topic: overview.topic ?? "",
                created_at: overview.created_at ?? "",
              })
            }
            onSaveStateChanged={setSelectedProjectSaveState}
          />
        );
        break;
      case "cover-letters":
        content = (
          <CoverLetterDetailsView
            key={selectedItem.id}
            proposalId={proposalId}
            coverLetterId={selectedItem.id}
            deleteItem={deleteItem}
            onChanged={(coverLetter) =>
              updateItem({
                id: coverLetter.id!,
                topic: coverLetter.topic ?? "",
                created_at: coverLetter.created_at ?? "",
              })
            }
            onSaveStateChanged={setSelectedProjectSaveState}
          />
        );
        break;
      case "subcontractors":
        content = (
          <SubcontractorDetailsView
            key={selectedItem.id}
            proposalId={proposalId}
            subcontractorId={selectedItem.id}
            deleteItem={deleteItem}
            onChanged={(subcontractor) =>
              updateItem({
                id: subcontractor.id!,
                name: subcontractor.name ?? "",
                created_at: subcontractor.created_at ?? "",
              })
            }
            onSaveStateChanged={setSelectedProjectSaveState}
          />
        );
        break;
    }
  } else {
    return <NoItemSelected />;
  }

  return <Rows className={cn("basis-0 grow-[2]", className)}>{content}</Rows>;
};
const NoItemSelected = () => {
  return (
    <CenteredContainer className="border-l basis-0 grow-[2]">
      <Rows className="items-center gap-md text-secondary">
        <Icon name="square-left" size="xlarge" variant="solid" />
        <p className="text-center">
          Select an item
          <br />
          from the list on the left
        </p>
      </Rows>
    </CenteredContainer>
  );
};

export default ContentLibraryProjectDetails;
