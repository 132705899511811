import { SubcontractorDetails } from "api/Api";
import { SaveState } from "components/common/forms/SavedStatusIndicator";
import useFetchedData from "hooks/useFetchedData";
import { useApiClient } from "providers/ApiClientProvider";
import { FC } from "react";
import BaseItemDetailsView from "./BaseItemDetailsView";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import Input from "components/common/forms/Input";
import CollaborativeTextArea from "components/common/forms/CollaborativeTextArea";

interface SubcontractorDetailsViewProps {
  subcontractorId: string;
  proposalId: string | null;
  onChanged: (person: SubcontractorDetails) => void;
  onSaveStateChanged?: (state: SaveState) => void;
  deleteItem: () => Promise<void>;
}

const SubcontractorDetailsView: FC<SubcontractorDetailsViewProps> = ({
  subcontractorId,
  proposalId,
  onChanged,
  onSaveStateChanged,
  deleteItem,
}) => {
  const apiClient = useApiClient();
  const [details, setDetails, { error }] = useFetchedData(async () => {
    const result = await apiClient.rfp.rfpContentLibrarySubcontractorRead(
      subcontractorId,
      {
        // @ts-ignore
        query: { proposal_id: proposalId },
      }
    );
    return result.data;
  }, [subcontractorId]);

  return (
    <BaseItemDetailsView<SubcontractorDetails>
      details={details}
      setDetails={setDetails}
      saveItem={async (details) => {
        await apiClient.rfp.rfpContentLibrarySubcontractorUpdate(
          subcontractorId,
          details,
          // @ts-ignore
          { query: { proposal_id: proposalId } }
        );
      }}
      onChanged={onChanged}
      onSaveStateChanged={onSaveStateChanged}
      deleteItem={deleteItem}
      loadingError={error}
      renderChildren={(details, setDetails) => (
        <>
          <LabeledFormInput label="Name">
            <Input
              placeholder="The name of this subcontractor"
              value={details.name}
              onChange={(e) => setDetails({ ...details, name: e.target.value })}
            />
          </LabeledFormInput>
          <LabeledFormInput label="Overview" className="grow overflow-hidden">
            <CollaborativeTextArea
              placeholder="A brief overview of the subcontractor"
              className="h-[600px]"
              docId={details.overview_id!}
              key={details.overview_id!}
            />
          </LabeledFormInput>
        </>
      )}
    />
  );
};

export default SubcontractorDetailsView;
