import { useCallback, useState, useRef, useEffect, useMemo } from "react";
import { OdoTD, OdoTable } from "odo";
import { createContext, useContext, ReactNode } from "react";
import { BaseSelection, Path } from "slate";
import { ReactEditor } from "slate-react";
import {
  ELEMENT_PARAGRAPH,
  ELEMENT_TD,
  ELEMENT_TR,
  useEditorRef,
  useEditorSelection,
} from "@udecode/plate";

interface TableElementProviderProps {
  table: OdoTable;
}

interface TableSelectionRange {
  startCol: number;
  startRow: number;
  endCol: number;
  endRow: number;
}

/**
 * The selection state of the table
 *
 * "table" - Selection is the entire table
 * "range" - Selection at least one cell - if only one cell is selected,
 *          then the selection is of text within the cell (not the whole cell)
 * "none" - No selection
 */
export type TableSelection = TableSelectionRange | "table" | "none";

interface TableStateData {
  getCellState: (cell: OdoTD) => {
    row: number;
    col: number;
    width: number;
    selected: boolean;
  };
  totalWidth: number;
  colLefts: number[];
  setColSizes: React.Dispatch<React.SetStateAction<number[]>>;
  saveColSizes: () => void;
  selection: TableSelection;

  insertRow: (above: boolean) => void;
  insertColumn: (before: boolean) => void;
  deleteTable: () => void;
  deleteColumns: () => void;
  deleteRows: () => void;

  contextMenuActive: boolean;
  setContextMenuActive: React.Dispatch<React.SetStateAction<boolean>>;
}

const TableElementContext = createContext<TableStateData | null>(null);

export const TableElementProvider = ({
  table,
  children,
}: TableElementProviderProps & { children: ReactNode }) => {
  const editor = useEditorRef();
  const selection = useEditorSelection();
  const colSizes = (() => {
    if (table.colSizes) return table.colSizes;

    const colCount = table.children[0].children.length;
    const defaultTableWidth = 630;
    const colSize = defaultTableWidth / colCount;
    return Array.from({ length: colCount }, () => colSize);
  })();
  const [localColSizes, setLocalColSizes] = useState(colSizes);
  const colSizesRef = useRef(localColSizes);
  const [contextMenuActive, setContextMenuActive] = useState(false);

  // Handle borders:
  // 1. Start at 1 for the left most border
  // 2. Add 1 extra for the right border of each column
  const totalWidth = localColSizes.reduce((acc, val) => acc + val, 0);
  const colLefts: number[] = [];
  for (let i = 0; i < localColSizes.length; i++) {
    if (colLefts.length === 0) {
      colLefts.push(localColSizes[0] - 2);
    } else {
      colLefts.push(colLefts[i - 1] + localColSizes[i]);
    }
  }
  const tablePath = ReactEditor.findPath(editor as any, table);
  const tableSize = useMemo(
    () => ({
      rows: table.children.length,
      cols: colSizes.length,
    }),
    [table.children, colSizes]
  );
  const tableSelection = tableSelectionFromSelection(
    tablePath,
    tableSize,
    selection
  );

  useEffect(() => {
    colSizesRef.current = localColSizes;
  }, [localColSizes]);

  useEffect(() => {
    // If the props change, update the local state
    setLocalColSizes(colSizes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(colSizes)]);

  const getCellState = useCallback(
    (td: OdoTD) => {
      const path = ReactEditor.findPath(editor as any, td);
      if (!path) {
        throw new Error("Could not find path for element");
      }
      if (path.length < 3) {
        throw new Error("Invalid path length");
      }
      const col = path[path.length - 1] + 1;
      const row = path[path.length - 2] + 1;
      const width = localColSizes[col - 1] ?? 50;
      let selected: boolean;
      switch (tableSelection) {
        case "none":
          selected = false;
          break;
        case "table":
          selected = true;
          break;
        default:
          if (
            cellCountInSelection(tableSelection) === 1 &&
            !contextMenuActive
          ) {
            selected = false;
          } else {
            selected = selectionRangeContains(tableSelection, col - 1, row - 1);
          }
      }
      return {
        col,
        row,
        width,
        selected,
      };
    },
    [localColSizes, tableSelection, contextMenuActive, editor]
  );

  const saveColSizes = useCallback(() => {
    if (colSizesRef.current === undefined) {
      return;
    }
    // @ts-ignore
    // table.colSizes = localColSizes;
    editor.setNodes<OdoTable>(
      { colSizes: colSizesRef.current },
      { at: ReactEditor.findPath(editor as any, table) }
    );
  }, [colSizesRef, editor, table]);

  const deleteTable = useCallback(() => {
    editor.delete({ at: tablePath });
  }, [editor, tablePath]);

  const insertRow = useCallback(
    (above: boolean) => {
      const colCount = colSizesRef.current.length;
      const newRow = {
        type: ELEMENT_TR,
        children: Array.from({ length: colCount }).map(() => ({
          type: ELEMENT_TD,
          children: [{ type: ELEMENT_PARAGRAPH, text: "" }],
        })),
      };
      let row: number;
      if (above) {
        row = minRowFromSelection(tableSelection);
      } else {
        row = maxRowFromSelection(tableSelection, tableSize.rows) + 1;
      }
      editor.insertNodes(newRow, { at: tablePath.concat([row]) });
    },
    [tableSelection, tablePath, tableSize, editor]
  );

  const insertColumn = useCallback(
    (before: boolean) => {
      let col: number;
      let newSize: number;
      if (before) {
        col = minColFromSelection(tableSelection);
        newSize = colSizesRef.current[col];
      } else {
        col = maxColFromSelection(tableSelection, tableSize.cols);
        newSize = colSizesRef.current[col];
        col += 1;
      }

      // Add the elements
      for (let i = 0; i < tableSize.rows; i++) {
        editor.insertNodes(
          {
            type: ELEMENT_TD,
            // @ts-ignore
            children: [{ type: ELEMENT_PARAGRAPH, text: "" }],
          },
          { at: tablePath.concat([i, col]) }
        );
      }

      // Add the new column size
      editor.setNodes<OdoTable>(
        {
          colSizes: [
            ...colSizesRef.current.slice(0, col),
            newSize,
            ...colSizesRef.current.slice(col),
          ],
        },
        { at: tablePath }
      );
    },
    [tableSize, tablePath, tableSelection, colSizesRef, editor]
  );

  const deleteRows = useCallback(() => {
    switch (tableSelection) {
      case "none":
        return;
      case "table":
        editor.delete({ at: tablePath });
        return;
      default:
        break;
    }
    const rows = allRowsInSelection(tableSelection);
    if (rows.length === 0) {
      return;
    }
    const deleteIndex = rows[0];
    for (let row = 0; row < rows.length; row++) {
      editor.delete({ at: tablePath.concat([deleteIndex]) });
    }
  }, [tableSelection, tablePath, editor]);

  const deleteColumns = useCallback(() => {
    switch (tableSelection) {
      case "none":
        return;
      case "table":
        editor.delete({ at: tablePath });
        return;
      default:
        break;
    }

    const cols = allColsInSelection(tableSelection);
    if (cols.length === 0) {
      return;
    }
    const deleteIndex = cols[0];

    // Delete the elements
    for (let col = 0; col < cols.length; col++) {
      for (let i = 0; i < tableSize.rows; i++) {
        editor.delete({ at: tablePath.concat([i, deleteIndex]) });
      }
    }

    // Delete the colSizes
    editor.setNodes<OdoTable>(
      {
        colSizes: colSizesRef.current.filter((_, i) => !cols.includes(i)),
      },
      { at: tablePath }
    );
  }, [tableSelection, tablePath, tableSize, colSizesRef, editor]);

  return (
    <TableElementContext.Provider
      value={{
        getCellState,
        totalWidth,
        colLefts,
        saveColSizes,
        setColSizes: setLocalColSizes,
        selection: tableSelection,
        deleteTable,
        insertRow,
        insertColumn,
        deleteColumns,
        deleteRows,
        contextMenuActive,
        setContextMenuActive,
      }}
    >
      {children}
    </TableElementContext.Provider>
  );
};

export const useTableState = () => {
  const data = useContext(TableElementContext);
  if (!data) {
    throw new Error("useTableState must be used within a TableElementProvider");
  }
  return data;
};

const selectionRangeContains = (
  range: TableSelectionRange,
  col: number,
  row: number
) => {
  return (
    range.startCol <= col &&
    range.endCol >= col &&
    range.startRow <= row &&
    range.endRow >= row
  );
};

export const cellCountInSelection = (tableSelection: TableSelection) => {
  if (tableSelection === "none") {
    return 0;
  }
  if (tableSelection === "table") {
    return Number.MAX_SAFE_INTEGER;
  }
  return (
    (tableSelection.endCol - tableSelection.startCol + 1) *
    (tableSelection.endRow - tableSelection.startRow + 1)
  );
};

export const colCountInSelection = (tableSelection: TableSelection) => {
  switch (tableSelection) {
    case "none":
      return 0;
    case "table":
      return Number.MAX_SAFE_INTEGER;
    default:
      return tableSelection.endCol - tableSelection.startCol + 1;
  }
};

export const rowCountInSelection = (tableSelection: TableSelection) => {
  switch (tableSelection) {
    case "none":
      return 0;
    case "table":
      return Number.MAX_SAFE_INTEGER;
    default:
      return tableSelection.endRow - tableSelection.startRow + 1;
  }
};

const tableSelectionFromSelection = (
  tablePath: Path,
  tableSize: { rows: number; cols: number },
  selection: BaseSelection | null
): TableSelection => {
  if (!selection) {
    return "none";
  }

  let earlierPath: Path;
  let laterPath: Path;
  if (Path.compare(selection.anchor.path, selection.focus.path) < 0) {
    earlierPath = selection.anchor.path;
    laterPath = selection.focus.path;
  } else {
    earlierPath = selection.focus.path;
    laterPath = selection.anchor.path;
  }

  if (laterPath[0] < tablePath[0]) {
    // End of selection is before the table
    return "none";
  }

  if (earlierPath[0] > tablePath[0]) {
    // Start of selection is after the table
    return "none";
  }

  if (earlierPath[0] < tablePath[0] && laterPath[0] >= tablePath[0]) {
    // Selection starts before the table and then at least goes into it.
    // Consider the whole table selected
    return "table";
  }

  if (earlierPath[2] === laterPath[2] && earlierPath[1] === laterPath[1]) {
    // Selection is within a single cell
    return {
      startCol: earlierPath[2],
      startRow: earlierPath[1],
      endCol: earlierPath[2],
      endRow: earlierPath[1],
    };
  }

  // We now know that both the start and end of the selection are within the table
  // and that they are both within different cells so we will look for the range

  const range = {
    startCol: Math.min(earlierPath[2], laterPath[2]),
    startRow: Math.min(earlierPath[1], laterPath[1]),
    endCol: Math.max(earlierPath[2], laterPath[2]),
    endRow: Math.max(earlierPath[1], laterPath[1]),
  };

  // const range = {
  //   startCol: earlierPath[2],
  //   startRow: earlierPath[1],
  //   endCol: laterPath[2],
  //   endRow: laterPath[1],
  // };

  if (
    range.startCol === 0 &&
    range.startRow === 0 &&
    range.endCol === tableSize.cols - 1 &&
    range.endRow === tableSize.rows - 1
  ) {
    return "table";
  }

  return range;
};

const minRowFromSelection = (selection: TableSelection) => {
  switch (selection) {
    case "none":
    case "table":
      return 0;
    default:
      return selection.startRow;
  }
};

const maxRowFromSelection = (selection: TableSelection, totalRows: number) => {
  switch (selection) {
    case "none":
    case "table":
      return totalRows - 1;
    default:
      return selection.endRow;
  }
};

const minColFromSelection = (selection: TableSelection) => {
  switch (selection) {
    case "none":
    case "table":
      return 0;
    default:
      return selection.startCol;
  }
};

const maxColFromSelection = (selection: TableSelection, totalCols: number) => {
  switch (selection) {
    case "none":
    case "table":
      return totalCols - 1;
    default:
      return selection.endCol;
  }
};

export const allColsInSelection = (
  selection: TableSelectionRange
): number[] => {
  const min = minColFromSelection(selection);
  const max = maxColFromSelection(selection, Number.MAX_SAFE_INTEGER);
  return Array.from({ length: max - min + 1 }, (_, i) => i + min);
};

export const allRowsInSelection = (
  selection: TableSelectionRange
): number[] => {
  const min = minRowFromSelection(selection);
  const max = maxRowFromSelection(selection, Number.MAX_SAFE_INTEGER);
  return Array.from({ length: max - min + 1 }, (_, i) => i + min);
};
