import { useOptionalAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { FC, ReactNode, useEffect } from "react";
import { Navigate, PathRouteProps, useLocation } from "react-router-dom";
import ProposalsRoute from "./home/ProposalsRoute";
import VerifyRoute from "./verify/VerifyRoute";
import ProposalDetailsRoute from "./proposals/ProposalDetailsRoute";
import PastProposalsRoute from "./proposals/past/PastProposalsRoute";
import RFPsRoute from "./rfps/RFPsRoute";
import { setRedirectAfterLogin } from "utils";
import {
  useApiClient,
  useDoExtraWorkBeforeLoading,
} from "providers/ApiClientProvider";

import * as Sentry from "@sentry/react";
import CompanyProfileRoute from "./account/CompanyProfileRoute";
import ContentLibraryRoute from "./content/ContentLibraryRoute";

interface UserRouteProps extends PathRouteProps {
  allowUnverified?: boolean;
}

const userRoutes: UserRouteProps[] = [
  {
    path: "/",
    element: <RFPsRoute />,
  },
  {
    path: "/rfps/",
    element: <Navigate to="/" />,
  },
  {
    path: "/account/company-profile/",
    element: <CompanyProfileRoute />,
  },
  {
    path: "/proposals/",
    element: <ProposalsRoute />,
  },
  {
    path: "/proposals/past/",
    element: <ProposalsRoute />,
  },
  {
    path: "/verify",
    element: <VerifyRoute />,
    allowUnverified: true,
  },
  {
    path: "/proposal/:proposalId",
    element: <ProposalDetailsRoute />,
  },
  {
    path: "/proposal/:proposalId/r/:requirementId/",
    element: <ProposalDetailsRoute />,
  },
  {
    path: "/past-proposals/",
    element: <PastProposalsRoute />,
  },
  {
    path: "/content/",
    element: <ContentLibraryRoute />,
  },
  {
    path: "/content/:category/",
    element: <ContentLibraryRoute />,
  },
  {
    path: "/content/:category/:itemId/",
    element: <ContentLibraryRoute />,
  },
];

const RequireUser: FC<{ children: ReactNode; allowUnverified: boolean }> = ({
  children,
  allowUnverified,
}) => {
  const apiClient = useApiClient();
  const user = useOptionalAuthenticatedUser();
  const location = useLocation();
  const finishLoading = useDoExtraWorkBeforeLoading();

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const code = search.get("c");
    if (code) {
      const applyCode = async () => {
        try {
          await apiClient.applyCode.applyCodeCreate({
            code,
          });
          // Remove the code from the URL
          search.delete("c");
          window.history.replaceState(
            {},
            "",
            `${window.location.pathname}?${search.toString()}`
          );
        } catch (e) {
          console.error("Failed to apply code", e);
          Sentry.captureException(e);
        } finally {
          finishLoading();
        }
      };
      applyCode();
    } else {
      finishLoading();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiClient.applyCode, location]);

  if (user === null) {
    setRedirectAfterLogin();
    return <Navigate to="/login" />;
  }
  if (user === undefined) {
    return null;
  }
  if (!user.isVerified && !allowUnverified) {
    return <Navigate to="/verify" />;
  }
  return <>{children}</>;
};

const wrapUserRoutes = (routes: UserRouteProps[]) => {
  return routes.map((route) => {
    return {
      ...route,
      element: (
        <RequireUser allowUnverified={route.allowUnverified ?? false}>
          {route.element}
        </RequireUser>
      ),
    };
  });
};

export default wrapUserRoutes(userRoutes);
