import { FC } from "react";
import { cn } from "../../../lib/utils";

interface FormProps extends React.FormHTMLAttributes<HTMLFormElement> {}

const Form: FC<FormProps> = ({ children, className, ...props }) => {
  return (
    <form
      className={cn(
        "tablet:popover p-2xl tablet:p-4xl tablet:rounded-sm w-full tablet:min-w-[420px] max-w-[500px] flex flex-col gap-lg max-h-[90vh] overflow-y-auto",
        className
      )}
      {...props}
    >
      {children}
    </form>
  );
};

export default Form;
