import useCommentsLayout from "hooks/comments/useCommentLayout";
import { FC, useEffect, useState } from "react";
import CommentThreadView from "./CommentThreadView";
import { cn } from "lib/utils";

interface CommentListProps {
  hidden?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const CommentList: FC<CommentListProps> = ({
  className,
  children,
  hidden = false,
}) => {
  const { threads, setThreadRef, threadsContainerRef, areFullCommentsVisible } =
    useCommentsLayout();
  const [hasNoComments, setHasNoComments] = useState(false);
  const [focusedThreadId, setFocusedThreadId] = useState<string | null>(null);
  const [canFocusThread, setCanFocusThread] = useState(false);
  useEffect(() => {
    if (hasNoComments) {
      setCanFocusThread(false);
    } else {
      setTimeout(() => {
        setCanFocusThread(true);
      }, 100);
    }
  }, [hasNoComments]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;
    if (threads.length === 0) {
      timeout = setTimeout(() => {
        setHasNoComments(threads.length === 0);
      }, 100);
    } else {
      setHasNoComments(false);
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [threads.length]);

  if (hidden) {
    return null;
  }

  return (
    <div
      ref={threadsContainerRef as any}
      className={cn(
        "relative transition-[max-width,min-width] ease-in-out",
        !hasNoComments ? "max-w-[460px] min-w-[54px]" : "min-w-0 max-w-0",
        className
      )}
    >
      {threads.map((thread) => (
        <CommentThreadView
          key={thread.id}
          comment={thread}
          focused={focusedThreadId === thread.id}
          canFocusThread={canFocusThread}
          setThreadRef={setThreadRef}
          areFullCommentsVisible={areFullCommentsVisible}
          onFocusChange={(focused) => {
            if (focused) {
              setFocusedThreadId(thread.id);
            } else {
              setFocusedThreadId(null);
            }
          }}
        />
      ))}
      {children}
    </div>
  );
};

export default CommentList;
