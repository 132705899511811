import Button from "components/common/Button";
import Rows from "components/common/containers/Rows";
import { cn } from "lib/utils";
import SectionDetailsPanel from "./SectionDetailsPanel";
import RFPCoveragePanel from "./RFPCoveragePanel";
import {
  RightPanel,
  useEditorDocData,
} from "providers/RequirementContentEditorProvider";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import ContentLibraryPanel from "./ContentLibraryPanel";
import { useMemo } from "react";

export const RightEditorToolbar: React.FC = () => {
  const { activeRightPanel, setActiveRightPanel } = useEditorDocData();
  const { isWriter } = useAuthenticatedUser();

  const getClickHandler = (panel: RightPanel) => () => {
    if (activeRightPanel === panel) {
      setActiveRightPanel(null);
    } else {
      setActiveRightPanel(panel);
    }
  };

  return (
    <Rows className="shrink-0 border-l">
      {isWriter && (
        <Button
          tooltip="Section Details"
          icon="memo-circle-info"
          size="large"
          variant="toolbar"
          className={cn(
            activeRightPanel === "section-details" &&
              "border-r-primary hover:border-r-primary"
          )}
          onClick={getClickHandler("section-details")}
        />
      )}
      <Button
        tooltip="RFP Coverage"
        icon="file"
        iconVariant="solid"
        size="large"
        variant="toolbar"
        className={cn(
          activeRightPanel === "outline-verification" &&
            "border-r-primary hover:border-r-primary"
        )}
        onClick={getClickHandler("outline-verification")}
      />
      <Button
        tooltip="Content Library"
        icon="book-open-cover"
        iconVariant="solid"
        size="large"
        variant="toolbar"
        className={cn(
          activeRightPanel === "content-library" &&
            "border-r-primary hover:border-r-primary"
        )}
        onClick={getClickHandler("content-library")}
      />
    </Rows>
  );
};

export const useRightEditorContent = () => {
  const { activeRightPanel } = useEditorDocData();

  const element = useMemo(() => {
    return (
      <>
        {activeRightPanel === "section-details" && <SectionDetailsPanel />}
        <Rows
          className={cn(
            "grow",
            activeRightPanel !== "outline-verification" ? "hidden" : ""
          )}
        >
          <RFPCoveragePanel />
        </Rows>
        <Rows
          className={cn(
            "grow",
            activeRightPanel !== "content-library" ? "hidden" : ""
          )}
        >
          <ContentLibraryPanel />
        </Rows>
      </>
    );
  }, [activeRightPanel]);

  return {
    visible: !!activeRightPanel,
    element,
  };
};
