import { useEditorRef, useEditorSelection } from "@udecode/plate-common";
import {
  isOdoElement,
  isOdoP,
  OdoElementType,
  getListType,
  ListType,
} from "odo";
import { useCallback, useEffect, useState } from "react";

export type FormattingType = OdoElementType | ListType | "multiple";

const useCurrentFormatting = () => {
  const editor = useEditorRef();
  const selection = useEditorSelection();

  const [marks, setMarks] = useState<Record<string, any>>({});
  // Dictionary of all element types in the current selection
  const [elementType, setElementType] = useState<FormattingType | null>(null);
  const [refresh, setRefresh] = useState(0);

  const refreshFormatting = useCallback(() => {
    setRefresh((prev) => prev + 1);
  }, []);

  useEffect(() => {
    // Update Marks
    const newMarks = editor.getMarks() || {};
    setMarks((prev) => {
      const existingJson = JSON.stringify(prev);
      const newJson = JSON.stringify(newMarks);
      if (existingJson === newJson) {
        return prev;
      }
      return newMarks;
    });

    // Update Element Types
    let newElementType: FormattingType | null = null;
    if (selection) {
      for (const [node] of editor.nodes({ at: selection })) {
        if (isOdoElement(node) && node.type) {
          let type = node.type as FormattingType;
          if (isOdoP(node)) {
            const listType = getListType(node);
            if (listType) {
              type = listType;
            } else {
              type = "p";
            }
          }
          if (newElementType === null) {
            // This is the first element type
            newElementType = type;
          } else if (newElementType !== type) {
            // There are multiple element types
            newElementType = "multiple";
            break;
          }
        }
      }
    }
    setElementType((prev) => {
      if (newElementType === prev) {
        return prev;
      }
      return newElementType;
    });
  }, [editor, selection, refresh]);

  return { marks, elementType, refreshFormatting };
};

export default useCurrentFormatting;
