import Button from "components/common/Button";
import Spinner from "components/common/Spinner";
import Input from "components/common/forms/Input";
import { cn } from "lib/utils";
import { useApiClient } from "providers/ApiClientProvider";
import { FC, ReactNode, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { message_from_exception } from "utils";
import OrgSelect from "components/PromptRefinery/OrgSelect";
import { Org } from "api/Api";
import useLocalStorage from "lib/useLocalStorage";
import { useSearchParams } from "react-router-dom";
import Toggle from "components/common/forms/Toggle";
import Rows from "components/common/containers/Rows";
import Columns from "components/common/containers/Columns";
import Spacer from "components/common/containers/Spacer";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import { EMPTY_PAGINATED_DATA } from "hooks/usePaginatedData";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";

interface DebugChunk {
  id: number;
  original_text: string;
  similarity: number;
  doc_id: string;
  group_id?: number;
  start_index_in_group?: number;
}

interface SearchResultDebug {
  raw_chunks: DebugChunk[];
}

interface ChunkWindowChunk {
  debug_info: SearchResultDebug;
}

interface ChunkWindow {
  start_index_in_group: number;
  chunks: ChunkWindowChunk[];
  doc_id?: string;
  group_id?: number;
}

interface SearchResult {
  text: string;
  similarity: number;
  debug_info: SearchResultDebug;
}

interface SearchOutput {
  results: SearchResult[];
  debug_original_windows: ChunkWindow[];
  debug_original_chunks: DebugChunk[];
}

const SearchRoute = () => {
  const apiClient = useApiClient();
  const [queryParams, setQueryParams] = useSearchParams();
  const initialQuery = queryParams.get("query") ?? null;
  const initialStrategy = queryParams.get("strategy") ?? null;
  const initialTopic = queryParams.get("topic") ?? null;
  const [initialOrg] = useState(queryParams.get("org") ?? null);
  const { isStaff } = useAuthenticatedUser();

  const [query, setQuery] = useLocalStorage(
    "search_query",
    initialQuery ?? "",
    { overrideLocalWithInitialValue: !!initialQuery }
  );
  const [topic, setTopic] = useLocalStorage(
    "search_topic",
    initialTopic === "null" ? "" : initialTopic ?? "",
    { overrideLocalWithInitialValue: !!initialTopic }
  );
  const [fakeResult, setFakeResult] = useState<string>("");
  const [testSimiliarity, setTestSimiliarity] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [searchOutput, setSearchOutput] = useLocalStorage<SearchOutput | null>(
    "search_results",
    null,
    { overrideLocalWithInitialValue: !!initialQuery }
  );
  const [searchStrategy, setSearchStrategy] = useLocalStorage<string>(
    "search_strategy",
    initialStrategy ?? "forced_paragraphs",
    { overrideLocalWithInitialValue: !!initialStrategy }
  );
  const [isLoading, setIsLoading] = useState(false);
  console.log({ initialOrg });
  const [org, setOrg] = useLocalStorage<Org | string | null>(
    "search_org",
    initialOrg ?? null,
    { overrideLocalWithInitialValue: !!initialOrg }
  );
  const [resultsType, setResultsType] = useState<null | "chunk" | "window">(
    null
  );

  useEffect(() => {
    // Clear the search query
    setQueryParams("");
  }, [setQueryParams]);

  const [encodeQueryAsDocument, setEncodeQueryAsDocument] =
    useState<boolean>(false);

  const handleSearch = async () => {
    if (!org) {
      toast.error("Please select an organization");
      return;
    }
    if (query === "") {
      toast.error("Please enter a search query");
      return;
    }
    try {
      setError(null);
      setIsLoading(true);
      const orgId = typeof org === "string" ? org : org.public_id!;
      const result = await apiClient.rfp.rfpLibSearchCreate({
        query: query,
        organization_id: orgId,
        strategy: searchStrategy,
        topic: topic,
      });
      setSearchOutput(result.data as any);
    } catch (e) {
      setError(message_from_exception(e));
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSimilarityTest = async () => {
    try {
      setTestSimiliarity(null);
      const result = await apiClient.rfp.rfpLibTestSimilarityCreate({
        query: query,
        result: fakeResult,
        encode_query_as_document: encodeQueryAsDocument,
      });
      setTestSimiliarity(result.data.similarity ?? null);
    } catch (e) {
      toast.error(message_from_exception(e));
    }
  };

  const handleTestKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSimilarityTest();
    }
  };

  const handleCopyResults = () => {
    let textToCopy = "";
    for (const result of searchOutput!.results) {
      textToCopy += `${result.text}\n---\n`;
    }

    // Copy to clipboard
    navigator.clipboard.writeText(textToCopy);
  };

  let searchResults: ReactNode;
  if (error) {
    searchResults = (
      <>
        <p className="text-destructive">{error}</p>
      </>
    );
  } else if (isLoading) {
    searchResults = (
      <>
        <Spinner />
      </>
    );
  } else if (searchOutput) {
    if (searchOutput.results.length === 0) {
      searchResults = <p>No Results</p>;
    } else {
      searchResults = (
        <>
          <ResultTypeSelector
            resultsType={resultsType}
            setResultsType={setResultsType}
            onCopyResults={handleCopyResults}
          />
          <SearchResultsTable result={searchOutput} type={resultsType} />
        </>
      );
    }
  } else {
    searchResults = (
      <>
        <p className="text-secondary">Submit a search</p>
      </>
    );
  }

  return (
    <Rows className="grow gap-md">
      <Columns className="border rounded-md p-md gap-md shrink-0">
        <Rows className="basis-0 grow gap-md">
          <h2>Database Search</h2>
          <OrgSelect value={org} onChange={setOrg} />
          {/* <SearchStrategySelect
            value={searchStrategy}
            onChange={setSearchStrategy}
          /> */}
          <Input
            icon="tag"
            value={topic}
            placeholder="Topic"
            onChange={(e) => setTopic(e.target.value)}
          />
          <Columns className="gap-md">
            <Input
              icon="magnifying-glass"
              className="grow"
              value={query}
              placeholder="Search query"
              onKeyDown={handleSearchKeyDown}
              onChange={(e) => setQuery(e.target.value)}
            />
            <Button text="Search" variant="solid" onClick={handleSearch} />
          </Columns>
        </Rows>
        {isStaff && (
          <Rows className="basis-0 grow gap-md">
            <h2>Similarity Test</h2>
            <Input
              icon="vials"
              placeholder="Fake result to test"
              value={fakeResult}
              onChange={(e) => setFakeResult(e.target.value)}
              onKeyDown={handleTestKeyDown}
            />
            <Columns className="gap-lg">
              {testSimiliarity && (
                <p>Similarity: {Math.round(testSimiliarity * 1000) / 1000}</p>
              )}
              <Spacer />
              <Columns className="flex gap-xs items-center">
                <p>As document:</p>
                <Toggle
                  on={encodeQueryAsDocument}
                  onToggle={() =>
                    setEncodeQueryAsDocument(!encodeQueryAsDocument)
                  }
                  className="text-2xl"
                />
              </Columns>
              <Button
                text="Test"
                variant="solid"
                onClick={handleSimilarityTest}
              />
            </Columns>
          </Rows>
        )}
      </Columns>
      <Rows
        className={cn(
          "grow border rounded-md overflow-hidden",
          (!searchOutput ||
            isLoading ||
            error ||
            searchOutput.results.length === 0) &&
            "flex items-center justify-center"
        )}
      >
        {searchResults}
      </Rows>
    </Rows>
  );
};

interface SearchResultsTableProps {
  result: SearchOutput;
  type: null | "chunk" | "window";
}

const SearchResultsTable: FC<SearchResultsTableProps> = ({ result, type }) => {
  return (
    <Columns className="grow gap-md p-md justify-stretch overflow-hidden">
      <FinalResultsTable results={result.results} />
      {type === "window" && (
        <WindowResultsTable windows={result.debug_original_windows} />
      )}
      {type === "chunk" && (
        <ChunkResultsTable chunks={result.debug_original_chunks} />
      )}
    </Columns>
  );
};

interface FinalResultsTableProps {
  results: SearchResult[];
}

const FinalResultsTable: FC<FinalResultsTableProps> = ({ results }) => {
  return (
    <Rows className="grow basis-0">
      <h2 className="font-semibold mb-sm">Full Results</h2>
      <PaginatedTableView
        results={results}
        paginatedData={EMPTY_PAGINATED_DATA}
        rowSeparators={true}
        columns={[
          { name: "Text" },
          { name: "Doc" },
          { name: "Similarity", size: "min" },
        ]}
        renderRow={(result, Cell, Row, index) => {
          return (
            <Row key={index}>
              <Cell>
                <pre className="whitespace-pre-wrap">{result.text}</pre>
              </Cell>
              <Cell>{result.debug_info.raw_chunks[0].doc_id}</Cell>
              <Cell>{Math.round(result.similarity! * 1000) / 1000}</Cell>
            </Row>
          );
        }}
      />
    </Rows>
  );
};

interface ChunkResultsTableProps {
  chunks: DebugChunk[];
}

const ChunkResultsTable: FC<ChunkResultsTableProps> = ({ chunks }) => {
  return (
    <Rows className="grow basis-0">
      <h2 className="font-semibold mb-sm">Chunk Results</h2>
      <PaginatedTableView
        results={chunks}
        paginatedData={EMPTY_PAGINATED_DATA}
        columns={[
          { name: "Text" },
          { name: "Similarity", size: "min" },
          { name: "Doc" },
          { name: "Group ID" },
          { name: "Start Index" },
        ]}
        rowSeparators={true}
        renderRow={(chunk, Cell, Row) => {
          return (
            <Row key={chunk.id}>
              <Cell>
                <pre className="whitespace-pre-wrap">{chunk.original_text}</pre>
              </Cell>
              <Cell>{Math.round(chunk.similarity * 1000) / 1000}</Cell>
              <Cell>{chunk.doc_id}</Cell>
              <Cell>{chunk.group_id}</Cell>
              <Cell>{chunk.start_index_in_group}</Cell>
            </Row>
          );
        }}
      />
    </Rows>
  );
};

interface WindowResultsTableProps {
  windows: ChunkWindow[];
}

const WindowResultsTable: FC<WindowResultsTableProps> = ({ windows }) => {
  return (
    <Rows className="grow basis-0">
      <h2 className="font-semibold mb-sm">Window Results</h2>
      <PaginatedTableView
        results={windows}
        paginatedData={EMPTY_PAGINATED_DATA}
        columns={[
          { name: "Text" },
          { name: "Doc" },
          { name: "Group ID" },
          { name: "Start Index" },
        ]}
        rowSeparators={true}
        renderRow={(window, Cell, Row) => {
          return (
            <Row key={window.start_index_in_group}>
              <Cell>
                <pre className="whitespace-pre-wrap">
                  {window.chunks
                    .map((chunk) =>
                      chunk.debug_info.raw_chunks
                        .map((c) => c.original_text)
                        .join(" ")
                    )
                    .join("\n--------------\n")}
                </pre>
              </Cell>
              <Cell>{window.doc_id}</Cell>
              <Cell>{window.group_id}</Cell>
              <Cell>{window.start_index_in_group}</Cell>
            </Row>
          );
        }}
      />
    </Rows>
  );
};

interface ResultTypeSelectorProps {
  resultsType: null | "chunk" | "window";
  setResultsType: (type: null | "chunk" | "window") => void;
  onCopyResults: () => void;
}

const ResultTypeSelector: FC<ResultTypeSelectorProps> = ({
  resultsType,
  setResultsType,
  onCopyResults,
}) => {
  const buttonVariant = (type: null | "chunk" | "window") => {
    if (type === resultsType) {
      return "solid-secondary";
    }
    return "outline";
  };

  const option = (text: string, type: null | "chunk" | "window") => {
    return (
      <Button
        text={text}
        variant={buttonVariant(type)}
        onClick={() => setResultsType(type === resultsType ? null : type)}
      />
    );
  };

  return (
    <Columns className="gap-md p-sm border-b shrink-0">
      <Button icon="copy" onClick={onCopyResults} />
      <Spacer />
      {option("Chunk Results", "chunk")}
      {option("Window Results", "window")}
    </Columns>
  );
};

export default SearchRoute;
