import { useDroppable } from "@dnd-kit/core";
import {
  PlateElement,
  PlateElementProps,
  useEditorRef,
} from "@udecode/plate-common";
import Rows from "components/common/containers/Rows";
import { ParentSectionInfo } from "lib/plate/plugins/styling/StyleInfo";
import { useStyleInfo } from "lib/plate/plugins/styling/useStyleInfo";
import { useIsInComment } from "lib/plate/useIsInComment";
import { DEFAULT_STYLE_RENDERER } from "lib/plate/useStyleGuide";
import { cn } from "lib/utils";
import { setElement } from "providers/DocElementRefProvider";
import { useProposalData } from "providers/ProposalDetailsProvider";
import { CSSProperties, FC, useEffect, useRef, useState } from "react";
import { ReactEditor } from "slate-react";

export interface BaseElementProps extends PlateElementProps {}

export const BaseElement: FC<BaseElementProps> = ({
  children,
  className,
  element,
  placeholder,
  ...props
}) => {
  const editor = useEditorRef();
  const { styleGuide: proposalStyleGuide } = useProposalData();
  const path = ReactEditor.findPath(editor as any, element);
  const styleInfo = useStyleInfo(element);
  const isInCommentEditor = useIsInComment(element);

  const styleGuide = isInCommentEditor
    ? DEFAULT_STYLE_RENDERER
    : proposalStyleGuide;
  const [style, setStyle] = useState<CSSProperties>(
    styleGuide.textStyleToCSS(editor as any, element)
  );
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setStyle((prev) => {
      const newStyle = styleGuide.textStyleToCSS(editor as any, element);
      if (JSON.stringify(newStyle) === JSON.stringify(prev)) {
        return prev;
      }
      return newStyle;
    });
  }, [editor, element, styleGuide]);

  useEffect(() => {
    const id = element.id as string;
    if (!id) {
      return;
    }
    if (ref.current) {
      setElement(editor, id, ref.current);
    }

    return () => {
      setElement(editor, id, null);
    };
  }, [editor, element.id]);

  // TODO: Fix drop between targets
  let borderTop: string | number = "0";
  let borderBottom: string | number = "0";
  let dropTop: number = 0;
  let dropBottom: number = 0;
  const parentSection = styleInfo.parentSectionInfo;
  if (parentSection) {
    if (style.marginBottom !== undefined) {
      if (typeof style.marginBottom === "number") {
        dropBottom = -style.marginBottom;
      } else {
        dropBottom = -parseInt(style.marginBottom);
      }

      if (parentSection.isLastInSection) {
        borderBottom = 20;
      } else {
        borderBottom = "-" + style.marginBottom;
      }
    } else {
      borderBottom = 4;
    }

    if (style.marginTop) {
      let shift = 0;
      if (typeof style.marginTop === "number") {
        shift = -style.marginTop;
      } else {
        shift = -parseInt(style.marginTop);
      }
      dropTop = shift;

      if (parentSection.isFirstInSection) {
        borderTop = "4px";
      } else {
        borderTop = shift;
      }
    }
  }

  return (
    <PlateElement
      {...props}
      element={element}
      ref={ref}
      className={cn(
        isInCommentEditor && styleInfo.isInTable && "px-sm",
        !styleInfo.isInRefine &&
          !isInCommentEditor &&
          !styleInfo.isInTable &&
          "mx-4xl",
        className
      )}
      style={style}
    >
      <div className="relative">
        {styleInfo.parentSectionInfo && (
          <Rows
            contentEditable={false}
            className={cn(
              "absolute inset-0 pointer-events-none overflow-visible select-none"
            )}
          >
            <Rows
              className={cn("absolute left-0 right-0 overflow-visible")}
              style={{
                top: dropTop,
                bottom: dropBottom,
              }}
            >
              <DropIntoTarget
                sectionInfo={styleInfo.parentSectionInfo}
                index={path[0]}
              />
              {styleInfo.parentSectionInfo.isFirstInSection && (
                <DropAboveTarget
                  sectionInfo={styleInfo.parentSectionInfo}
                  index={path[0]}
                />
              )}
              {styleInfo.parentSectionInfo.isLastInSection &&
                styleInfo.parentSectionInfo.isLastSectionInDocument && (
                  <DropBelowTarget
                    sectionInfo={styleInfo.parentSectionInfo}
                    index={path[0]}
                  />
                )}
            </Rows>
          </Rows>
        )}
        {false && (
          <>
            <div
              contentEditable={false}
              className="absolute -left-thin w-thin bg-primary select-none"
              style={{ top: borderTop, bottom: borderBottom }}
            />
            <div
              contentEditable={false}
              className="absolute -right-thin w-thin bg-primary select-none"
              style={{ top: borderTop, bottom: borderBottom }}
            />
          </>
        )}
        {children}
      </div>
    </PlateElement>
  );
};

interface DropTarget {
  index: number;
  sectionInfo: ParentSectionInfo;
}

const DropIntoTarget: FC<DropTarget> = ({ index, sectionInfo }) => {
  const { setNodeRef } = useDroppable({
    id: sectionInfo.id + "_into_" + index.toString(),
  });

  return (
    <div
      className={cn(
        "grow absolute left-0 right-0 top-[4px] -bottom-[4px]",
        sectionInfo.isLastInSection && "top-[4px]"
      )}
      ref={setNodeRef}
    />
  );
};

const DropAboveTarget: FC<DropTarget> = ({ index, sectionInfo }) => {
  const { setNodeRef } = useDroppable({
    id: sectionInfo.id + "_above_" + index.toString(),
  });

  return (
    <div
      className={cn("h-md absolute top-[4px] left-0 right-0")}
      ref={setNodeRef}
    />
  );
};

const DropBelowTarget: FC<DropTarget> = ({ index, sectionInfo }) => {
  const { setNodeRef } = useDroppable({
    id: sectionInfo.id + "_below_" + index.toString(),
  });

  return (
    <div
      className={cn(
        "h-md"
        // 15 = md + 3px border
        // isOver && "border-b-[3px] -mb-[3px] h-[15px] border-primary"
      )}
      ref={setNodeRef}
    />
  );
};

export default BaseElement;
