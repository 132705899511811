import { useDraggable } from "@dnd-kit/core";
import { focusEditor, PlateEditor, useEditorRef } from "@udecode/plate-common";
import { RFPBlock } from "api/Api";
import { cn } from "lib/utils";
import { getSectionInfoFromElement } from "odo";
import { bboxToStyle } from "providers/FinalCheckDetailsProvider";
import { useEditorDocData } from "providers/RequirementContentEditorProvider";
import { FC } from "react";

interface RFPPDFBlockViewProps {
  block: RFPBlock;
  pageWidth: number;
  pageHeight: number;
}

const RFPPDFBlockView: FC<RFPPDFBlockViewProps> = ({
  block,
  pageWidth,
  pageHeight,
}) => {
  const editor = useEditorRef();
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: "block_" + block.index.toString(),
    data: {
      block,
    },
  });
  const { setHighlight, scrollToElement } = useEditorDocData();

  const hasRequirement =
    block.requirements.filter((r) => r.category !== "submission-guideline")
      .length > 0;
  const covered = isBlockCovered(block, editor);

  const handleClick = () => {
    scrollToElement((element, path) => {
      const sectionInfo = getSectionInfoFromElement(element);
      const focusAfterDelay = () => {
        if (path) {
          const full = editor.end(path);
          focusEditor(editor, full);
        }
      };
      if (!sectionInfo) {
        return false;
      }
      if (sectionInfo.requirements && sectionInfo.requirements.length > 0) {
        const reqIds = sectionInfo.requirements.map((r) => r.toString());
        for (const req of block.requirements) {
          if (reqIds.includes(req.id!.toString())) {
            focusAfterDelay();
            return true;
          }
        }
      }
      if (sectionInfo.blocks && sectionInfo.blocks.includes(block.index)) {
        focusAfterDelay();
        return true;
      }
      return false;
    });
  };

  return (
    <div
      ref={setNodeRef}
      onMouseEnter={() => {
        const highlightedRequirements = block.requirements.map((r) =>
          r.id!.toString()
        );
        setHighlight(
          highlightedRequirements,
          [block.index]
          // highlightedRequirements.length > 0 ? [] : [block.index]
        );
      }}
      onMouseLeave={() => setHighlight([], [])}
      onClick={handleClick}
      className={cn(
        "flex rounded-sm absolute cursor-grab overflow-hidden",
        isDragging &&
          (hasRequirement
            ? covered
              ? "border border-[3px] border-[#7CB632]"
              : "border border-[3px] border-destructive"
            : "border border-[3px] border-secondary")
      )}
      style={bboxToStyle(block.bbox as any, pageWidth, pageHeight)}
      {...listeners}
      {...attributes}
    >
      <div
        className={cn(
          "grow opacity-10 hover:opacity-40",
          covered ? "bg-[#7CB632]" : hasRequirement && "bg-destructive",
          isDragging && !covered && "bg-secondary"
        )}
      />
    </div>
  );
};

const isBlockCovered = (block: RFPBlock, editor: PlateEditor) => {
  let remainingRequirements = new Set(block.requirements.map((r) => r.id));
  let hadRequirements = remainingRequirements.size > 0;
  for (const child of editor.children) {
    const sectionInfo = getSectionInfoFromElement(child);
    if (sectionInfo) {
      if (sectionInfo.blocks && sectionInfo.blocks.includes(block.index)) {
        return true;
      }
      if (hadRequirements && sectionInfo.requirements) {
        for (const req of sectionInfo.requirements) {
          remainingRequirements.delete(req as any);
          if (remainingRequirements.size === 0) {
            return true;
          }
        }
      }
    }
  }
  return false;
};

export default RFPPDFBlockView;
