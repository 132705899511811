import { getCommentKeyFromId, CommentLeafProps, MARK_COMMENT } from "odo";
import { isText, setNodes, useEditorRef } from "@udecode/plate";
import {
  useCreatePendingComment,
  useSaveComment,
} from "../../../providers/CommentsProvider";
import { useCallback } from "react";
import { v4 } from "uuid";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";

function useAddComment() {
  const editor = useEditorRef();
  const createPendingComment = useCreatePendingComment();
  const saveComment = useSaveComment();
  const currentUser = useAuthenticatedUser();

  return useCallback(
    (startingContent: any[], parentId?: string, autoSend?: boolean) => {
      const addCommentToEditor = (commentId: string) => {
        const leaf: CommentLeafProps = {
          [MARK_COMMENT]: true,
          [getCommentKeyFromId(commentId)]: true,
        };
        setNodes(editor, leaf, { match: isText, split: true });
      };

      if (autoSend ?? false) {
        const commentId = v4().toString();
        addCommentToEditor(commentId);
        setTimeout(() => {
          saveComment(commentId, {
            id: commentId,
            parentId,
            userId: currentUser.publicId,
            text: startingContent,
            createdAt: new Date().toISOString(),
            isPending: false,
            isResolved: false,
          });
        }, 100);
      } else {
        const comment = createPendingComment(startingContent, parentId);
        addCommentToEditor(comment.id);
      }
    },
    [createPendingComment, currentUser.publicId, editor, saveComment]
  );
}

export default useAddComment;
