import { TElement } from "@udecode/plate-common";
import { StyleGuide, TextStyle } from "api/Api";
import { isOdoElement, isOdoP, isSuggestedElement } from "odo";
import { CSSProperties, useEffect, useRef } from "react";
import { ReactEditor } from "slate-react";

const useStyleGuide = (guide: StyleGuide) => {
  const renderer = useRef<StyleGuideRenderer>(new StyleGuideRenderer(guide));

  useEffect(() => {
    renderer.current = new StyleGuideRenderer(guide);
  }, [guide]);

  return renderer.current;
};

export class StyleGuideRenderer {
  guide: StyleGuide;

  // The cache styles, by element type, as strictly dicated by the style guide
  // (does not include changes based on sibling elements)
  baseStyleCache: Record<string, TextStyle> = {};

  constructor(guide: StyleGuide) {
    this.guide = guide;
  }

  textStyleToCSS(editor: ReactEditor, element: TElement): CSSProperties {
    const { next: nextElement, prev: previousElement } =
      this.getSiblingElements(editor, element);

    let style = this.getBaseStyle(element);
    style = this.adjustStyleBasedOnContext(
      style,
      nextElement,
      element,
      previousElement
    );
    return this.adjustedTextStyleToCSS(style);
  }

  private adjustStyleBasedOnContext(
    style: TextStyle,
    nextElement: TElement | null,
    currentElement: TElement,
    previousElement: TElement | null
  ): TextStyle {
    let adjusted = JSON.parse(JSON.stringify(style));
    const currentType = this.typeFromElement(currentElement);

    // Don't add margin to the first element

    if (!previousElement) {
      adjusted.margin_top = 0;
    }

    // Don't override color if suggested

    if (isSuggestedElement(currentElement)) {
      adjusted.color = undefined;
      adjusted.background_color = undefined;
    }

    if (previousElement) {
      const previousType = this.typeFromElement(previousElement);
      if (previousType === currentType && adjusted.contextual_spacing) {
        // Don't add margin between elements of the same type
        adjusted.margin_top = 0;
      } else {
        // Use half of max margin between elements of different types
        const previousStyle = this.getBaseStyle(previousElement);
        adjusted.margin_top = Math.max(
          adjusted.margin_top,
          previousStyle.margin_bottom ?? 0
        );
      }
    }

    if (nextElement) {
      // Margin is always taken care of by the next element
      adjusted.margin_bottom = 0;
    }

    return adjusted;
  }

  private adjustedTextStyleToCSS(style: TextStyle): CSSProperties {
    let props: CSSProperties = {};

    // Font

    if (style.font) {
      props.fontFamily = this.translateFont(style.font);
    }

    if (style.font_size) {
      props.fontSize = `${style.font_size * 1}pt`;
    }

    if (style.font_weight) {
      props.fontWeight = style.font_weight;
    }

    if (style.character_style) {
      props.fontStyle = style.character_style;
    }

    // Alignment

    if (style.alignment) {
      props.textAlign = style.alignment as any;
    }

    // Spacing

    if (style.line_spacing) {
      props.lineHeight = style.line_spacing * 1.2;
    }

    // Margin

    if (style.margin_top) {
      props.marginTop = `${style.margin_top}px`;
    }

    if (style.margin_bottom) {
      props.marginBottom = `${style.margin_bottom}px`;
    }

    // Padding

    if (style.padding_top) {
      props.paddingTop = `${style.padding_top}px`;
    }

    if (style.padding_bottom) {
      props.paddingBottom = `${style.padding_bottom}px`;
    }

    if (style.padding_left) {
      props.paddingLeft = `${style.padding_left}px`;
    }

    if (style.padding_right) {
      props.paddingRight = `${style.padding_right}px`;
    }

    // Color

    if (style.color) {
      props.color = this.translateColor(style.color);
    }

    if (style.background_color) {
      props.backgroundColor = style.background_color;
    }

    // Border
    if (style.border) {
      debugger;
      const borderSize = style.border.size ?? 1;
      const borderColor = this.translateColor(style.border.color ?? "000000");
      const borderStyle = `${borderSize}px solid #${borderColor}`;

      switch (style.border.position ?? "around") {
        case "top":
          props.borderTop = borderStyle;
          props.paddingTop = `5px`;
          break;
        case "bottom":
          props.borderBottom = borderStyle;
          props.paddingBottom = `5px`;
          break;
        case "around":
          props.border = borderStyle;
          props.padding = `5px`;
          break;
      }
    }

    // Case

    if (style.case_style) {
      switch (style.case_style) {
        case "uppercase":
          props.textTransform = "uppercase";
          break;
        case "lowercase":
          props.textTransform = "lowercase";
          break;
        case "normal":
          break;
      }
    }

    return props;
  }

  private getBaseStyle(element: TElement): TextStyle {
    const type = this.typeFromElement(element);
    if (!type) {
      return {};
    }
    if (!this.baseStyleCache[type]) {
      this.baseStyleCache[type] = this.calculateBaseStyle(type);
    }

    return this.baseStyleCache[type];
  }

  private calculateBaseStyle(type: string): TextStyle {
    let style = this.guide.text_styles?.default ?? {};
    if (type === "p_list") {
      style = this.mergeObject(style, this.guide.text_styles?.p);
    }
    // @ts-ignore
    return this.mergeObject(style, this.guide.text_styles?.[type]);
  }

  private typeFromElement(element: TElement): string | undefined {
    if (isOdoP(element) && !!element.listStyleType) {
      return "p_list";
    }

    if (isOdoElement(element)) {
      return element.type;
    }

    return undefined;
  }

  private translateFont(font: string): string {
    // switch (font) {
    //   case "Century Gothic":
    //     return "century-gothic, sans-serif";
    // }
    return font;
  }

  private translateColor(color: string): string {
    switch (color) {
      case "primary":
        return "var(--primary)";
      case "secondary":
        return "var(--secondary)";
      case "tertiary":
        return "var(--tertiary)";
    }
    return color;
  }

  private mergeObject(
    base: Record<string, any>,
    override: Record<string, any> | undefined
  ): Record<string, any> {
    if (!override) {
      return base;
    }

    let merged = JSON.parse(JSON.stringify(base));
    for (const key in override) {
      if (key in base) {
        if (typeof base[key] === "object") {
          merged[key] = this.mergeObject(base[key], override[key]);
        } else {
          merged[key] = override[key];
        }
      } else {
        merged[key] = override[key];
      }
    }

    return merged;
  }

  private getSiblingElements(
    editor: ReactEditor,
    element: TElement
  ): {
    prev: TElement | null;
    next: TElement | null;
  } {
    const path = ReactEditor.findPath(editor, element);
    if (!path) return { prev: null, next: null };

    let prev: TElement | null = null;
    let prevPath = [...path];
    prevPath[prevPath.length - 1] -= 1;
    if (prevPath[prevPath.length - 1] >= 0 && editor.hasPath(prevPath)) {
      prev = editor.node(prevPath)[0] as any;
      if (prev?.type === "deleted") {
        prev = null;
      }
    }

    let next: TElement | null = null;
    let nextPath = [...path];
    nextPath[nextPath.length - 1] += 1;
    if (editor.hasPath(nextPath)) {
      next = editor.node(nextPath)[0] as any;
    }

    return { prev, next: next ?? null };
  }
}

export const DEFAULT_STYLE_RENDERER = new StyleGuideRenderer({
  text_styles: {
    default: {
      font: "Inter",
      font_size: 12,
      font_weight: "normal",
      margin_top: 12,
      margin_bottom: 0,
      padding_top: 0,
      padding_bottom: 0,
      padding_left: 0,
      padding_right: 0,
      line_spacing: 1.2,
    },
    p: {},
    p_list: {
      margin_bottom: 0,
      contextual_spacing: true,
    },
    h1: {
      font_size: 20,
      font_weight: "bold",
      margin_top: 20,
      margin_bottom: 6,
    },
    h2: {
      color: "primary",
      font_size: 16,
      font_weight: "bold",
      margin_top: 18,
      margin_bottom: 6,
    },
    h3: {
      font_size: 14,
      font_weight: "bold",
      margin_top: 16,
      margin_bottom: 4,
    },
    h4: {
      color: "#666666",
      font_size: 12,
      font_weight: "bold",
      margin_top: 14,
      margin_bottom: 4,
    },
    h5: {
      font_size: 12,
      color: "#666666",
      font_weight: "normal",
      margin_top: 12,
      margin_bottom: 4,
    },
    h6: {
      margin_top: 12,
      font_weight: "normal",
      character_style: "italic",
      color: "#666666",
    },
  },
});

export default useStyleGuide;
