import { textFromOdoElement } from "../odo-editor/index.js";
export const getCommentValue = (comment) => {
    if (typeof comment.text === "string") {
        return [
            {
                type: "p",
                children: [
                    {
                        text: comment.text,
                    },
                ],
            },
        ];
    }
    return comment.text;
};
export const getCommentText = (comment) => {
    if (typeof comment.text === "string") {
        return comment.text;
    }
    let text = "";
    for (const node of comment.text) {
        text += textFromOdoElement(node) + "\n";
    }
    return text;
};
export const emptyContent = [{ type: "p", children: [{ text: "" }] }];
export const odoUser = {
    publicId: "odo",
    displayName: "Odo",
};
export const odoTagContent = [
    {
        type: "p",
        children: [
            { type: "mention", user: odoUser, children: [{ text: "" }] },
            { text: " " },
        ],
    },
];
export const isEmptyContent = (content) => {
    if (content.length === 0)
        return true;
    if (content.length > 1)
        return false;
    const first = content[0];
    if (first.type !== "p")
        return false;
    if (first.children.length === 0)
        return true;
    if (first.children.length > 1)
        return false;
    const firstChild = first.children[0];
    if (!("text" in firstChild))
        return false;
    if (firstChild.text.length !== 0)
        return false;
    return true;
};
export const isOdoTagContent = (content) => {
    let foundMention = false;
    if (content.length !== 1 || !("children" in content[0]))
        return false;
    for (const element of content[0].children) {
        if ("type" in element && element.type === "mention") {
            if (foundMention) {
                return false;
            }
            foundMention = true;
            if (element.user.publicId !== "odo") {
                return false;
            }
        }
        if ("text" in element && element.text.trim() !== "") {
            return false;
        }
    }
    return true;
};
export function isOdoComment(comment) {
    return comment.userId === "odo";
}
export function isOdoMentioned(nodes) {
    return mentionedUserIds(nodes).includes("odo");
}
export function mentionedUserIds(nodes) {
    if (typeof nodes === "string") {
        return [];
    }
    function mentionedUsersIdsInNode(node) {
        if (!node)
            return [];
        if (node.type === "mention" && !!node.user.publicId) {
            return [node.user.publicId];
        }
        if (node.children) {
            return mentionedUserIds(node.children);
        }
        return [];
    }
    return nodes.flatMap(mentionedUsersIdsInNode);
}
export const MARK_COMMENT = "comment";
