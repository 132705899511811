import React from "react";
import {
  PlateElement,
  PlateElementProps,
  getPluginOptions,
} from "@udecode/plate-common";
import { ELEMENT_TABLE, TablePlugin } from "@udecode/plate-table";
import { cn } from "lib/utils";
import { useEditorRef } from "@udecode/plate";
import {
  TableElementProvider,
  cellCountInSelection,
  useTableState,
} from "./TableElementProvider";
import { OdoTable } from "odo";
import TableContextMenu from "./TableContextMenu";
import { DragResizableControl } from "components/common/containers/DragResizable";
import { SuggestableElement } from "components/proposals/refine/SuggestableElement";
import Scrollable from "components/common/containers/Scrollable";

const TableElement = React.forwardRef<
  React.ElementRef<typeof PlateElement>,
  PlateElementProps
>(({ className, children, ...props }, ref) => {
  return (
    <TableElementProvider table={props.element as OdoTable}>
      <SuggestableElement
        ref={ref}
        className={cn("overflow-x-auto overflow-x-visible", className)}
        {...props}
      >
        <Scrollable className="grow" vertical={false} horizontal={true}>
          <TableContent>{children}</TableContent>
        </Scrollable>
      </SuggestableElement>
    </TableElementProvider>
  );
});

const TableContent = ({ children }: { children: React.ReactNode }) => {
  const { totalWidth, colLefts, setColSizes, saveColSizes, selection } =
    useTableState();

  return (
    <TableContextMenu>
      <div
        className={cn(
          "grid gap-thin bg-border relative border",
          cellCountInSelection(selection) > 1 ? "hide-text-selection" : ""
        )}
        style={{ width: totalWidth }}
      >
        {children}
        {colLefts.map((left, index) => (
          <TableColumnDivider
            key={index}
            index={index}
            isLast={index === colLefts.length - 1}
            left={left}
            saveColSizes={saveColSizes}
            setColSizes={setColSizes}
          />
        ))}
      </div>
    </TableContextMenu>
  );
};

const TableColumnDivider = ({
  left,
  index,
  isLast,
  setColSizes,
  saveColSizes,
}: {
  left: number;
  index: number;
  isLast: boolean;
  setColSizes: React.Dispatch<React.SetStateAction<number[]>>;
  saveColSizes: () => void;
}) => {
  const editor = useEditorRef();
  const { minColumnWidth } = getPluginOptions<TablePlugin>(
    editor,
    ELEMENT_TABLE
  );
  return (
    <DragResizableControl
      direction="horizontally"
      controlSide="trailing"
      contentEditable={false}
      className="absolute top-0 bottom-0 w-[5px]"
      style={{ left: left - (isLast ? 5 : 2) }}
      onResize={(change) => {
        setColSizes((prev) => {
          const newColSizes = [...prev];
          newColSizes[index] = Math.max(
            minColumnWidth ?? 0,
            newColSizes[index] + change
          );
          return newColSizes;
        });
      }}
      onStopResize={saveColSizes}
    />
  );
};

export { TableElement };
