import { createRef, useEffect, useRef, useState } from "react";
import Columns from "../Columns";
import React from "react";
import DragHandle from "./DragHandle";
import { cn } from "lib/utils";
import SplitPaneLayoutManager, {
  INFINITY,
  SplitPaneData,
} from "./SplitPaneLayoutManager";
import { SplitPaneProps } from "./SplitPane";

interface SplitPaneContainerProps {
  className?: string;
  children?: React.ReactNode;
  localStorageKey: string;
}

/**
 * Container for SplitPanes (children must be SplitPane)
 *
 * The design of this component is based on a collection of panes that have their own
 * size constraints and priorities. Those constraints and priorites influence how space
 * is distributed to and taken away from the panes.
 *
 * The layout is conceptualized as a particular serparator being moved which takes space
 * from one side (with 1 or more panes) and gives it to the other (with 1 or more panes).
 * The side being made smaller follows rules based on how to take away space from the panes
 * and the other side follows indpenedent rules on how to give space to the panes. This
 * ensures that the separator always moves exactly with the cursor and isolates the logic.
 *
 * Note that the initial layout of panes is conceptualized as each pane with a desired width
 * starting out at that width, each pane without a desired width starting out at an infinte
 * width, and then shrinking down all of them to fit into the container.
 *
 * Constraints:
 * - minWidth: The minimum width the pane can ever be. If no other panes can be shrunk anymore
 *    then the whole container will scroll.
 * - maxWidth: The maximum width the pane can ever be. If no other panes can be expanded anymore
 *    then the children will not fill the container.
 * - desiredWidth: The width the pane would like to be, which is based on the current width
 *    or the width it was saved at.
 * - priority: The order in which space should be given/taken away from the panes. Higher
 *    priority means space is given/taken away first. Multipe panes can have the same priority
 *   and will be given/taken away in equal amounts.
 */
export const SplitPaneContainer = React.forwardRef<
  HTMLDivElement,
  SplitPaneContainerProps
>(({ className, children, localStorageKey }, ref) => {
  // Ensure children are SplitPanes
  const containerRef = useRef<HTMLDivElement>(null);

  const [paneConfigs, setPaneConfigs] = useState<Record<string, SplitPaneData>>(
    {}
  );

  const layoutManager = useRef<SplitPaneLayoutManager | null>(null);

  const childrenIds = JSON.stringify(
    React.Children.map(children, (child) => {
      if (!React.isValidElement(child)) {
        return null;
      }
      return {
        id: (child as any).props.uniqueId,
        hidden: (child as any).props.hidden ?? false,
      };
    })
  );
  useEffect(() => {
    const childrenArray = React.Children.toArray(children);
    setPaneConfigs((prev) => {
      // Update any customizable props, but start with the existng current size
      let updated: typeof prev = {};
      childrenArray.forEach((child, index) => {
        if (React.isValidElement(child) /* && child.type === SplitPane*/) {
          const { uniqueId, minSize, maxSize, defaultSize, priority, hidden } =
            child.props as SplitPaneProps;
          if (hidden ?? false) return;
          const existing = prev[uniqueId];
          if (existing) {
            updated[uniqueId] = {
              ...existing,
              uniqueId,
              minSize,
              maxSize,
              defaultSize,
              priority,
            };
          } else {
            updated[uniqueId] = {
              minSize,
              maxSize,
              defaultSize,
              priority,
              currentSize: defaultSize ?? INFINITY,
              paneRef: createRef(),
              uniqueId,
            };
          }
        }
      });

      return updated;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childrenIds, setPaneConfigs]);

  const hasRefs = Object.values(paneConfigs).some(
    (config) => config.paneRef.current !== null
  );

  useEffect(() => {
    // Ensure that at least one ref is set
    if (!hasRefs) {
      layoutManager.current = null;
      return;
    }

    // When the pane configs change, we need to recreate the layout manager
    layoutManager.current = new SplitPaneLayoutManager(
      Object.values(paneConfigs),
      containerRef.current?.clientWidth ?? 0,
      localStorageKey
    );
  }, [paneConfigs, containerRef, hasRefs]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      layoutManager.current?.adjustContainerSize(
        containerRef.current?.clientWidth ?? 0
      );
    });
    resizeObserver.observe(containerRef.current as Element);
    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef]);

  assertChildrenAreSplitPanes(children);

  const createMoveHandler = (separatorIndex: number) => (amount: number) => {
    layoutManager.current?.moveSeparator(separatorIndex, amount);
  };

  let index = 0;
  const finalChildren: React.ReactNode[] = [];
  React.Children.forEach(children, (child) => {
    if (!child) {
      finalChildren.push(child);
      return;
    }
    if (!("props" in (child as any))) {
      finalChildren.push(child);
      return;
    }
    // @ts-ignore
    const props = child.props as SplitPaneProps;
    const paneRef = paneConfigs[props.uniqueId]?.paneRef;

    finalChildren.push(
      <>
        {index > 0 && (
          <DragHandle
            onMove={createMoveHandler(index - 1)}
            direction="horizontally"
          />
        )}
        {React.cloneElement(child as React.ReactElement, {
          ...props,
          paneRef,
        })}
      </>
    );
    index += 1;
  });

  return (
    <Columns ref={containerRef} className="overflow-hidden grow">
      <Columns className={cn("overflow-x-auto grow", className)}>
        {finalChildren}
      </Columns>
    </Columns>
  );
});

const assertChildrenAreSplitPanes = (children: React.ReactNode) => {
  //   for (const child of React.Children.toArray(children)) {
  //     if (!React.isValidElement(child)) {
  //       throw new Error(
  //         `SplitPaneContainer children must be SplitPanes. Instead got invalid react element`
  //       );
  //     }
  //     if (!React.isValidElement(child) || child.type !== SplitPane) {
  //       throw new Error(
  //         `SplitPaneContainer children must be SplitPanes. Instead got: ${child.type}`
  //       );
  //     }
  //   }
};
