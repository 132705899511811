import Columns from "components/common/containers/Columns";
import Grid from "components/common/containers/Grid";
import Rows from "components/common/containers/Rows";
import Toggle from "components/common/forms/Toggle";
import Icon, { IconName } from "components/common/Icon";
import { cn } from "lib/utils";
import { FC } from "react";

interface CustomerTypePickerProps {
  values: string[];
  onChange: (values: string[]) => void;
}

interface CustomerType {
  id: string;
  displayName: string;
}

const GOVERNMENT_ENTITIY_TYPES: CustomerType[] = [
  { id: "state", displayName: "State" },
  { id: "county", displayName: "County" },
  { id: "city", displayName: "City" },
  { id: "village", displayName: "Village" },
  { id: "town", displayName: "Town" },
  { id: "borough", displayName: "Borough" },
  { id: "tribal_nation", displayName: "Tribal Nation" },
  { id: "federal", displayName: "Federal" },
];

const EDUCATION_TYPES: CustomerType[] = [
  { id: "university", displayName: "University" },
  { id: "college", displayName: "College" },
  { id: "high_school", displayName: "High School" },
  { id: "middle_school", displayName: "Middle School" },
  { id: "elementary_school", displayName: "Elementary School" },
  { id: "k12", displayName: "K-12" },
  { id: "other_school", displayName: "Other School" },
];

const HEALTH_TYPES: CustomerType[] = [
  { id: "hospital", displayName: "Hospital" },
  { id: "other_health", displayName: "Other Health" },
];

const DEPARTMENT_TYPES: CustomerType[] = [
  { id: "transit_agency", displayName: "Transit Agency" },
  { id: "public_works", displayName: "Public Works" },
  { id: "other_department", displayName: "Other Department" },
];

const NON_PROFIT_TYPES: CustomerType[] = [
  { id: "non_profit", displayName: "Non-Profit" },
];

const ALL_TYPES: {
  displayName: string;
  types: CustomerType[];
  icon: IconName;
}[] = [
  {
    displayName: "Government Entities",
    types: GOVERNMENT_ENTITIY_TYPES,
    icon: "landmark",
  },
  { displayName: "Education", types: EDUCATION_TYPES, icon: "graduation-cap" },
  { displayName: "Health", types: HEALTH_TYPES, icon: "hospital" },
  { displayName: "Department", types: DEPARTMENT_TYPES, icon: "sitemap" },
  {
    displayName: "Nonprofit",
    types: NON_PROFIT_TYPES,
    icon: "hand-holding-heart",
  },
];

export const CustomerTypePicker: FC<CustomerTypePickerProps> = ({
  values,
  onChange,
}) => {
  return (
    <Rows className="border rounded">
      {ALL_TYPES.map(({ displayName, types, icon }) => (
        <CustomerTypeSection
          title={displayName}
          icon={icon}
          types={types}
          values={values}
          onChange={onChange}
        />
      ))}
    </Rows>
  );
};

interface CustomerTypeSectionProps {
  title: string;
  icon?: IconName;
  types: CustomerType[];
  className?: string;
  style?: React.CSSProperties;
  values: string[];
  onChange: (values: string[]) => void;
}

const CustomerTypeSection: FC<CustomerTypeSectionProps> = ({
  title,
  icon,
  types,
  className,
  style,
  values,
  onChange,
}) => {
  return (
    <div
      className={cn(
        "flex flex-col min-[380px]:flex-row border-t first:border-t-0 py-md",
        className
      )}
      style={style}
    >
      <Rows className="gap-md items-center justify-center grow pt-md mb-sm mx-4xl pb-md border-b min-[380px]:w-[190px] px-4xl text-center min-[380px]:border-b-0 min-[380px]:mx-0 min-[380px]:pb-0">
        {icon && <Icon name={icon} size="xlarge" />}
        <h1>{title}</h1>
      </Rows>
      <Rows className="w-full grow">
        <div
          className={cn(
            "columns-1 w-full grow",
            types.length > 1 && "min-[560px]:columns-2"
          )}
          style={{ columnFill: "balance" }}
        >
          {types.map((type) => (
            <div key={type.id} className="w-[50%]">
              <Toggle
                text={type.displayName}
                toggleType="checkbox"
                on={values.includes(type.id)}
                onToggle={(on) => {
                  if (on) {
                    onChange([...values, type.id]);
                  } else {
                    onChange(values.filter((id) => id !== type.id));
                  }
                }}
              />
            </div>
          ))}
        </div>
      </Rows>
    </div>
  );
};
