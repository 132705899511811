import { useEditorRef } from "@udecode/plate-common";
import { cn } from "lib/utils";
import { isOdoHeading, OdoHAny } from "odo";
import { getElement } from "providers/DocElementRefProvider";
import { useEditorDocData } from "providers/RequirementContentEditorProvider";
import { FC, useEffect, useState } from "react";

interface PendingHighlight {
  id: string;
  first: HTMLElement;
  style: "top" | "bottom" | "border";
}

interface SectionHighlight extends PendingHighlight {
  last: HTMLElement;
}

const SectionHighlightOverlay: FC = () => {
  const editor = useEditorRef();
  const {
    containerRef,
    highlightedBlocks,
    highlightedRequirements,
    droppingSection,
    activeSection,
  } = useEditorDocData();
  const [highlights, setHighlights] = useState<SectionHighlight[]>([]);

  useEffect(() => {
    // Calculate the section highlights based on the highlighted blocks
    // This works as point in time because highlights happen when hovering over
    // blocks in the coverage view (content won't be changing/scrolling).
    if (
      highlightedBlocks.length === 0 &&
      highlightedRequirements.length === 0 &&
      !droppingSection
    ) {
      setHighlights([]);
      return;
    }

    let highlights: SectionHighlight[] = [];

    let pendingHighlight: PendingHighlight | null = null;
    for (const [index, child] of editor.children.entries()) {
      if (isOdoHeading(child)) {
        const element = getElement(editor, child.id);
        if (!element) {
          console.warn("Element not found for section", child);
          pendingHighlight = null;
          continue;
        }

        if (pendingHighlight) {
          // Set the bottom on the pending highlight
          const previousElement = getElement(
            editor,
            editor.children[index - 1]?.id as string
          );
          if (previousElement) {
            highlights.push({
              ...pendingHighlight,
              last: previousElement,
            });
          }
          pendingHighlight = null;
          continue;
        }

        // Look for highlight (block or requirement is being hovered in coverage view)
        if (
          isSectionHighlighted(
            child,
            highlightedBlocks,
            highlightedRequirements
          )
        ) {
          pendingHighlight = {
            id: child.id!,
            first: element,
            style: "border",
          };
          continue;
        }

        if (
          activeSection?.id === child.id &&
          activeSection?.mode !== "active"
        ) {
          let style: "top" | "bottom" | "border";
          let finalElement = element;
          switch (activeSection?.mode ?? "into") {
            case "above":
              style = "top";
              break;
            case "below":
              style = "bottom";
              break;
            default:
              style = "border";
              break;
          }

          pendingHighlight = {
            id: child.id!,
            first: finalElement,
            style: style,
          };
        }
      }
    }

    if (pendingHighlight) {
      const lastElement = getElement(
        editor,
        editor.children[editor.children.length - 1]?.id as string
      );
      if (lastElement) {
        highlights.push({
          ...pendingHighlight,
          last: lastElement,
        });
      }
    }

    setHighlights(highlights);
  }, [
    editor,
    highlightedBlocks,
    highlightedRequirements,
    droppingSection,
    activeSection?.id,
    activeSection?.mode,
  ]);

  // Get the rects within the container
  const container = containerRef.current;
  return (
    <>
      {container &&
        highlights.map((highlight) => {
          const rect = getRectForElements(
            highlight.first,
            highlight.last,
            container,
            "overlay"
          );
          return (
            <div
              key={highlight.id}
              className={cn(
                "border-primary absolute inset-0 pointer-events-none",
                highlight.style === "top" && "border-t-[3px]",
                highlight.style === "bottom" && "border-b-[3px]",
                highlight.style === "border" && "border rounded"
              )}
              style={rect}
            />
          );
        })}
    </>
  );
};

const isSectionHighlighted = (
  section: OdoHAny,
  highlightedBlocks: number[],
  highlightedRequirements: string[]
) => {
  if (!section.blocks && !section.requirements) {
    return;
  }
  const blocks = section.blocks;
  if (blocks) {
    for (const block of blocks) {
      if (highlightedBlocks.includes(block)) {
        return true;
      }
    }
  }

  const requirements = section.requirements;
  if (requirements) {
    for (const req of requirements) {
      if (highlightedRequirements.includes(req.toString())) {
        return true;
      }
    }
  }
};

const getRectForElements = (
  topElement: HTMLElement,
  bottomElement: HTMLElement,
  container: HTMLElement,
  mode: "overlay" | "right"
) => {
  const containerRect = container.getBoundingClientRect();
  const topElementRect = topElement.getBoundingClientRect();
  const bottomElementRect = bottomElement.getBoundingClientRect();

  let top = Math.min(topElementRect.top, bottomElementRect.top);
  let left = Math.min(topElementRect.left, bottomElementRect.left);
  const bottom = Math.max(topElementRect.bottom, bottomElementRect.bottom);
  const right = Math.max(topElementRect.right, bottomElementRect.right);

  let height = bottom - top;
  let width = right - left;

  // Convert to relative position
  top -= containerRect.top;
  left -= containerRect.left;

  // Add padding
  const padding = 8;
  top -= padding;
  left -= padding;
  width += padding * 2;
  height += padding * 2;

  switch (mode) {
    case "overlay":
      return { top, left, height, width };
    case "right":
      return { top, left: 0, height, width: 1 };
  }
};

export default SectionHighlightOverlay;
