import { HocuspocusProvider } from "@hocuspocus/provider";
import { Requirement } from "odo";

import * as Y from "yjs";
import { get_hub_url } from "api/env";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import useYjsObject, { YjsObjectStatus } from "api/useYjsObject";
import { ProposalParticipant } from "types/ProposalParticipant";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import useFetchedData from "./useFetchedData";
import { useApiClient } from "providers/ApiClientProvider";
import { ProposalDetails, proposalDetialsFromApi } from "types/ProposalDetails";
import useProposalDetailSnapshoting from "./useProposalDetailSnapshoting";

export interface ProposalMetadata {
  title: string;
  dueDate: string | null;
  outline: Requirement[] | null;
  archivedRequirements: Requirement[];
}

interface ProposalDetailsResult {
  details: ProposalDetails;
  setDetails: Dispatch<SetStateAction<ProposalDetails>>;
  refreshDetails: () => void;

  metadata: ProposalMetadata;
  metadataStatus: YjsObjectStatus;
  setMetadata: Dispatch<SetStateAction<ProposalMetadata>>;

  participants: ProposalParticipant[];
  setParticipants: Dispatch<SetStateAction<ProposalParticipant[]>>;
}

const useProposalDetails = (
  proposalId: string
): ProposalDetailsResult | { error: unknown } | null => {
  const apiClient = useApiClient();
  const yjsProvider = useRef<HocuspocusProvider | null>(null);
  const currentUser = useAuthenticatedUser();
  const [presentUsers, setPresentUsers] = useState<Record<string, null>>({});
  const [details, setDetails, { error, refresh: refreshDetails }] =
    useFetchedData<ProposalDetails>(async () => {
      const response = await apiClient.rfp.rfpProposalRead(proposalId);
      return proposalDetialsFromApi(proposalId, response.data);
    });
  const [metadata, setMetadata, { status: metadataStatus }] =
    useYjsObject<ProposalMetadata>({
      yjsProvider: yjsProvider.current ?? null,
      key: "proposal_meta",
      syncingTypes: {
        dueDate: "safe",
        title: "safe",
        outline: "safe",
        archivedRequirements: "safe",
      },
    });

  useProposalDetailSnapshoting(proposalId, metadata);

  useEffect(() => {
    const doc = new Y.Doc();
    const newProvider = new HocuspocusProvider({
      url: get_hub_url("ws", ""),
      name: proposalId,
      token: "insufficient-secret",
      document: doc,
      preserveConnection: false,
      onAwarenessChange(data) {
        let newPresentUsers: Record<string, null> = {};
        for (const presentClient of data.states) {
          if (typeof presentClient["user"] === "string") {
            newPresentUsers[(presentClient as any)["user"]] = null;
          }
        }
        setPresentUsers((prev) => {
          if (JSON.stringify(newPresentUsers) === JSON.stringify(prev))
            return prev;

          return newPresentUsers;
        });
      },
    });
    newProvider.setAwarenessField("user", currentUser.publicId);
    yjsProvider.current = newProvider;
    return () => {
      newProvider.destroy();
    };
  }, [currentUser.publicId, proposalId]);

  if (error) {
    return { error };
  }

  if (!details || !metadata) {
    return null;
  }

  const participants: ProposalParticipant[] = details.participants.map(
    (participant) => ({
      publicId: participant.public_id!,
      color: undefined,
      displayName: participant.display_name!,
      isOwner: participant.is_owner ?? false,
      isCurrentUser: participant.public_id === currentUser.publicId,
      isPresent:
        presentUsers[participant.public_id!] !== undefined ||
        participant.public_id === currentUser.publicId,
    })
  );

  const setParticipants = async (
    update: SetStateAction<ProposalParticipant[]>
  ) => {
    setDetails((prev) => {
      if (prev === null) return prev;

      let updated: ProposalParticipant[];
      if (typeof update === "function") {
        updated = update(participants);
      } else {
        updated = update;
      }

      return {
        ...prev,
        participants: updated.map((participant) => ({
          public_id: participant.publicId,
          display_name: participant.displayName,
          is_owner: participant.isOwner,
        })),
      };
    });
  };

  return {
    metadata,
    setMetadata,
    metadataStatus,
    details,
    setDetails: (update) => {
      let updated: ProposalDetails;
      if (typeof update === "function") {
        updated = update(details as ProposalDetails);
      } else {
        updated = update;
      }
      setDetails(updated);
    },
    refreshDetails,
    participants,
    setParticipants,
  };
};

export default useProposalDetails;
