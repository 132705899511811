"use client";

import { focusEditor, useEditorRef } from "@udecode/plate-common";
import { ToolbarButton, ToolbarButtonProps } from "./Toolbar";

export interface MarkToolbarButtonProps extends ToolbarButtonProps {
  nodeType: string;
  currentMarks: Record<string, any>;
}

/**
 * Toolbar button to toggle the mark of the leaves in selection.
 */
export function MarkToolbarButton({
  nodeType,
  currentMarks,
  ...props
}: MarkToolbarButtonProps) {
  const editor = useEditorRef();

  const handleToggle = () => {
    if (currentMarks[nodeType] === true) {
      editor.removeMark(nodeType);
    } else {
      editor.addMark(nodeType, true);
    }
    focusEditor(editor);
  };

  return (
    <ToolbarButton
      onClick={handleToggle}
      pressed={currentMarks[nodeType] === true}
      {...props}
    />
  );
}
