import { ButtonHTMLAttributes, FC } from "react";
import Icon, { IconName, IconProps } from "components/common/Icon";
import { cn } from "lib/utils";
import { cva, VariantProps } from "class-variance-authority";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../EditorView/Menus/Tooltip";
import { useOptionalApiClient } from "../../providers/ApiClientProvider";
import Spinner from "./Spinner";

const buttonVariants = cva(
  "hover:opacity-50 disabled:cursor-default disabled:hover:opacity-100 flex items-center text-center justify-center disabled:text-border whitespace-nowrap",
  {
    variants: {
      variant: {
        DEFAULT: "",
        outline: "border rounded-sm",
        "outline-full": "border rounded-sm p-sm",
        input: "border rounded-sm px-md py-xs justify-start hover:opacity-100",
        solid:
          "bg-primary text-popover px-sm py-xs rounded-sm disabled:opacity-50 disabled:hover:opacity-50 font-semibold focus:outline-offset-[-1px] focus:outline focus:outline-[black]-90 focus:outline-[2px]",
        "solid-secondary":
          "bg-background text-primary rounded-sm border-primary border",
        navigation:
          "disabled:border-b disabled:border-primary disabled:text-primary h-4xl disabled:border-b-[2px]",
        destructive:
          "bg-destructive text-background px-sm py-xs rounded-sm disabled:opacity-50 disabled:hover:opacity-50 font-semibold focus:outline-offset-[-1px] focus:outline focus:outline-[black]-90 focus:outline-[2px]",
        "destructive-secondary":
          "bg-background text-destructive rounded-sm border-destructive border px-sm py-xs",
        toolbar:
          "p-md hover:bg-background-selected hover:opacity-100 hover:border-background-selected border-background border-x-[3px]",
        placeholder: "bg-background-selected px-sm py-xs rounded-sm border",
      },
      textColor: {
        DEFAULT: "text-foreground",
        primary: "text-primary",
        secondary: "text-secondary hover:opacity-100 hover:text-foreground",
      },
      size: {
        DEFAULT: "h-2xl gap-sm",
        large: "h-3xl gap-sm",
        xlarge: "h-48 gap-md",
        small: "h-lg gap-sm",
        xsmall: "h-2m gap-sm",
      },
      defaultVariants: {
        textColor: "DEFAULT",
        variant: "DEFAULT",
        size: "DEFAULT",
      },
    },
  }
);

export type ButtonVariantProps = VariantProps<typeof buttonVariants>;

export interface ButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement>,
    ButtonVariantProps {
  text?: string;
  icon?: IconName;
  iconVariant?: IconProps["variant"];
  disabled?: boolean;
  tooltip?: string;
  tooltipDelayDuration?: number;
  tooltipSide?: "top" | "bottom" | "left" | "right";
  metricsId?: string;
  isLoading?: boolean;
}

const Button: FC<ButtonProps> = ({
  text,
  icon,
  iconVariant,
  disabled = false,
  variant,
  size = "DEFAULT",
  textColor,
  children,
  tooltip,
  tooltipDelayDuration,
  tooltipSide,
  isLoading,
  metricsId,
  className,
  ...props
}) => {
  const apiClient = useOptionalApiClient();
  const content = isLoading ? (
    <>
      <Spinner size="inherit" />
      {text}
      {children}
    </>
  ) : (
    <>
      {icon ? (
        <Icon size={size as any} name={icon} variant={iconVariant} />
      ) : null}
      {text}
      {children}
    </>
  );

  const button = (
    <button
      {...props}
      disabled={disabled}
      className={cn(
        buttonVariants({ variant, size, textColor }),
        // Override explicit width if text is not empty
        (text?.length || 0) === 0
          ? "aspect-square shrink-0"
          : "aspect-auto px-2m elipsis overflow-hidden",
        !!props.onClick ? "cursor-pointer" : "",
        isLoading ? "pointer-events-none opacity-50" : "",
        className
      )}
      onClick={(e) => {
        e.stopPropagation();
        if (metricsId) {
          // Don't need to wait for this to finish since the link is opened in a new tab
          if (!apiClient) {
            console.error(
              "Attempted to record metric without an apiClient available"
            );
            return;
          }
          apiClient.recordMetric(metricsId);
        }
        if (props.onClick) {
          props.onClick(e);
        }
      }}
    >
      {content}
    </button>
  );

  if (!tooltip) {
    return button;
  }

  return (
    <Tooltip delayDuration={tooltipDelayDuration}>
      <TooltipTrigger asChild={true}>{button}</TooltipTrigger>
      <TooltipContent side={tooltipSide}>{tooltip}</TooltipContent>
    </Tooltip>
  );
};

export default Button;
