export interface ParentSectionInfo {
  id: string;
  isFirstInSection: boolean;
  isLastInSection: boolean;
  isLastSectionInDocument: boolean;
}

export interface StyleInfo {
  isInRefine: boolean;
  isInTable: boolean;
  parentSectionInfo: ParentSectionInfo | null;
}

export const DEFAULT_STYLE_INFO: StyleInfo = {
  isInRefine: false,
  isInTable: false,
  parentSectionInfo: null,
};

const isParentSectionInfoEqual = (
  a: ParentSectionInfo,
  b: ParentSectionInfo | undefined | null
) => {
  if (!b) return false;

  return (
    a.id === b.id &&
    a.isFirstInSection === b.isFirstInSection &&
    a.isLastInSection === b.isLastInSection &&
    a.isLastSectionInDocument === b.isLastSectionInDocument
  );
};

export const isStyleInfoEqual = (
  a: StyleInfo,
  b: StyleInfo | undefined | null
) => {
  if (!b) return false;

  if (a.parentSectionInfo !== null) {
    if (!isParentSectionInfoEqual(a.parentSectionInfo, b.parentSectionInfo)) {
      return false;
    }
  } else if (b.parentSectionInfo !== null) {
    return false;
  }

  return a.isInRefine === b.isInRefine && a.isInTable === b.isInTable;
};

export const cloneStyleInfo = (styleInfo: StyleInfo): StyleInfo => {
  return {
    isInRefine: styleInfo.isInRefine,
    isInTable: styleInfo.isInTable,
    parentSectionInfo: styleInfo.parentSectionInfo
      ? {
          ...styleInfo.parentSectionInfo,
        }
      : null,
  };
};
