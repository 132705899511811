import { RFPCategory } from "api/Api";
import Button from "components/common/Button";
import Columns from "components/common/containers/Columns";
import Overlay from "components/common/containers/overlays/Overlay";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import Rows from "components/common/containers/Rows";
import BasicForm, { BasicFormModel } from "components/common/forms/BasicForm";
import Icon from "components/common/Icon";
import usePaginatedData from "hooks/usePaginatedData";
import { useApiClient } from "providers/ApiClientProvider";
import { FC, useState } from "react";

interface Category extends BasicFormModel {
  name: string;
  display_name: string;
  keywords: string;
  description: string;
}

const RFPCategoriesRoute = () => {
  const apiClient = useApiClient();

  const [services, setServices, paginatedData] = usePaginatedData({
    endpoint: apiClient.rfp.rfpCategoryList,
    map: (remote) => remote,
  });

  const [editingService, setEditingService] = useState<
    RFPCategory | null | undefined
  >(undefined);

  return (
    <Rows className="grow p-lg gap-md">
      <Columns className="gap-md shrink-0">
        <Rows>
          <h1 className="text-2xl font-semibold">RFP Categories</h1>
          <p>
            These are intended to be high-level categories and are applied by an
            LLM (
            <a className="text-primary underline" href="/admin/prompts/70">
              [RFP] L1 Category Labeling
            </a>
            )
          </p>
        </Rows>
        <Button
          icon="plus"
          variant="solid"
          onClick={() => setEditingService(null)}
        />
      </Columns>
      <PaginatedTableView
        results={services}
        searchable={true}
        paginatedData={paginatedData}
        columns={[
          { name: "Applied", size: "min", center: true },
          { name: "Name" },
          { name: "Display Name" },
        ]}
        onSelect={(service) => setEditingService(service)}
        renderRow={(service, Cell, Row) => (
          <Row key={service.name}>
            <Cell center={true}>
              <Icon
                name={service.applied ? "check" : "xmark"}
                className={service.applied ? "" : "text-destructive"}
              />
            </Cell>
            <Cell>{service.name}</Cell>
            <Cell>{service.display_name}</Cell>
          </Row>
        )}
      />
      {editingService !== undefined && (
        <Overlay
          onClose={() => setEditingService(undefined)}
          maxWidth={600}
          className="w-full"
        >
          <EditServiceForm
            service={editingService as any}
            onSave={(service) => {
              setServices((prev) => {
                if (!prev) return prev;
                const keywords = service.keywords.split(",");
                if (editingService === null) {
                  return [...prev, { ...service, keywords }];
                } else {
                  return prev.map((s) => {
                    if (s.id === editingService.id) {
                      return { ...editingService, ...service, keywords };
                    }
                    return s;
                  });
                }
              });
              setEditingService(undefined);
            }}
          />
        </Overlay>
      )}
    </Rows>
  );
};

interface EditServiceFormProps {
  service: RFPCategory | null;
  onSave: (service: Category) => void;
}

const EditServiceForm: FC<EditServiceFormProps> = ({ service, onSave }) => {
  const apiClient = useApiClient();

  const handleSubmit = async (updated: Category) => {
    if (!updated.display_name) {
      throw new Error("Display Name is required");
    }

    if (!updated.name) {
      throw new Error("Name is required");
    }

    if (!/^[a-z-]+$/.test(updated.name)) {
      throw new Error("Name must be lower case with only dashes");
    }

    let updatedCategory: RFPCategory = {
      ...updated,
      keywords: updated.keywords.split(","),
    };

    if (service) {
      await apiClient.rfp.rfpCategoryPartialUpdate(
        service.id!,
        updatedCategory
      );
    } else {
      await apiClient.rfp.rfpCategoryCreate(updatedCategory);
    }
    onSave(updated);
  };

  return (
    <BasicForm<Category>
      initialModel={
        service
          ? {
              name: service.name,
              display_name: service.display_name,
              keywords: service.keywords.join(","),
              description: service.description || "",
            }
          : {
              name: "",
              display_name: "",
              keywords: "",
              description: "",
            }
      }
      inputNames={{
        name: "Name",
        display_name: "Display Name",
        keywords: "Keywords",
        description: "Description",
      }}
      inputTypes={{
        keywords: "string_list",
        description: "long",
      }}
      submitText="Save"
      onSubmit={handleSubmit}
    />
  );
};

export default RFPCategoriesRoute;
