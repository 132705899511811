import {
  createPluginFactory,
  isElement,
  isRangeInSameBlock,
  PlateEditor,
  TNodeEntry,
} from "@udecode/plate";
import {
  DEFAULT_STYLE_INFO,
  isStyleInfoEqual,
  StyleInfo,
  cloneStyleInfo,
} from "./StyleInfo";
import { isOdoHeading, isOdoRefine } from "odo";
import { BaseRange, Path } from "slate";

export const createStylingPlugin = createPluginFactory({
  key: "odo-styling",
  withOverrides: (editor, plugin) => {
    const { normalizeNode } = editor;

    editor.normalizeNode = ([node, path]) => {
      if (path.length > 0) {
        // We're only going to operate on the whole editor
        return normalizeNode([node, path]);
      }

      // Maintain styling information for each element

      let styleInfo: StyleInfo = {
        ...DEFAULT_STYLE_INFO,
      };

      // Recursively update the style info for each node
      if (Array.isArray(node.children)) {
        for (const [index, childEntry] of node.children.entries()) {
          styleInfo = updateStyleInfo(
            editor,
            [childEntry, [...path, index]],
            styleInfo
          );
        }
      }

      return normalizeNode([node, path]);
    };

    return editor;
  },
});

const applyStyleInfo = (
  editor: PlateEditor,
  entry: TNodeEntry,
  styleInfo: StyleInfo
) => {
  if (entry[1].length === 1 && entry[1][0] === -1) {
    // This indicates that the node isn't in the editor hierarchy
    // e.g. in a refine old block
    entry[0].styleInfo = styleInfo;
  }

  const existingStyleInfo = entry[0].styleInfo as StyleInfo;
  if (isStyleInfoEqual(styleInfo, existingStyleInfo)) {
    return;
  }

  // @ts-ignore
  editor.setNodes({ styleInfo: cloneStyleInfo(styleInfo) }, { at: entry[1] });
};

const updateStyleInfo = (
  editor: PlateEditor,
  entry: TNodeEntry,
  styleInfo: StyleInfo
): StyleInfo => {
  const [node, path] = entry;
  if (!isElement(node)) {
    return styleInfo;
  }

  // Update parent style info
  // ----------------------------

  // Props to reset after processing the children
  let restoredProps: Partial<StyleInfo> = {};
  if (node.type === "refine" && !styleInfo.isInRefine) {
    styleInfo.isInRefine = true;
    restoredProps.isInRefine = false;
  }

  if (path.length === 1) {
    // We're looking at root nodes
    if (isOdoHeading(node) && node.id) {
      // Need to look ahead to collect certain information
      // 1. Number of blocks in this section
      // 2. Is this the last section?
      const { maxIndex, isLastSectionInDoc } = lookAheadForSectionInfo(
        editor,
        path
      );

      // Update parent section info
      const startIndex = path[path.length - 1];
      styleInfo.parentSectionInfo = {
        id: node.id,
        isFirstInSection: true,
        isLastInSection: startIndex === maxIndex,
        isLastSectionInDocument: isLastSectionInDoc,
      };
    } else if (styleInfo.parentSectionInfo) {
      // This is no longer the first section
      styleInfo.parentSectionInfo.isFirstInSection = false;
      //   styleInfo.parentSectionInfo.isLastInSection =
      //     path[path.length - 1] === styleInfo.parentSectionInfo.endIndex;
    }
  }

  applyStyleInfo(editor, entry, styleInfo);

  // Updates that should only be applied to children
  // --------------------------

  if (node.type === "table" && !styleInfo.isInTable) {
    styleInfo.isInTable = true;
    restoredProps.isInTable = false;
  }

  // Process all children
  // --------------------------

  if (node.children && Array.isArray(node.children)) {
    let path = entry[1];
    for (const [index, childEntry] of node.children.entries()) {
      styleInfo = updateStyleInfo(
        editor,
        [childEntry, [...path, index]],
        styleInfo
      );
    }
  }

  if (isOdoRefine(node)) {
    for (const childEntry of node.old) {
      styleInfo = updateStyleInfo(editor, [childEntry as any, [-1]], styleInfo);
    }
  }

  // Restore props
  // --------------------------

  if (Object.keys(restoredProps).length > 0) {
    styleInfo = { ...styleInfo, ...restoredProps };
  }

  return styleInfo;
};

const getChildrenAtPath = (editor: PlateEditor, path: Path) => {
  const [node] = editor.node(path);
  if (!("children" in node) || !Array.isArray(node.children)) {
    return [];
  }

  return node.children;
};

const lookAheadForSectionInfo = (
  editor: PlateEditor,
  sectionHeaderPath: Path
): { maxIndex: number; isLastSectionInDoc: boolean; isSelected: boolean } => {
  const children = getChildrenAtPath(editor, sectionHeaderPath.slice(0, -1));

  let isSelected = false;

  // Loop forward from the section header to find the next section header
  // (or the end of the document)
  let index = sectionHeaderPath[sectionHeaderPath.length - 1] + 1;
  while (index < children.length) {
    const child = children[index];
    const nextPath = [...sectionHeaderPath.slice(0, -1), index];
    isSelected = isSelected || isBlockAtPathSelected(editor, nextPath);
    if (isOdoHeading(child)) {
      return { maxIndex: index - 1, isLastSectionInDoc: false, isSelected };
    }
    index++;
  }

  return { maxIndex: index - 1, isLastSectionInDoc: true, isSelected };
};

const isBlockAtPathSelected = (
  editor: PlateEditor,
  blockPath: Path
): boolean => {
  // A block is selected if:
  // 1. The anchor is within the block
  // 2. The focus is within the block
  // 3. The selection is fully across the block

  const selection = editor.selection;
  if (!selection) return false;
  if (!selection.anchor) return false;
  if (!selection.focus) return false;

  const commonAnchor = Path.common(selection.anchor.path, blockPath);
  if (commonAnchor.length === blockPath.length) {
    // Anchor is within the block
    return true;
  }

  const commonFocus = Path.common(selection.focus.path, blockPath);
  if (commonFocus.length === blockPath.length) {
    // Focus is within the block
    return true;
  }

  let openPath: Path;
  let closePath: Path;

  if (Path.isBefore(selection.anchor.path, selection.focus.path)) {
    openPath = selection.anchor.path;
    closePath = selection.focus.path;
  } else {
    openPath = selection.focus.path;
    closePath = selection.anchor.path;
  }

  if (
    Path.isBefore(blockPath, openPath) &&
    Path.isBefore(closePath, blockPath)
  ) {
    // Selection is fully across the block
    return true;
  }

  return false;
};
