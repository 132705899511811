import { getSelectedEnv, setSelectedEnv } from "api/env";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/EditorView/Menus/DropdownMenu";
import { cn } from "lib/utils";
import {
  useAuthenticatedUser,
  useOptionalAuthenticatedUser,
} from "providers/AuthenticatedUserProvider";
import { useEffect, useMemo, useRef, useState } from "react";
import { RouteObject, useLocation, useNavigate } from "react-router-dom";

import posthog from "posthog-js";
import ComboBox, { ResultBase } from "components/common/menus/ComboBox";
import useFetchedOptions from "hooks/useFetchedOptions";
import { useApiClient } from "providers/ApiClientProvider";
import { toast } from "react-toastify";
import { message_from_exception } from "utils";
import Rows from "components/common/containers/Rows";

const AdminBar = () => {
  const user = useOptionalAuthenticatedUser();
  const navigate = useNavigate();
  const [showingQuickActions, setShowingQuickActions] = useState(false);

  const location = useLocation();
  useEffect(() => {
    posthog.capture("$pageview");
  }, [location]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.ctrlKey || e.metaKey)) {
        setShowingQuickActions(true);
      }
      if (e.key === "Escape") setShowingQuickActions(false);
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [setShowingQuickActions]);

  const availableEnvs = useMemo(() => {
    const raw = process.env.REACT_APP_AVAILABLE_DOMAINS;
    if (!raw) return [];

    try {
      const dict = JSON.parse(raw);
      return Object.keys(dict);
    } catch {
      return [];
    }
  }, []);

  const { name: selectedEnv, isOverride } = getSelectedEnv();

  if (user === undefined) return null;
  if (!(user?.isWriter ?? false) && !isOverride) return null;

  const handleSelectEnv = (env: string) => {
    setSelectedEnv(env);
    // Reload the app completely to apply the new env
    window.location.href = "/";
  };

  return (
    <>
      <div
        className={cn(
          "bg-secondary px-2m py-xs text-center text-background relative fixed bottom-0 left-0 right-0 h-xl",
          user?.isWriter && "cursor-pointer hover:opacity-80",
          selectedEnv !== "prod" && "bg-destructive"
        )}
        onClick={() => user?.isWriter && navigate("/admin")}
      >
        {user?.isStaff && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <div className="absolute left-md top-0 bottom-0 flex items-center justify-center">
                backend: {selectedEnv}
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {availableEnvs.map((env) => (
                <DropdownMenuItem
                  key={env}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleSelectEnv(env);
                  }}
                >
                  {env}
                </DropdownMenuItem>
              ))}
            </DropdownMenuContent>
          </DropdownMenu>
        )}
        {user?.isWriter ? "Admin" : "Override Only"}
        <div className="absolute right-md top-0 bottom-0 flex items-center justify-center">
          web: {process.env.REACT_APP_DEPLOY_VERSION}
        </div>
      </div>
      {showingQuickActions && (
        <div
          className="absolute inset-0 flex flex-col items-center"
          onClick={() => setShowingQuickActions(false)}
        >
          <div className="absolute inset-0 bg-background opacity-50" />
          <div className="h-[20vh]" />
          <ActionView hide={() => setShowingQuickActions(false)} />
        </div>
      )}
    </>
  );
};

interface Action extends ResultBase {
  display: string;
  handle: () => void;
  requireStaff: boolean;
}

const ActionView = ({ hide }: { hide: () => void }) => {
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const authenticatedUser = useAuthenticatedUser();
  const [searchText, setSearchText] = useState("");
  const orgs: Action[] = useFetchedOptions(
    {
      endpoint: apiClient.orgs.orgsList,
      searchText,
    },
    authenticatedUser.isStaff
  ).map((org) => ({
    id: "org-" + org.public_id!,
    display: org.name,
    category: "Organizations",
    requireStaff: true,
    handle: async () => {
      try {
        await apiClient.orgs.orgsSetCreate({ public_id: org.public_id! });
        window.location.href = "/";
      } catch (e) {
        toast.error(message_from_exception(e));
      }
    },
  }));

  const navigateAction = (
    id: string,
    display: string,
    destination: string,
    requireStaff = true
  ): Action => {
    return {
      id: "nav-" + id,
      category: "Navigate",
      display,
      requireStaff,
      handle: () => {
        navigate(destination);
      },
    };
  };

  const hardcodedActions = useRef(
    [
      navigateAction("home", "Admin Home", "/admin/", false),
      navigateAction("prompts", "Prompts", "/admin/prompts/"),
      navigateAction(
        "proposals",
        "Unpublished Proposals",
        "/admin/proposals/",
        false
      ),
      navigateAction("rfps", "RFPs", "/admin/rfps/"),
      navigateAction("orgs", "Organizations", "/admin/orgs/"),
      navigateAction("logout", "Logout", "/logout", false),
      navigateAction("event", "Events", "/admin/events/"),
      navigateAction("editor", "Editor", "/admin/editor/"),
      navigateAction("search", "Search", "/admin/search/", false),
      navigateAction(
        "rfp-categories",
        "RFP Categories",
        "/admin/rfp-categories/"
      ),
      navigateAction("exit", "Exit", "/", false),
    ]
      .filter((action) => !action.requireStaff || authenticatedUser.isStaff)
      .sort((a, b) => a.display.localeCompare(b.display))
  );

  const actions = hardcodedActions.current.concat(orgs);

  return (
    <div
      className="max-w-[600px] w-full bg-background popover p-md"
      onClick={(e) => e.stopPropagation()}
    >
      <ComboBox
        placeholder="Search for an action"
        options={actions}
        renderOption={(option) => option.display}
        onSelected={(option) => {
          option.handle();
          hide();
        }}
        autoFocus={true}
        filterOptions={(options, text) => {
          setSearchText(text);
          return options.filter((option) =>
            option.display.toLowerCase().includes(text.toLowerCase())
          );
        }}
      />
    </div>
  );
};

export const wrapInAdminBar = (routes: RouteObject[]) => {
  return routes.map((route) => {
    return {
      ...route,
      element: (
        <Rows className="grow pb-xl">
          <Rows className="relative grow">{route.element}</Rows>
          <AdminBar />
        </Rows>
      ),
    };
  });
};
