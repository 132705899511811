import { RFPLabel } from "api/Api";
import { AxiosError } from "axios";
import Button from "components/common/Button";
import Icon from "components/common/Icon";
import NavigationView from "components/common/NavigationView";
import TypingAnimation from "components/common/TypingAnimation";
import AsyncLoadedDiv from "components/common/containers/AsyncLoadedDiv";
import Columns from "components/common/containers/Columns";
import LoadableView from "components/common/containers/LoadableView";
import MessageView from "components/common/containers/MessageView";
import Rows from "components/common/containers/Rows";
import Input from "components/common/forms/Input";
import RFPComparisionView from "components/rfps/RFPComparisonView";
import RFPLabelDropDown from "components/rfps/RFPLabelDropDown";
import useFetchedData from "hooks/useFetchedData";
import { useApiClient } from "providers/ApiClientProvider";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  RFPOrgComparison,
  rfpOrgComparisonFromApi,
} from "types/RFPOrgComparison";
import { message_from_exception } from "utils";

const RFPsRoute = () => {
  const apiClient = useApiClient();
  const [queryParams, setQueryParams] = useSearchParams();
  const [pendingSearchText, setPendingSearchText] = useState(
    queryParams.get("q") ?? ""
  );
  const labelIds = queryParams.getAll("l");
  const [searchText, setSearchText] = useState(pendingSearchText);
  const [selectedLabels, setSelectedLabels] = useState<RFPLabel[]>([]);
  const [comparisons, setComparisons, { error, refresh, status }] =
    useFetchedData(async () => {
      try {
        const response = await apiClient.rfp.rfpComparisonList(
          // @ts-ignore
          { search: searchText, labels: selectedLabels.map((l) => l.id) }
        );
        if (response.status === 204) {
          return "pending";
        }
        return response.data.results.reduce((acc, comparison) => {
          const rfpOrgComparison = rfpOrgComparisonFromApi(comparison);
          if (rfpOrgComparison) {
            acc.push(rfpOrgComparison);
          }
          return acc;
        }, [] as RFPOrgComparison[]);
      } catch (error) {
        if (error instanceof AxiosError && error.response?.status === 404) {
          return "unapproved";
        } else if (
          error instanceof AxiosError &&
          error.response?.status === 425
        ) {
          return "no-profile";
        } else if (
          error instanceof AxiosError &&
          error.response?.status === 418
        ) {
          return "not-started";
        } else {
          throw error;
        }
      }
    }, [searchText, selectedLabels]);

  const handleRemove = (comparison: RFPOrgComparison) => {
    setComparisons((prev) => {
      if (
        prev === "unapproved" ||
        prev === "no-profile" ||
        prev === "not-started" ||
        prev === "pending"
      ) {
        return prev;
      }
      return prev?.filter((c) => c.id !== comparison.id) ?? null;
    });
  };

  const handleSearch = useCallback(() => {
    setQueryParams((prev) => {
      const params = new URLSearchParams(prev);
      if (pendingSearchText) {
        params.set("q", pendingSearchText);
      } else {
        params.delete("q");
      }
      params.delete("l");
      for (const label of selectedLabels) {
        params.append("l", label.id!.toString());
      }
      return params;
    });
    setSearchText(pendingSearchText);
  }, [pendingSearchText, selectedLabels, setQueryParams]);

  const handleClear = useCallback(() => {
    setPendingSearchText("");
    setQueryParams((prev) => {
      const params = new URLSearchParams(prev);
      params.delete("q");
      return params;
    });
    setSearchText("");
  }, [setQueryParams]);

  useEffect(() => {
    handleSearch();
  }, [selectedLabels]);

  useEffect(() => {
    if (!labelIds) {
      return;
    }
    const load = async () => {
      const response = await apiClient.rfp.rfpRfpLabelList();
      setSelectedLabels(
        response.data.results.filter((l) =>
          labelIds?.includes(l.id!.toString())
        )
      );
    };
    load();
  }, []);

  return (
    <NavigationView
      selected="rfps"
      containerClassName={
        typeof comparisons === "string" ||
        (comparisons?.length === 0 && !searchText)
          ? "bg-background-secondary"
          : undefined
      }
    >
      <AsyncLoadedDiv
        value={comparisons}
        error={error ? message_from_exception(error) : null}
        className="grow flex flex-col w-full gap-md items-center overflow-visible"
        whileLoaded={(comparisons) => {
          if (typeof comparisons === "string") {
            if (comparisons === "unapproved") {
              return <ComingSoonSplash />;
            }
            if (comparisons === "not-started") {
              return <StartComparisonsSplash refresh={refresh} />;
            }
            if (comparisons === "pending") {
              return <PendingResultsSplash refresh={refresh} />;
            }
            return <SetProfileSplash />;
          }

          if (
            comparisons.length === 0 &&
            selectedLabels.length === 0 &&
            !searchText
          ) {
            return <NoResultsSplash />;
          }

          return (
            <Rows className="grow gap-md overflow-visible w-full">
              <h1 className="text-2xl font-semibold w-full">RFPs</h1>
              <p className="text-secondary">
                Discover RFPs that match your company’s profile.
              </p>
              <Columns className="gap-md m-thin mb-md">
                <RFPLabelDropDown
                  variant="input"
                  allowCreating={false}
                  selectedLabels={selectedLabels}
                  onToggleLabel={(label) => {
                    setSelectedLabels((prev) => {
                      if (prev.map((l) => l.id).includes(label.id)) {
                        return prev.filter((l) => l.id !== label.id);
                      }
                      return [...prev, label];
                    });
                  }}
                />
                <Input
                  icon="magnifying-glass"
                  placeholder="Search"
                  className="sticky m-thin grow basis-0 min-w-[100px] tablet:min-w-[200px]"
                  variant="accent"
                  value={pendingSearchText}
                  valueClearable={true}
                  onChange={(e) => {
                    setPendingSearchText(e.target.value ?? "");
                  }}
                  onClear={handleClear}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSearch();
                    }
                  }}
                />
              </Columns>
              <LoadableView isLoading={status === "loading"}>
                <Rows className="grow flex flex-col gap-3xl w-full">
                  {comparisons.map((comparison) => {
                    const handleSetComparison: Dispatch<
                      SetStateAction<RFPOrgComparison>
                    > = (action) => {
                      setComparisons((prev) => {
                        if (!prev) return prev;
                        if (typeof prev === "string") {
                          return prev;
                        }
                        return prev.map((c) => {
                          if (c.id === comparison.id) {
                            if (typeof action === "function") {
                              return action(c);
                            }
                            return action;
                          }
                          return c;
                        });
                      });
                    };
                    return (
                      <RFPComparisionView
                        key={comparison.id}
                        comparison={comparison}
                        setComparison={handleSetComparison}
                        remove={() => handleRemove(comparison)}
                      />
                    );
                  })}
                  {comparisons.length === 0 && (
                    <Rows className="w-full">
                      <h1 className="text-xl mb-sm font-semibold">
                        No Results
                      </h1>
                      <p className="">
                        We don't currently have any RFPs matching that search
                        and your profile.
                      </p>
                      <p>Please try another search.</p>
                    </Rows>
                  )}
                </Rows>
              </LoadableView>
            </Rows>
          );
        }}
      />
    </NavigationView>
  );
};

const ComingSoonSplash = () => {
  return (
    <div className="grow flex flex-col items-center justify-center gap-md">
      <Icon name="lightbulb-on" size="huge" variant="solid" />
      <h1 className="text-2xl font-semibold">RFP Recommendations</h1>
      <p>Discover RFPs that match your company’s profile.</p>
      <TypingAnimation
        className="mt-md text-xl"
        options={["Coming Soon"]}
        type="once"
      />
    </div>
  );
};

const NoResultsSplash = () => {
  return (
    <MessageView
      icon="lightbulb-on"
      title="RFP Recommendations"
      variant="above"
    >
      <p className="text-center">
        We don't currently have any RFPs that are a good match.
      </p>
      <p>We'll email you once we find some.</p>
    </MessageView>
  );
};

const PendingResultsSplash = ({ refresh }: { refresh: () => void }) => {
  // Refresh the data every 5 seconds
  useEffect(() => {
    const interval = setInterval(refresh, 5000);
    return () => clearInterval(interval);
  });

  return (
    <MessageView
      icon="lightbulb-on"
      title="RFP Recommendations"
      variant="above"
    >
      <p className="text-center">
        We're looking for RFPs that match your profile.
      </p>
      <TypingAnimation options={["Hang tight for a minute"]} type="loop" />
    </MessageView>
  );
};

const SetProfileSplash = () => {
  const navigate = useNavigate();
  return (
    <MessageView
      icon="lightbulb-on"
      title="RFP Recommendations"
      variant="above"
    >
      <p>Discover RFPs that match your company’s profile</p>
      <Button
        variant="solid"
        icon="pen"
        iconVariant="solid"
        text="Fill Out Profile"
        onClick={() => navigate("/account/company-profile/")}
      />
    </MessageView>
  );
};

const StartComparisonsSplash = ({ refresh }: { refresh: () => void }) => {
  const apiClient = useApiClient();

  const handleStart = async () => {
    try {
      await apiClient.rfp.rfpComparisonStartCreate();
      refresh();
    } catch (error) {
      toast.error(message_from_exception(error));
    }
  };

  return (
    <MessageView
      icon="lightbulb-on"
      title="RFP Recommendations"
      variant="above"
    >
      <p>Discover RFPs that match your company’s profile</p>
      <Button
        variant="solid"
        icon="play"
        iconVariant="solid"
        text="Find RFPs"
        onClick={handleStart}
      />
    </MessageView>
  );
};

export default RFPsRoute;
