import { InputProps } from "./Input";
import Columns from "../containers/Columns";
import { cn } from "lib/utils";

interface MonthInputProps extends Omit<InputProps, "value" | "onChange"> {
  month: number | null;
  year: number | null;
  onChange: (value: { month: number | null; year: number | null }) => void;
}

const MonthInput: React.FC<MonthInputProps> = ({
  month,
  year,
  onChange,
  onKeyDown,
  onKeyUp,
  className,
  ...props
}) => {
  const setMonth = (value: string) => {
    const parsed = parseInt(value);
    if (isNaN(parsed)) {
      onChange({ month: null, year });
    } else {
      onChange({ month: parsed, year });
    }
  };

  const setYear = (value: string) => {
    const parsed = parseInt(value);
    if (isNaN(parsed)) {
      onChange({ month, year: null });
    } else {
      onChange({ month, year: parsed });
    }
  };

  return (
    <Columns className={cn("border rounded-md", className)} {...props}>
      <div className="grow basis-0 relative">
        <input
          placeholder="mm"
          className="absolute p-sm inset-0 text-right"
          value={month !== null ? month.toString() : ""}
          onChange={(e) => setMonth(e.target.value)}
        />
      </div>
      <Columns className="items-center">
        <p className="shrink-0 basis-0">/</p>
      </Columns>
      <div className="grow basis-0 relative">
        <input
          placeholder="yy"
          className="absolute p-sm inset-0"
          value={year !== null ? year.toString() : ""}
          onChange={(e) => setYear(e.target.value)}
        />
      </div>
    </Columns>
  );
};

export default MonthInput;
