import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApiClient } from "providers/ApiClientProvider";
import useFetchedData from "hooks/useFetchedData";
import AsyncLoadedDiv from "components/common/containers/AsyncLoadedDiv";
import { message_from_exception } from "utils";
import * as Sentry from "@sentry/react";
import MessageView from "components/common/containers/MessageView";
import Button from "components/common/Button";
import Overlay from "components/common/containers/overlays/Overlay";
import Spinner from "components/common/Spinner";
import useFileHash from "hooks/useFileHash";
import LoadableView from "components/common/containers/LoadableView";
import Icon from "components/common/Icon";
import Rows from "components/common/containers/Rows";
import AddRFPForm from "components/rfps/AddRFPForm";

interface NewProposalFlowProps {
  open: boolean;
  existingRfp?: { id: string; title: string };
  onStarted?: () => void;
  onClose?: () => void;
}

const StartNewProposalOverlay: React.FC<NewProposalFlowProps> = ({
  existingRfp,
  onStarted,
  onClose,
  open,
}) => {
  const apiClient = useApiClient();
  const [shiftKeyDown, setShiftKeyDown] = useState(false);
  const [eligibility, , { error }] = useFetchedData<string>(async () => {
    if (!open) return null;
    return (
      await apiClient.rfp.rfpProposalEligibilityCreate({ force: shiftKeyDown })
    ).data.eligibility!;
  }, [open]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Shift") {
        setShiftKeyDown(true);
      }
    };
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === "Shift") {
        setShiftKeyDown(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  if (!open) {
    return null;
  }

  return (
    <Overlay scrollable={false} title="" onClose={onClose}>
      <AsyncLoadedDiv
        error={error ? message_from_exception(error) : null}
        value={eligibility}
        className="grow flex overflow-hidden"
        whileLoaded={(eligibility) => {
          if (!eligibility) return null;
          switch (eligibility) {
            case "allowed":
            case "trial":
              if (existingRfp) {
                return (
                  <StartProposalFromExistingRFP
                    existingRfp={existingRfp}
                    onStarted={onStarted}
                  />
                );
              } else {
                return <StartProposalFromNewRFP onStarted={onStarted} />;
              }
            case "demo_required":
              return <DemoSplash fromExistingRFP={!!existingRfp} />;
            case "trial_expired":
              return <TrialExpiredSplash />;
            default:
              Sentry.captureMessage(
                `Unknown proposal creation eligibility: ${eligibility}`
              );
              return <TrialExpiredSplash />;
          }
        }}
      />
    </Overlay>
  );
};

interface StartProposalFromExistingRFPProps {
  existingRfp: { id: string; title: string };
  onStarted?: () => void;
}

const DemoSplash: React.FC<{ fromExistingRFP: boolean }> = ({
  fromExistingRFP,
}) => {
  const bookDemoLink = `https://www.odo.do/book-demo/?source=app-start-proposal${
    fromExistingRFP ? "-from-existing" : "-from-new"
  }`;
  console.log(bookDemoLink);
  return (
    <MessageView
      icon="file-plus"
      title="Start a Proposal"
      className="max-w-[460px] text-center"
    >
      <p>
        We generate a proposal draft so you don’t have to start from scratch.
        Learn more how you can save up to 80% of your time writing proposals.
      </p>
      <Button
        text="Book Demo"
        variant="solid"
        onClick={() => (window.location.href = bookDemoLink)}
      />
    </MessageView>
  );
};

const TrialExpiredSplash: React.FC = () => {
  return (
    <MessageView
      icon="file-plus"
      title="Start a Proposal"
      className="max-w-[440px] text-center"
    >
      <p>Your organization has used its free proposal.</p>
      <p>
        Let's discuss how we can support you in winning more government
        contracts!
      </p>
      <Button
        text="Contact Us"
        variant="solid"
        onClick={() => (window.location.href = "mailto:support@odo.do")}
      />
    </MessageView>
  );
};

const StartProposalFromExistingRFP: React.FC<
  StartProposalFromExistingRFPProps
> = ({ existingRfp, onStarted }) => {
  const navigate = useNavigate();
  const apiClient = useApiClient();
  const [error, setError] = useState<unknown>(null);

  useEffect(() => {
    // Create the proposal right away
    const createProposal = async () => {
      try {
        const response = await apiClient.rfp.rfpProposalCreate({
          rfp_id: existingRfp.id,
        });
        navigate(`/proposal/${response.data.id}/`);
      } catch (e) {
        setError(e);
      }
    };
    createProposal();
  }, [apiClient.rfp, existingRfp.id, navigate]);

  if (error) {
    return (
      <MessageView title="Error" icon="square-exclamation" iconVariant="solid">
        <p>There was an error starting the proposal.</p>
        <p>{message_from_exception(error)}</p>
      </MessageView>
    );
  }
  return <Spinner text="Creating proposal..." />;
};

interface StartProposalFromNewRFPProps {
  onStarted?: () => void;
}

const StartProposalFromNewRFP: React.FC<StartProposalFromNewRFPProps> = ({
  onStarted,
}) => {
  const apiClient = useApiClient();
  const hashFile = useFileHash();
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleUploadRFPFile = async (file: File) => {
    // 1. Create the RFP record on the server
    // 2. Upload the file to S3
    // 3. Create a new proposal for the RFP
    try {
      setIsLoading(true);

      // First, get a hash of the file
      const hash = await hashFile(file);

      // Get name without extension
      const fileName = file.name.split(".").slice(0, -1).join(".");
      const rfpCreateResponse = await apiClient.rfp.rfpRfpCreate({
        hash,
        name: fileName,
      });

      if (rfpCreateResponse.data.upload_url) {
        // We have to upload the file to this url
        await fetch(rfpCreateResponse.data.upload_url, {
          method: "PUT",
          body: file,
        });
      }

      // Next, create a new proposal for this RFP
      const proposalCreateResponse = await apiClient.rfp.rfpProposalCreate({
        rfp_id: rfpCreateResponse.data.id,
      });

      navigate(`/proposal/${proposalCreateResponse.data.id}/`);
    } catch (e) {
      setError(message_from_exception(e));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoadableView
      isLoading={isLoading}
      className="w-full flex flex-col relative items-center justify-center gap-md grow"
    >
      <Rows className="items-center gap-md w-full min-w-[300px] p-lg">
        <Icon name="file-arrow-up" size="huge" variant="solid" />
        <h1 className="text-2xl font-semibold">Upload the RFP</h1>
        <p>Upload the files associated with the RFP</p>
        <AddRFPForm
          confirmText="Next"
          forAdmin={false}
          onRfpUploaded={async (rfpId) => {
            // Create a new proposal for this RFP
            const proposalCreateResponse =
              await apiClient.rfp.rfpProposalCreate({
                rfp_id: rfpId,
              });

            navigate(`/proposal/${proposalCreateResponse.data.id}/`);
          }}
        />
      </Rows>
    </LoadableView>
  );
};

export default StartNewProposalOverlay;
