import {
  focusEditor,
  PlateEditor,
  useEditorState,
} from "@udecode/plate-common";
import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";
import StatusDropDown from "components/proposals/StatusDropDown";
import { cn } from "lib/utils";
import { getSectionInfoFromElement, isOdoHeading, SectionInfo } from "odo";
import { useEditorDocData } from "providers/RequirementContentEditorProvider";
import { FC } from "react";

interface OutlineNavPanelProps {}

const OutlineNavPanel: FC<OutlineNavPanelProps> = () => {
  const editor = useEditorState();
  const { scrollToElement, cursorFocusedSection: selectedSection } =
    useEditorDocData();

  const sections: SectionInfo[] = collectOutlineElements(editor);

  const handleSectionClick = (section: SectionInfo) => {
    scrollToElement((element) => {
      const sectionInfo = getSectionInfoFromElement(element);
      return sectionInfo?.id === section.id;
    });
    setTimeout(() => {
      const path = pathForSection(section, editor);
      if (path) {
        const full = editor.end(path);
        focusEditor(editor, full);
      }
    }, 200);
  };

  const handleStatusChange = (
    sectionId: string,
    {
      status,
      statusName,
    }: {
      status: string;
      statusName: string;
    }
  ) => {
    editor.setNodes(
      // @ts-ignore
      { status, statusName },
      {
        at: [],
        match: (element) => {
          return isOdoHeading(element) && element.id === sectionId;
        },
      }
    );
  };

  return (
    <Rows className="overflow-y-auto bg-accent w-full">
      {sections.map((section) => (
        <Columns
          key={section.id}
          className={cn(
            "items-center py-xs px-md shrink-0 hover:bg-background-selected cursor-pointer",
            selectedSection?.id === section.id && "bg-background-selected"
          )}
          style={{ paddingLeft: (section.level - 1) * 24 }}
          onClick={() => handleSectionClick(section)}
        >
          <StatusDropDown
            className="shrink-0 grow-0 p-md"
            status={section.status ?? "blank"}
            statusName={section.statusName ?? undefined}
            onChange={(status) => handleStatusChange(section.id, status)}
          />
          <p className="grow">{section.name}</p>
        </Columns>
      ))}
    </Rows>
  );
};

const pathForSection = (section: SectionInfo, editor: PlateEditor) => {
  for (const [index, child] of editor.children.entries()) {
    if (!isOdoHeading(child)) continue;
    const sectionInfo = getSectionInfoFromElement(child);
    if (sectionInfo?.id === section.id) {
      return [index];
    }
  }
  return null;
};

const collectOutlineElements = (editor: PlateEditor) => {
  const sections: SectionInfo[] = editor.children
    .map((child) => getSectionInfoFromElement(child))
    .filter((section) => !!section) as SectionInfo[];
  return sections;
};

export default OutlineNavPanel;
