import { PlateEditor, TElement, useEditorRef } from "@udecode/plate";
import { DEFAULT_STYLE_INFO, StyleInfo } from "./StyleInfo";
import { ReactEditor } from "slate-react";
import { getSectionInfoFromElement, isOdoRefine, isOdoTable } from "odo";

export const useStyleInfo = (element: TElement): StyleInfo => {
  const editor = useEditorRef();
  if (element.styleInfo) {
    return element.styleInfo as StyleInfo;
  }

  return calculateStyleInfoRealtime(editor, element) || DEFAULT_STYLE_INFO;
};

/**
 * Get the style info in real-time
 *
 * This is needed for newly inserted elements withotu style info yet
 */
const calculateStyleInfoRealtime = (
  editor: PlateEditor,
  element: TElement
): StyleInfo | null => {
  const path = ReactEditor.findPath(editor as any, element);

  if (!path || path.length === 0 || editor.children.length === 0) {
    return null;
  }

  let index = path[0];
  let minIndex: number | null = null;
  let maxIndex = index;
  let sectionId: string | null = null;

  let nextIndex = index;
  while (nextIndex >= 0) {
    const [element] = editor.node([nextIndex]);
    const sectionInfo = getSectionInfoFromElement(element);
    if (sectionInfo) {
      minIndex = nextIndex;
      // @ts-ignore
      sectionId = element.id;
      break;
    }
    nextIndex--;
  }

  if (minIndex === null || sectionId === null) {
    return null;
  }

  nextIndex = index + 1;
  while (nextIndex < editor.children.length) {
    const [element] = editor.node([nextIndex]);
    if (getSectionInfoFromElement(element)) {
      break;
    }
    maxIndex = nextIndex;
    nextIndex++;
  }

  let isInRefine = false;
  let isInTable = false;

  // Walk up path to find if the element is in a refine or table
  let currentPath = path;
  while (currentPath.length > 0) {
    const [node] = editor.node(currentPath);
    if (!node) {
      break;
    }

    if (isOdoRefine(node)) {
      isInRefine = true;
    }

    if (isOdoTable(node)) {
      isInTable = true;
    }

    currentPath = currentPath.slice(0, currentPath.length - 1);
  }

  return {
    isInRefine,
    isInTable,
    parentSectionInfo: {
      id: sectionId,
      isFirstInSection: minIndex === index,
      isLastInSection: maxIndex === index,
      isLastSectionInDocument: maxIndex === editor.children.length - 1,
    },
  };
};
