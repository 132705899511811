import { PlateElementProps, Value } from "@udecode/plate-common";
import { TImageElement, useMediaState } from "@udecode/plate-media";
import { useResizableStore } from "@udecode/plate-resizable";
import { useApiClient } from "providers/ApiClientProvider";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import {
  mediaResizeHandleVariants,
  Resizable,
  ResizeHandle,
} from "./Resizable";
import { SuggestableElement } from "components/proposals/refine/SuggestableElement";
import { cn } from "lib/utils";
import { toast } from "react-toastify";
import Spinner from "components/common/Spinner";
import CenteredContainer from "components/common/containers/CenteredContainer";

export function ImageElement({
  className,
  children,
  nodeProps,
  ...props
}: PlateElementProps<Value, TImageElement>) {
  const apiClient = useApiClient();
  const { readOnly, focused, selected, align = "center" } = useMediaState();
  const [imageURL, setImageURL] = React.useState<string>();
  const [loaded, setLoaded] = React.useState(false);

  const containerRef = useRef(null);

  const width = useResizableStore().get.width();
  const displayWidth =
    typeof width === "number" && width > 0 ? width : undefined;
  const [height, setHeight] = useState<number | undefined>(undefined);
  const parsed = parseRawUrl(props.element.url);

  // Resize observer to adjust the size dynamically
  useEffect(() => {
    const container = containerRef.current;
    if (!container) {
      return;
    }
    const aspectRatio = parsed?.aspectRatio;
    if (!aspectRatio) {
      setHeight(undefined);
      return;
    }
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const { width } = entry.contentRect;
        setHeight(width / aspectRatio);
      }
    });

    resizeObserver.observe(container);

    return () => {
      resizeObserver.unobserve(container);
    };
  }, [parsed?.aspectRatio]);

  useEffect(() => {
    let active = true;

    if (!parsed) {
      setImageURL(undefined);
      return;
    }

    const remoteUrl = parsed.id;
    if (remoteUrl) {
      const getImageUrl = async () => {
        const res = await apiClient.images
          .imagesRead(remoteUrl)
          .catch((err) => {
            toast.error(err);
            return null;
          });
        if (!active) return;
        setImageURL(res?.data.download_url);
      };
      getImageUrl();
    }
    return () => {
      active = false;
    };
  }, [apiClient.images, props.element.url, parsed?.id]);

  return (
    <div>
      <SuggestableElement
        className={cn("py-md", className)}
        {...(props as any)}
      >
        <figure className="group relative m-0" contentEditable={false}>
          <Resizable
            options={{
              align: "center",
              readOnly,
            }}
            className="mx-auto"
            ref={containerRef}
          >
            <ResizeHandle
              options={{ direction: "left" }}
              className={mediaResizeHandleVariants({ direction: "left" })}
            />
            <div
              className={cn(
                "-z-10 bg-ring",
                "animate-pulse absolute top-0 left-0"
              )}
              style={{
                width: displayWidth,
                height,
              }}
            ></div>
            <img
              key={imageURL}
              className={cn(
                "block w-full max-w-full cursor-pointer object-cover px-0",
                "bg-background",
                "animate-fade-in-fast",
                focused && selected && "ring-2 ring-ring ring-offset-2"
              )}
              alt=""
              src={imageURL}
              onLoad={() => setLoaded(true)}
              style={{
                width: displayWidth,
                height,
              }}
              {...nodeProps}
            />
            <ResizeHandle
              options={{ direction: "right" }}
              className={mediaResizeHandleVariants({ direction: "right" })}
            />
          </Resizable>

          {/*<Caption align={align} style={{ width }}>*/}
          {/*  <CaptionTextarea*/}
          {/*    placeholder="Write a caption..."*/}
          {/*    readOnly={readOnly}*/}
          {/*  />*/}
          {/*</Caption>*/}

          {(!imageURL || !loaded) && (
            <CenteredContainer className="absolute inset-0">
              <Spinner variant="secondary" />
            </CenteredContainer>
          )}
        </figure>
        {children}
      </SuggestableElement>
    </div>
  );
}

const parseRawUrl = (rawUrl: string | null) => {
  if (!rawUrl || !rawUrl.startsWith("id:")) {
    return null;
  }

  let id: string | null = rawUrl.split("|")[0].slice("id:".length);
  if (id === "_") {
    id = null;
  }

  return {
    id,
    aspectRatio: parseFloat(rawUrl.split("|")[1]),
  };
};
