import { useLocation, useNavigate } from "react-router-dom";
import { FC, ReactNode, useEffect, useState } from "react";
import Button from "components/common/Button";
import { useApiClient } from "providers/ApiClientProvider";
import Icon from "components/common/Icon";
import { Proposal, proposalFromAPI } from "types/Proposal";
import Rows from "components/common/containers/Rows";
import Columns from "components/common/containers/Columns";
import usePaginatedData, { PaginatedData } from "hooks/usePaginatedData";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import NavigationView from "components/common/NavigationView";
import StartNewProposalOverlay from "./StartNewProposalOverlay";
import { AxiosError } from "axios";
import Spacer from "components/common/containers/Spacer";
import MessageView from "components/common/containers/MessageView";
import Grid from "components/common/containers/Grid";
import ErrorView from "components/common/containers/ErrorView";

const ProposalsRoute: FC = () => {
  const apiClient = useApiClient();
  const [showNewProposalFlow, setShowNewProposalFlow] = useState(false);
  const [proposals, , paginatedData] = usePaginatedData({
    endpoint: apiClient.rfp.rfpProposalList,
    map: proposalFromAPI,
  });

  useEffect(() => {
    document.title = "Odo - All Proposals";
    return () => {
      document.title = "Odo";
    };
  });

  let showingDemoSplash = false;
  let showingFreeTrialSplash = false;
  if (paginatedData.error && paginatedData.error instanceof AxiosError) {
    if (paginatedData.error.response?.status === 404) {
      showingDemoSplash = true;
    } else if (paginatedData.error.response?.status === 425) {
      showingFreeTrialSplash = true;
    }
  }

  let content: ReactNode;
  let containerClassName = "";
  if (showingDemoSplash) {
    containerClassName = "bg-background-secondary";
    content = <DemoSplash />;
  } else if (showingFreeTrialSplash) {
    containerClassName = "bg-background-secondary";
    content = <TrialSplash setShowNewProposalFlow={setShowNewProposalFlow} />;
  } else if (paginatedData.error) {
    content = <ErrorView error={paginatedData.error} />;
  } else if (proposals) {
    content = (
      <ProposalManagerContent
        proposals={proposals}
        paginatedData={paginatedData}
        setShowNewProposalFlow={setShowNewProposalFlow}
      />
    );
  } else {
    content = null;
  }

  return (
    <NavigationView
      selected="proposals"
      containerClassName={containerClassName}
      scrollable={false}
    >
      {content}
      <StartNewProposalOverlay
        open={showNewProposalFlow}
        onClose={() => setShowNewProposalFlow(false)}
      />
    </NavigationView>
  );
};

interface ProposalManagerContentProps {
  proposals: Proposal[] | null;
  paginatedData: PaginatedData;
  setShowNewProposalFlow: (show: boolean) => void;
}

const ProposalManagerContent: FC<ProposalManagerContentProps> = ({
  proposals,
  paginatedData,
  setShowNewProposalFlow,
}) => {
  const navigate = useNavigate();
  const handleSelectRow = (id: string) => navigate(`/proposal/${id}`);
  const location = useLocation();

  const handleStartNewProposal = async () => {
    setShowNewProposalFlow(true);
  };
  const selectedTab = location.pathname.endsWith("/past/") ? "past" : "active";

  return (
    <Rows className="grow">
      <Columns className="flex mb-md shrink-0 items-center">
        <h1 className="text-2xl font-semibold w-full mb-md">Proposals</h1>
        <Spacer />
        <Button
          text="Start new proposal"
          variant="solid"
          className="shrink-0"
          icon="plus"
          onClick={handleStartNewProposal}
        />
      </Columns>
      <Columns className="border-b -m-thin mb-sm shrink-0">
        <Button
          variant="navigation"
          text="Active"
          disabled={selectedTab === "active"}
          onClick={() => {
            navigate("/proposals/");
          }}
        />
        <Button
          variant="navigation"
          text="Past"
          disabled={selectedTab === "past"}
          onClick={() => {
            navigate("/proposals/past/");
          }}
        />
      </Columns>
      {selectedTab === "active" ? (
        <ActiveProposals
          proposals={proposals}
          paginatedData={paginatedData}
          handleSelectRow={handleSelectRow}
        />
      ) : (
        <PastProposalList handleSelectRow={handleSelectRow} />
      )}
    </Rows>
  );
};

interface ActiveProposalsProps {
  proposals: Proposal[] | null;
  paginatedData: PaginatedData;
  handleSelectRow: (id: string) => void;
}

const ActiveProposals: FC<ActiveProposalsProps> = ({
  proposals,
  paginatedData,
  handleSelectRow,
}) => {
  return (
    <PaginatedTableView
      className="grow"
      results={proposals}
      searchable={true}
      paginatedData={paginatedData}
      columns={[
        { name: "Title" },
        { name: "Due Date", size: 190, center: true },
      ]}
      onSelect={(proposal) => handleSelectRow(proposal.id)}
      rowHeight={84}
      rowSeparators={true}
      renderRow={(proposal, Cell, Row) => (
        <Row key={proposal.id}>
          <Cell>{proposal.title}</Cell>
          <Cell center>{proposal.dueDate}</Cell>
        </Row>
      )}
    />
  );
};

interface PastProposalListProps {
  handleSelectRow: (id: string) => void;
}

const PastProposalList: FC<PastProposalListProps> = ({ handleSelectRow }) => {
  const apiClient = useApiClient();
  const [pastProposals, , paginatedData] = usePaginatedData({
    endpoint: apiClient.rfp.rfpProposalInactiveList,
    map: proposalFromAPI,
  });

  return (
    <PaginatedTableView
      className="grow"
      results={pastProposals}
      searchable={true}
      paginatedData={paginatedData}
      columns={[
        { name: "Title" },
        { name: "Due Date", size: 190, center: true },
      ]}
      onSelect={(proposal) => handleSelectRow(proposal.id)}
      rowHeight={84}
      rowSeparators={true}
      renderRow={(proposal, Cell, Row) => (
        <Row key={proposal.id}>
          <Cell>{proposal.title}</Cell>
          <Cell center>{proposal.dueDate}</Cell>
        </Row>
      )}
    />
  );
};

interface TrialSplashProps {
  setShowNewProposalFlow: (show: boolean) => void;
}

const TrialSplash: FC<TrialSplashProps> = ({ setShowNewProposalFlow }) => {
  return (
    <MessageView variant="above" icon="gift" title="Get a free proposal draft">
      <Grid className="grid-cols-[repeat(2,auto)] mx-auto gap-lg my-md max-w-[400px] items-center">
        <Icon
          name="file-arrow-up"
          size="large"
          variant="solid"
          className="mx-auto"
        />
        <p>Upload an RFP you want to bid for</p>
        <Icon
          name="brain-circuit"
          size="large"
          variant="solid"
          className="mx-auto"
        />
        <p>
          Upload some past proposals to
          <br />
          personalize it to your business
        </p>
        <Icon
          name="island-tropical"
          size="large"
          variant="solid"
          className="mx-auto"
        />
        <p>
          Sit back and relax! We'll email you
          <br />
          when your proposal is ready
        </p>
      </Grid>
      <Button
        text="Start Now"
        variant="solid"
        onClick={() => setShowNewProposalFlow(true)}
      />
      <p className="text-secondary text-sm text-center">
        Your data is safe with us
        <br />
        <a
          href="https://odo.do/policy/privacy"
          className="underline text-xs"
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
      </p>
    </MessageView>
  );
};

const DemoSplash = () => {
  return (
    <MessageView
      variant="above"
      icon="person-chalkboard"
      title="Book Demo"
      className="max-w-[500px] text-center gap-lg"
    >
      <p>Thank you so much for your interest!</p>
      <p>We’d love to walk you through the product.</p>
      <Button
        text="Book Demo"
        variant="solid"
        onClick={() =>
          (window.location.href =
            "https://www.odo.do/book-demo/?source=app-proposal-list")
        }
      />
    </MessageView>
  );
};

export default ProposalsRoute;
