import { cn, mergeRefs } from "lib/utils";
import { SplitPaneConfig } from "./SplitPaneLayoutManager";
import React from "react";

export interface SplitPaneProps extends SplitPaneConfig {
  uniqueId: string;
  children?: React.ReactNode;
  className?: string;
  paneRef?: React.MutableRefObject<HTMLDivElement | null>;
  hidden?: boolean;
}

export const SplitPane = React.forwardRef<HTMLDivElement, SplitPaneProps>(
  (
    {
      children,
      paneRef,
      className,
      uniqueId,
      defaultSize,
      minSize,
      maxSize,
      hidden,
      ...rest
    },
    ref
  ) => {
    return (
      <div
        className={cn("shrink-0 grow-0", className, hidden && "hidden")}
        ref={mergeRefs(paneRef as any, ref)}
        {...rest}
      >
        {children}
      </div>
    );
  }
);
