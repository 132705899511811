import { TComboboxItem } from "@udecode/plate-combobox/dist/types";
import { SlashCommandItemData } from "lib/plate/plugins/slash-commands/types.js";
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";
import {
  ELEMENT_H1,
  ELEMENT_H2,
  ELEMENT_H3,
  ELEMENT_H4,
  ELEMENT_H5,
  ELEMENT_H6,
} from "@udecode/plate-heading";
import {
  blurEditor,
  PlateEditor,
  toggleNodeType,
  Value,
} from "@udecode/plate-common";
import { ELEMENT_BLOCKQUOTE } from "@udecode/plate-block-quote";
import { IconName } from "components/common/Icon.js";
import { ListStyleType, toggleIndentList } from "@udecode/plate-indent-list";

const turnIntoCommand = (props: {
  text: string;
  icon: IconName;
  key?: string;
  type: string;
}): TComboboxItem<SlashCommandItemData> => {
  return {
    key: props.key || props.icon,
    text: props.text,
    data: {
      icon: props.icon,
      onSelect: (editor) => {
        toggleNodeType(editor, { activeType: props.type });
      },
    },
  };
};

const customCommand = (props: {
  text: string;
  icon: IconName;
  key?: string;
  onSelect: (editor: PlateEditor<Value>) => void;
}): TComboboxItem<SlashCommandItemData> => {
  return {
    key: props.key || props.icon,
    text: props.text,
    data: {
      icon: props.icon,
      onSelect: props.onSelect,
    },
  };
};

export type SlashCommandMode = "root" | "table";

const slashCommandItems: (
  setOpen: React.Dispatch<React.SetStateAction<boolean>>,
  uploadNewImage: () => void,
  mode: SlashCommandMode
) => TComboboxItem<SlashCommandItemData>[] = (setOpen, upoadNewImage, mode) => {
  let items: TComboboxItem<SlashCommandItemData>[] = [];
  items.push(
    turnIntoCommand({
      text: "Paragraph",
      icon: "paragraph",
      type: ELEMENT_PARAGRAPH,
    })
  );
  if (mode === "root") {
    items.push(
      turnIntoCommand({
        text: "Heading 1",
        icon: "h1",
        type: ELEMENT_H1,
      }),
      turnIntoCommand({
        text: "Heading 2",
        icon: "h2",
        type: ELEMENT_H2,
      }),
      turnIntoCommand({
        text: "Heading 3",
        icon: "h3",
        type: ELEMENT_H3,
      }),
      turnIntoCommand({
        text: "Heading 4",
        icon: "h4",
        type: ELEMENT_H4,
      }),
      turnIntoCommand({
        text: "Heading 5",
        icon: "h5",
        type: ELEMENT_H5,
      }),
      turnIntoCommand({
        text: "Heading 6",
        icon: "h6",
        type: ELEMENT_H6,
      }),
      turnIntoCommand({
        text: "Block Quote",
        icon: "block-quote",
        type: ELEMENT_BLOCKQUOTE,
      }),
      customCommand({
        key: "table",
        text: "Table",
        icon: "table",
        onSelect: (editor) => {
          setOpen(true);
          blurEditor(editor);
        },
      }),
      customCommand({
        key: "image",
        text: "Image",
        icon: "image",
        onSelect: (editor) => {
          upoadNewImage();
        },
      })
    );
  }
  items.push(
    customCommand({
      text: "Bulleted List",
      icon: "list-ul",
      onSelect: (editor) => {
        toggleIndentList(editor, { listStyleType: ListStyleType.Disc });
      },
    }),
    customCommand({
      text: "Numbered List",
      icon: "list-ol",
      onSelect: (editor) => {
        toggleIndentList(editor, { listStyleType: ListStyleType.Decimal });
      },
    })
    // customCommand({
    //   text: "Checklist",
    //   icon: "square-check",
    //   onSelect: (editor) => {
    //     toggleIndentList(editor, { listStyleType: "unchecked" });
    //   },
    // })
  );
  // if (mode === "root") {
  //   items.push(
  //     customCommand({
  //       key: "code-block",
  //       text: "Code Block",
  //       icon: "rectangle-code",
  //       onSelect: (editor) => {
  //         insertEmptyCodeBlock(editor, {
  //           defaultType: ELEMENT_DEFAULT,
  //           insertNodesOptions: { select: true },
  //         });
  //       },
  //     })
  //   );
  // }
  return items;
};

export default slashCommandItems;
