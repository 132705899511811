import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  createBasicMarksPlugin,
  createPlugins,
  Plate,
  PlateContent,
  PlateLeaf,
  useEditorRef,
  withProps,
} from "@udecode/plate";
import { createBasicElementsPlugin } from "@udecode/plate-basic-elements";
import { ELEMENT_PARAGRAPH } from "@udecode/plate-paragraph";
import { focusEditor } from "@udecode/plate-common";
import {
  MARK_BOLD,
  MARK_CODE,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_SUBSCRIPT,
  MARK_SUPERSCRIPT,
  MARK_UNDERLINE,
} from "@udecode/plate-basic-marks";
import { MARK_COMMENT, MARK_SUGGESTION, odoUser } from "odo";
import { CommentLeaf } from "./CommentLeaf";
import { ELEMENT_MENTION } from "lib/plate/plugins/mentions/types";
import { MentionElement } from "lib/plate/plugins/mentions/components/MentionElement";
import createMentionsPlugin from "lib/plate/plugins/mentions";
import { mentionsComboboxWithId } from "lib/plate/plugins/mentions/components/MentionsComboBox";
import { createEmojiPlugin } from "@udecode/plate-emoji";
import { editorIdForCombobox } from "lib/plate/plugins/combobox/onChangeCombobox";
import { createOdoComboboxPlugin } from "lib/plate/plugins";
import { CodeLeaf } from "components/EditorView/Leafs/CodeLeaf";
import { emojiComboboxWithId } from "components/EditorView/Menus/EmojiCombobox";
import { ParagraphElement } from "components/EditorView/Elements/ParagraphElement";
import SuggestionLeaf from "components/suggestions/SuggestionLeaf";

interface CommentEditorViewProps {
  id: string;
  value: string | any[];
  placeholder?: string;
  readonly?: boolean;
  onChange?: (value: any[]) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLDivElement>) => void;
}

const commentEditorComponents: any = {
  [ELEMENT_MENTION]: MentionElement,
  [MARK_BOLD]: withProps(PlateLeaf, { as: "strong" }),
  [MARK_CODE]: CodeLeaf,
  [MARK_ITALIC]: withProps(PlateLeaf, { as: "em" }),
  [MARK_STRIKETHROUGH]: withProps(PlateLeaf, { as: "s" }),
  [MARK_SUBSCRIPT]: withProps(PlateLeaf, { as: "sub" }),
  [MARK_SUPERSCRIPT]: withProps(PlateLeaf, { as: "sup" }),
  [MARK_UNDERLINE]: withProps(PlateLeaf, { as: "u" }),
  [MARK_COMMENT]: CommentLeaf,
  [MARK_SUGGESTION]: SuggestionLeaf,
};

const commentEditorPlugins = [
  createBasicElementsPlugin(),
  createBasicMarksPlugin(),
  createOdoComboboxPlugin(),
];

export type CommentEditorViewRef = {
  reset: () => void;
  focus: () => void;
  focusEnd: () => void;
  tagOdo: () => void;
};

interface CommentContentProps {
  id: string;
  placeholder: string;
  onFocus: (() => void) | undefined;
  onBlur: (() => void) | undefined;
  onKeyDown: ((event: React.KeyboardEvent<HTMLDivElement>) => void) | undefined;
}

const CommentContent = forwardRef<CommentEditorViewRef, CommentContentProps>(
  ({ id, placeholder, onFocus, onBlur, onKeyDown }, ref) => {
    const editor = useEditorRef();

    useImperativeHandle(ref, () => ({
      reset: () => {
        editor.reset();
      },
      focus: () => {
        focusEditor(editor);
      },
      focusEnd: () => {
        const path = [
          editor.children.length - 1,
          editor.children[editor.children.length - 1].children.length - 1,
        ];
        const end = editor.point(path, { edge: "end" });
        focusEditor(editor, end);
      },
      tagOdo: () => {
        editor.insertNodes([
          {
            // @ts-ignore
            type: ELEMENT_MENTION,
            user: odoUser,
            children: [{ text: "" }],
          },
          {
            text: " ",
          },
        ]);
      },
    }));

    return (
      <PlateContent
        id={id}
        // placeholder={placeholder}
        className="focus:outline-none"
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
      />
    );
  }
);

const CommentEditorView = forwardRef<
  CommentEditorViewRef,
  CommentEditorViewProps
>(
  (
    { id, placeholder, value, readonly, onChange, onFocus, onBlur, onKeyDown },
    ref
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    let initialValue: any[];
    if (typeof value === "string") {
      initialValue = value.split("\n").map((line: string) => {
        return {
          type: "p",
          children: [{ text: line }],
        };
      });
    } else {
      initialValue = value;
    }

    const [currentValue, setCurrentValue] = useState<any[]>(initialValue);
    const [plugins, setPlugins] = useState<any[]>([]);
    const components = useMemo(
      () => ({
        ...commentEditorComponents,
        [ELEMENT_PARAGRAPH]: ParagraphElement({
          customClassName: "py-xs",
          customPlaceholder: placeholder,
          hideOnBlur: false,
          hidePlaceholderWhenNotEmpty: true,
        }),
      }),
      [placeholder]
    );

    useEffect(() => {
      let list = [...commentEditorPlugins];
      const mentionId = "mention-" + id;
      list = list.concat([
        createMentionsPlugin({
          renderAfterEditable: mentionsComboboxWithId(mentionId),
          then: (editor, plugin) => {
            editorIdForCombobox[mentionId] = editor.id;
            plugin.options.id = mentionId;
          },
        }),
      ]);
      if (!readonly) {
        const emojiId = "emoji-" + id;
        list = list.concat([
          createEmojiPlugin({
            renderAfterEditable: emojiComboboxWithId(emojiId),
            then: (editor, plugin) => {
              editorIdForCombobox[emojiId] = editor.id;
              plugin.options.id = emojiId;
            },
          }),
        ]);
      }
      setPlugins(createPlugins(list as any, { components }));
    }, [components, id, readonly]);

    if (plugins.length === 0) {
      return null;
    }

    const plate = (
      <Plate
        id={id}
        readOnly={readonly ?? false}
        plugins={plugins}
        initialValue={initialValue}
        onChange={(newValue) => {
          if (JSON.stringify(newValue) === JSON.stringify(currentValue)) {
            return;
          }
          setCurrentValue(newValue);
          onChange?.(newValue);
        }}
      >
        <CommentContent
          ref={ref}
          id={id}
          placeholder={placeholder ?? ""}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
        />
      </Plate>
    );

    if (readonly) {
      return plate;
    } else {
      return (
        <div
          ref={containerRef}
          className="border relative rounded-sm px-2m py-sm"
        >
          {plate}
        </div>
      );
    }
  }
);

export default CommentEditorView;
