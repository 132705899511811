import { AxiosResponse } from "axios";
import AsyncLoadedDiv from "components/common/containers/AsyncLoadedDiv";
import Rows from "components/common/containers/Rows";
import Scrollable from "components/common/containers/Scrollable";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import { getLibraryItemInfo } from "components/content-library/ContentLibraryListItem";
import { Category, LibraryItem } from "hooks/useContentLibrary";
import useFetchedData from "hooks/useFetchedData";
import { useApiClient } from "providers/ApiClientProvider";
import { useProposalData } from "providers/ProposalDetailsProvider";
import { FC } from "react";
import { message_from_exception } from "utils";

interface UserChoicesPanelProps {}

const UserChoicesPanel: FC<UserChoicesPanelProps> = () => {
  const { details } = useProposalData();
  const apiClient = useApiClient();

  return (
    <Scrollable>
      <Rows className="grow p-md gap-sm">
        <LabeledFormInput label="Strategy">
          {details.instructions || "None"}
        </LabeledFormInput>
        <LibraryItemDetails
          label="Subcontractors"
          proposalId={details.id}
          category="subcontractors"
          endpoint={apiClient.rfp.rfpContentLibrarySubcontractorList}
        />
        <LibraryItemDetails
          label="Staffing"
          proposalId={details.id}
          category="people"
          endpoint={apiClient.rfp.rfpContentLibraryPersonList}
        />
        <LibraryItemDetails
          label="References"
          proposalId={details.id}
          category="projects"
          endpoint={apiClient.rfp.rfpContentLibraryProjectList}
        />
        <LibraryItemDetails
          label="Past Proposal"
          proposalId={details.id}
          category="past-proposals"
          endpoint={(options) =>
            apiClient.rfp.rfpProposalPastList(details.id, options)
          }
        />
      </Rows>
    </Scrollable>
  );
};

interface LibraryItemDetailsProps {
  label: string;
  proposalId: string;
  category: Category;
  endpoint: (options: any) => Promise<AxiosResponse<any>>;
}

const LibraryItemDetails: FC<LibraryItemDetailsProps> = ({
  label,
  endpoint,
  proposalId,
  category,
}) => {
  const [items, , { error }] = useFetchedData(async () => {
    const response = await endpoint({
      // @ts-ignore
      proposal_id: proposalId,
      associated_only: true,
    });
    return response.data.results;
  }, [proposalId]);
  return (
    <LabeledFormInput label={label}>
      <AsyncLoadedDiv
        value={items}
        error={error ? message_from_exception(error) : undefined}
        whileLoaded={(items) => (
          <ul className="list-disc">
            {items.map((item: LibraryItem) => (
              <li className="ml-lg" key={item.id}>
                {getLibraryItemInfo(item, category).title}
              </li>
            ))}
            {items.length === 0 && <p>None</p>}
          </ul>
        )}
      />
    </LabeledFormInput>
  );
};

export default UserChoicesPanel;
