import { isOdoElement, isOdoLink, isOdoMention, isOdoText, } from "./types.js";
export class MarkdownEncoder {
    constructor(elements) {
        this.elements = elements;
    }
    encode() {
        return this.encodeElementList(this.elements, undefined, 0).text;
    }
    encodeElementList(elements, elementMeta, depth) {
        let output = "";
        let previousChild = null;
        let currentElementMeta = elementMeta ? Object.assign({}, elementMeta) : undefined;
        if (!Array.isArray(elements)) {
            throw new Error("Elements must be an array");
        }
        for (const child of elements) {
            if (isOdoElement(child)) {
                const childOutput = this.encodeElement(child, previousChild, depth, currentElementMeta);
                if (!childOutput) {
                    continue;
                }
                currentElementMeta = childOutput.newMeta;
                output += childOutput.text;
                previousChild = child;
            }
            else if (isOdoText(child)) {
                const childOutput = this.encodeLeaf(child, {});
                output += childOutput[0];
            }
            else {
                console.log("Invalid element", child);
                throw new Error("Invalid types");
            }
        }
        return { text: output, newMeta: currentElementMeta };
    }
    isWholeElementDeleted(element) {
        if (isOdoText(element)) {
            return element.suggestion === "delete";
        }
        if (isOdoElement(element)) {
            for (const child of element.children) {
                if (!this.isWholeElementDeleted(child)) {
                    return false;
                }
            }
        }
        return true;
    }
    encodeElement(element, previousElement, depth = -1, meta) {
        if (this.isWholeElementDeleted(element)) {
            return null;
        }
        let encoder = MarkdownEncoder.elementEncoder[element.type];
        if (!encoder) {
            console.warn("Unknown element type", element);
            encoder = MarkdownEncoder.elementEncoder.p;
        }
        const { text, newMeta } = encoder.encode(this, element, meta, depth);
        if (previousElement) {
            if (this.includeExtraSpaceBetweenElements(element, previousElement, encoder.spaceLikeElements, depth)) {
                return { text: "\n\n" + text, newMeta };
            }
            else {
                return { text: "\n" + text, newMeta };
            }
        }
        return { text, newMeta };
    }
    encodeText(text, activeProps) {
        // Ignore suggested text
        if (text.suggestion === "delete" || text.text === undefined)
            return ["", activeProps];
        let newActiveProps = Object.assign({}, activeProps);
        var output = text.text;
        // Modify the output with the right prefixes while updating the activeProps
        for (const prop of Object.keys(markers)) {
            // If the current property is active in the text but not in the activeProps
            if (!activeProps[prop] && text[prop]) {
                output = markers[prop] + output;
                // @ts-ignore
                newActiveProps[prop] = true;
            }
        }
        // Modify the output with the right suffixes while updating the activeProps
        // Do this in reverse order so the first properties are the inner most
        for (const prop of Object.keys(markers).reverse()) {
            // If the current property is not active in the text but active in the activeProps
            if (activeProps[prop] && !text[prop]) {
                output = markers[prop] + output;
                // @ts-ignore
                newActiveProps[prop] = false;
            }
        }
        return [output, newActiveProps];
    }
    areEquivalentChildren(a, b) {
        if (a.type !== b.type)
            return false;
        if (a.type === "p") {
            return !!a.listStyleType && !!b.listStyleType;
        }
        return true;
    }
    includeExtraSpaceBetweenElements(current, previous, spaceLikeElements, depth) {
        return !this.areEquivalentChildren(current, previous) || spaceLikeElements;
    }
    encodeLeaf(leaf, activeProps) {
        if (isOdoLink(leaf)) {
            const { text } = MarkdownEncoder.elementEncoder.link.encode(this, leaf, undefined, 0);
            // Encode as if the link is a text element
            return this.encodeText(Object.assign({ text }, activeProps), activeProps);
        }
        else if (isOdoMention(leaf)) {
            const { text } = MarkdownEncoder.elementEncoder.mention.encode(this, leaf, undefined, 0);
            // Encode as if the link is a text element
            return this.encodeText(Object.assign({ text }, activeProps), activeProps);
        }
        return this.encodeText(leaf, activeProps);
    }
    encodeLeafs(children, depth, prefix) {
        if (children.length === 0)
            return "";
        let output = "";
        // Add Indentation
        if (depth > 0) {
            output += "    ".repeat(depth);
        }
        // Add prefix symbol
        if (prefix) {
            output += prefix + " ";
        }
        let activeProps = {};
        for (const child of children) {
            const [newText, newActiveTextProps] = this.encodeLeaf(child, activeProps);
            output += newText;
            activeProps = newActiveTextProps;
        }
        // Provide chance to close any active props
        const [newText] = this.encodeText({ text: "" }, activeProps);
        return output + newText;
    }
    /**
     * Encodes a list of leafs into a flattened string
     *
     * Tables, lists, and other structures are ignored and instead the text elements within them are joined by spaces
     */
    encodeFlattenedElement(element) {
        const raw = this.encodeElementList(element.children, { listIndexes: [] }, 0);
        if (!raw) {
            return "";
        }
        return raw.text.replace(/\n/g, "<br />");
    }
}
MarkdownEncoder.elementEncoder = {
    p: {
        encode: (enc, value, meta, depth) => {
            var _a, _b;
            if (!value.listStyleType) {
                // We are encoding a plain paragraph
                return {
                    text: enc.encodeLeafs(value.children, depth + ((_a = value.indent) !== null && _a !== void 0 ? _a : 1) - 1),
                };
            }
            // We are encoding a list item
            let newMeta = meta ? meta : { listIndexes: [0], previousDepth: 0 };
            let listDepth = ((_b = value.indent) !== null && _b !== void 0 ? _b : 1) - 1;
            while (newMeta.listIndexes.length - 1 < listDepth) {
                newMeta.listIndexes.push(0);
            }
            while (newMeta.listIndexes.length - 1 > listDepth) {
                newMeta.listIndexes.pop();
            }
            let prefix;
            let listIndex = newMeta.listIndexes[listDepth];
            switch (value.listStyleType) {
                case "decimal":
                    prefix = `${listIndex + 1}.`;
                    break;
                case "disc":
                    prefix = "-";
                    break;
                case "lower-alpha":
                    const charCodeOfA = "a".charCodeAt(0);
                    const loopedIndex = listIndex % 26;
                    const char = String.fromCharCode(charCodeOfA + loopedIndex);
                    prefix = `${char}.`;
                    break;
                case "lower-roman":
                    prefix = `${intToRoman(listIndex + 1).toLowerCase()}.`;
                    break;
                case "checked":
                    prefix = "[x]";
                    break;
                case "unchecked":
                    prefix = "[ ]";
                    break;
            }
            newMeta.listIndexes[listDepth] += 1;
            // We are encoding a list item
            const text = enc.encodeLeafs(value.children, listDepth, prefix);
            return {
                text,
                newMeta,
            };
        },
        spaceLikeElements: false,
    },
    h1: {
        encode: (enc, value, meta, depth) => {
            return { text: enc.encodeLeafs(value.children, depth, "#") };
        },
        spaceLikeElements: true,
    },
    h2: {
        encode: (enc, value, meta, depth) => {
            return { text: enc.encodeLeafs(value.children, depth, "##") };
        },
        spaceLikeElements: true,
    },
    h3: {
        encode: (enc, value, meta, depth) => {
            return { text: enc.encodeLeafs(value.children, depth, "###") };
        },
        spaceLikeElements: true,
    },
    h4: {
        encode: (enc, value, meta, depth) => {
            return { text: enc.encodeLeafs(value.children, depth, "####") };
        },
        spaceLikeElements: true,
    },
    h5: {
        encode: (enc, value, meta, depth) => {
            return { text: enc.encodeLeafs(value.children, depth, "#####") };
        },
        spaceLikeElements: true,
    },
    h6: {
        encode: (enc, value, meta, depth) => {
            return { text: enc.encodeLeafs(value.children, depth, "######") };
        },
        spaceLikeElements: true,
    },
    blockquote: {
        encode: (enc, value, meta, depth) => {
            return { text: enc.encodeLeafs(value.children, depth, ">") };
        },
        spaceLikeElements: false,
    },
    code_block: {
        encode: (enc, value, meta, depth) => {
            return {
                text: "```\n" +
                    enc.encodeElementList(value.children, meta, depth).text +
                    "\n```",
            };
        },
        spaceLikeElements: true,
    },
    code_line: {
        encode: (enc, value, meta, depth) => {
            return { text: enc.encodeLeafs(value.children, depth) };
        },
        spaceLikeElements: false,
    },
    link: {
        encode: (enc, value, meta, depth) => {
            return {
                text: `[${enc.encodeLeafs(value.children, depth)}](${value.url})`,
            };
        },
        spaceLikeElements: false,
    },
    table: {
        encode: (enc, value) => {
            let output = "";
            for (const row of value.children) {
                if (output.length > 0) {
                    output += "\n| ";
                }
                else {
                    output += "| ";
                }
                for (const [index, cell] of row.children.entries()) {
                    if (index !== 0) {
                        output += " | ";
                    }
                    output += enc.encodeFlattenedElement(cell);
                }
                output += " |";
            }
            return { text: output };
        },
        spaceLikeElements: true,
    },
    tr: {
        encode: () => {
            throw new Error("TR elements encoding handled in table");
        },
        spaceLikeElements: false,
    },
    td: {
        encode: () => {
            throw new Error("TD elements encoding handled in table");
        },
        spaceLikeElements: false,
    },
    refine: {
        encode: (enc, value, meta, depth) => {
            return { text: enc.encodeElementList(value.old, meta, depth).text };
        },
        spaceLikeElements: true,
    },
    img: {
        encode: () => {
            // Images are ignored for now
            // They will be restored by the refine logic
            return { text: "" };
        },
        spaceLikeElements: true,
    },
    deleted: {
        encode: () => {
            // Deleted elements are ignored
            return { text: "" };
        },
        spaceLikeElements: false,
    },
    mention: {
        encode: (enc, value) => {
            // Mentions are ignored for now
            // They will be restored by the refine logic
            return { text: "@" + value.user.displayName };
        },
        spaceLikeElements: true,
    },
    section_control: {
        encode: () => {
            // Section controls are ignored
            return { text: "" };
        },
        spaceLikeElements: false,
    },
};
const markers = {
    bold: "**",
    italic: "*",
    strikethrough: "~~",
    underline: "__",
    code: "`",
    suggestion: "",
};
/**
 * This function can convert numbers from 1 to 3999 into their Roman numeral representations.
 *
 * The number range is limited to this because Roman numerals traditionally don't have a standard
 * representation for numbers 4000 and above without using overlines or more advanced techniques.
 */
function intToRoman(num) {
    if (num <= 0 || num > 3999) {
        return "Invalid Number";
    }
    const val = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1];
    const syb = [
        "M",
        "CM",
        "D",
        "CD",
        "C",
        "XC",
        "L",
        "XL",
        "X",
        "IX",
        "V",
        "IV",
        "I",
    ];
    let romanNum = "";
    for (let i = 0; i < val.length; i++) {
        while (num >= val[i]) {
            num -= val[i];
            romanNum += syb[i];
        }
    }
    return romanNum;
}
