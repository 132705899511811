import { PaginatedResponse } from "api/CoreApi";
import { AxiosResponse } from "axios";
import { useApiClient } from "providers/ApiClientProvider";
import {
  DependencyList,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { message_from_exception } from "utils";

export type PaginationMode = "infinite" | "pages";

interface SearchFilter {
  key: string;
  name: string;
  default: boolean;
}

export interface UsePaginatedDataOptions<Local, Remote, ExtraData = void> {
  endpoint: (options: any) => Promise<AxiosResponse<PaginatedResponse<Remote>>>;
  map: (remote: Remote) => Local;
  deps?: DependencyList;
  getExtraData?: (
    response: AxiosResponse<PaginatedResponse<Remote>>
  ) => ExtraData | null;
  filters?: SearchFilter[];
  mode?: PaginationMode;
}

interface PaginatedFilter {
  value: boolean;
  setValue: (value: boolean) => void;
}

export const EMPTY_PAGINATED_DATA: PaginatedData = {
  loadingPrevious: false,
  loadingNext: false,
  status: "loaded",
  error: null,
  noResultsFound: true,
  loadPrevious: null,
  loadNext: null,
  refresh: () => {},
  search: "",
  setSearch: () => {},
  filters: null,
  mode: "infinite",
};

export interface BasePaginatedData {
  status: "waiting" | "loading" | "loaded" | "error";
  error: unknown | null;
  refresh: () => void;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  filters: Record<string, PaginatedFilter> | null;
  loadPrevious: (() => void) | null | string;
  loadNext: (() => void) | null | string;
  mode: PaginationMode;
  // True only if there are no results and the search is empty
  noResultsFound: boolean;
}

export interface InfinitePaginatedData extends BasePaginatedData {
  loadingPrevious: boolean;
  loadingNext: boolean;
  mode: "infinite";
}

export interface PagesPaginatedData extends BasePaginatedData {
  currentPage: number;
  pageCount: number;
  mode: "pages";
}

export type PaginatedData = InfinitePaginatedData | PagesPaginatedData;

export const isInfinitePaginatedData = (
  data: PaginatedData
): data is InfinitePaginatedData => {
  return data.mode === "infinite";
};

export const isPagesPaginatedData = (
  data: PaginatedData
): data is PagesPaginatedData => {
  return data.mode === "pages";
};

export type PaginatedDataReturnType<Local> = [
  Local[] | null,
  React.Dispatch<React.SetStateAction<Local[] | null>>,
  PaginatedData
];

const usePaginatedData = <Local, Remote>(
  options: UsePaginatedDataOptions<Local, Remote>
) => {
  const apiClient = useApiClient();
  const mode = options.mode ?? "infinite";
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [results, doSetResults] = useState<Local[] | null>(null);
  const [loadingPrevious, setLoadingPrevious] = useState(false);
  const [loadingNext, setLoadingNext] = useState(false);
  const [nextError, setNextError] = useState<string | null>(null);
  const [previousError, setPreviousError] = useState<string | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(() => {
    const params = new URLSearchParams(window.location.search);
    return parseInt(params.get("page") ?? "1");
  });
  const [pageCount, setPageCount] = useState<number | null>(null);
  const [status, setStatus] = useState<
    "waiting" | "loading" | "loaded" | "error"
  >("waiting");
  const [error, setError] = useState<unknown | null>(null);
  const [previousCursor, setPreviousCursor] = useState<string | null>(null);
  const [nextCursor, setNextCursor] = useState<string | null>(null);
  const [search, doSetSearch] = useState<string>("");
  const [filterState, setFilterState] = useState<Record<string, boolean>>(
    options.filters?.reduce((acc, filter) => {
      acc[filter.key] = filter.default;
      return acc;
    }, {} as Record<string, boolean>) ?? {}
  );
  const [filters, setFilters] = useState<Record<
    string,
    PaginatedFilter
  > | null>(null);

  const setSearch = useCallback((search: SetStateAction<string>) => {
    doSetSearch(search);
    setCurrentPage(1);
  }, []);

  useEffect(() => {
    if (mode !== "pages") return;
    const url = new URL(window.location.href);
    url.searchParams.set("page", currentPage.toString());
    window.history.replaceState({}, "", url.toString());
  }, [currentPage]);

  const loadPrevious = useCallback(async () => {
    if (loadingPrevious || !previousCursor) return;
    if (mode === "pages") {
      // Reset the results when loading the next page
      // so that it shows as loading
      setResults(null);
    }
    setLoadingPrevious(true);
    try {
      const response = await apiClient.fetchFromCursor<Remote>(previousCursor);
      const newResults = response.data.results.map(options.map);
      const updatedResults = [...newResults, ...(results ?? [])];
      setResults(updatedResults);
      setPreviousCursor(response.data.previous ?? null);
      setNextCursor(response.data.next ?? null);
      setCurrentPage(response.data.page ?? 1);
      setPageCount(response.data.page_count ?? null);
    } catch (error) {
      setPreviousError(message_from_exception(error));
      setPreviousCursor(null);
    } finally {
      setLoadingPrevious(false);
    }
    // Deliberately not including in the dependency array:
    // - options.map
  }, [apiClient, loadingPrevious, previousCursor, results]);

  const loadNext = useCallback(async () => {
    if (loadingNext || !nextCursor) return;
    if (mode === "pages") {
      // Reset the results when loading the next page
      // so that it shows as loading
      setResults(null);
    }

    setLoadingNext(true);
    try {
      const response = await apiClient.fetchFromCursor<Remote>(nextCursor);
      const newResults = response.data.results.map(options.map);
      switch (mode) {
        case "infinite":
          const updatedResults = [...(results ?? []), ...newResults];
          setResults(updatedResults);
          break;
        case "pages":
          setResults(newResults);
          break;
      }
      setNextCursor(response.data.next ?? null);
      if (mode === "pages") {
        setPreviousCursor(response.data.previous ?? null);
      } else {
        // We don't clear earlier results, we just build them up
        setPreviousCursor(null);
      }
      setCurrentPage(response.data.page ?? 1);
      setPageCount(response.data.page_count ?? null);
    } catch (error) {
      setNextError(message_from_exception(error));
      setNextCursor(null);
    } finally {
      setLoadingNext(false);
    }
    // Deliberately not including in the dependency array:
    // - options.map
  }, [apiClient, loadingNext, nextCursor, results]);

  const refresh = useCallback(async () => {
    setStatus("loading");
    try {
      let requestOptions: any = {};
      if (mode === "pages") {
        requestOptions.page = currentPage;
      }
      if (search) {
        requestOptions["search"] = search;
      }
      for (const [key, value] of Object.entries(filterState)) {
        if (value) {
          requestOptions[key] = value;
        }
      }
      const response = await options.endpoint(requestOptions);
      const results = response.data.results.map(options.map);
      setResults(results);
      setNextCursor(response.data.next ?? null);
      setPreviousCursor(response.data.previous ?? null);
      setCurrentPage(response.data.page ?? 1);
      setPageCount(response.data.page_count ?? null);
      setStatus("loaded");
      setNoResultsFound(results.length === 0 && search === "");
    } catch (error) {
      setResults(null);
      setError(error);
      setStatus("error");
    }
    // Deliberately not including in the dependency array:
    // - options.map
  }, [filterState, search, options.endpoint, currentPage]);

  const setResults = useCallback(
    (results: Local[] | null) => {
      doSetResults(results);
      setNoResultsFound((prev) => {
        if (!results) return prev;
        // If results are manually added and we're currently saying
        // there are no results, we need to update that
        if (results.length > 0) return false;
        return prev;
      });
    },
    [search]
  );

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, (options.deps ?? []).concat([search, filterState]));

  useEffect(() => {
    const filters: Record<string, PaginatedFilter> | null =
      options.filters?.reduce((acc, filter) => {
        acc[filter.name] = {
          value: filterState[filter.key],
          setValue: (value: boolean) => {
            setFilterState((state) => ({ ...state, [filter.key]: value }));
          },
        };
        return acc;
      }, {} as Record<string, PaginatedFilter>) ?? null;

    setFilters(filters);
    // Deliberately not including in the dependency array:
    // - options.filters
  }, [filterState]);

  const paginatedData = useMemo(() => {
    let baseData: BasePaginatedData = {
      status,
      error,
      refresh,
      search,
      setSearch,
      filters,
      mode,
      noResultsFound,
      loadPrevious: previousError
        ? previousError
        : previousCursor
        ? loadPrevious
        : null,
      loadNext: nextError ? nextError : nextCursor ? loadNext : null,
    };
    switch (mode) {
      case "infinite":
        return {
          ...baseData,
          loadingPrevious,
          loadingNext,
        };
      case "pages":
        return {
          ...baseData,
          currentPage,
          pageCount,
        };
    }
  }, [
    status,
    error,
    refresh,
    search,
    filters,
    mode,
    loadingPrevious,
    loadingNext,
    previousError,
    previousCursor,
    loadPrevious,
    noResultsFound,
    nextError,
    nextCursor,
    loadNext,
    currentPage,
    pageCount,
  ]);

  return [results, setResults, paginatedData] as PaginatedDataReturnType<Local>;
};

export default usePaginatedData;
