import Button from "components/common/Button";
import Rows from "components/common/containers/Rows";
import Toggle from "components/common/forms/Toggle";
import { useTextAreaConfirm } from "providers/AlertProvider";
import { FC, useEffect } from "react";

export interface Category {
  name: string;
  placeholderDescription: string;
  placeholderServices: string;
  placeholderCustomers: string;
}

// These categories are always available to select
// (we will also show the current orgs active categories)
const FIXED_CATEGORIES: Record<string, Category> = {
  "web-development": {
    name: "Web Development",
    placeholderDescription:
      "Acme Co. is an interactive design and development agency based in New York City with over 12 years of experience delivering high-quality digital solutions for government clients. We are a certified women-owned small business (WOSB).",
    placeholderServices:
      "Acme has extensive experience in designing and developing interactive websites, with a deep understanding of web technologies and human interaction. The company's approach focuses on visual consistency, responsive design, and user experience, with a development team skilled primarily in Drupal and in other technologies like Wordpress, HTML/CSS, JavaScript/TypeScript, React, and Node.js.",
    placeholderCustomers:
      "Acme has successfully completed projects for clients in higher education in New York and Texas, such as Baruch College and Texas A&M University.",
  },
  marketing: {
    name: "Marketing / Advertising",
    placeholderDescription:
      "Acme Co. is a full-service advertising agency in Southern California that specializes in digital creative work. Established in 2020, Acme is certified as a Small Business Enterprise (SBE).",
    placeholderServices:
      "The agency's unique capabilities include digital strategy, content creation, social media management, and data-driven analytics.",
    placeholderCustomers:
      "Acme has extensive experience in tourism and healthcare sectors, such as the California Office of Tourism.",
  },
  "economic-development": {
    name: "Economic Consulting",
    placeholderDescription:
      "Acme Co. is an economic development consultancy that focuses on creating strategic plans and economic development services for U.S. cities and municipalities.",
    placeholderServices:
      "Acme has advised local government, academia, non profits, and cities on topics such as downtown revitalization, feasibility studies, and strategic planning, and has won over $50 million in grant funding for client's projects.",
    placeholderCustomers:
      "Acme served over 30 clients in 15 states. Most of the clients are cities, counties, municipalities in the U.S.",
  },
  transportation: {
    name: "Transportation",
    placeholderDescription:
      "Acme Co. is a transportation consulting firm that specializes in providing planning, design, and construction management services for transportation projects.",
    placeholderServices:
      "Acme specializes in transportation planning, traffic engineering, transit planning, and construction management.",
    placeholderCustomers:
      "Acme has completed over 100 projects in the past 10 years. Our clients include state DOTs, local municipalities, and private developers.",
  },
  auditing: {
    name: "Auditing",
    placeholderDescription:
      "Acme Co. is an auditing firm with 20+ years of experience. We provide auditing services to both private and public clients.",
    placeholderServices:
      "Acme specializes in providing financial audits, such as annual financial statement audits, internal control audits, and compliance audits.",
    placeholderCustomers:
      "Acme has served over 10 counties in the past 12 years.",
  },
  education: {
    name: "Education",
    placeholderDescription:
      "Acme Co. offers a comprehensive learning management system to help schools administer, manage, and deliver engaging lessons to K-12 students.",
    placeholderServices:
      "Acme's software provides course management, user management, progress tracking & reporting, communication, and certification features. In addition, Acme is integrated with the top content management systems so you can turn on the software on day 1.",
    placeholderCustomers: "Acme is used across 40 school districts in the U.S.",
  },
  it: {
    name: "Information Technology",
    placeholderDescription:
      "Acme Co. is a technology consulting firm that specializes in providing IT consulting services to both private and public clients.",
    placeholderServices:
      "Acme specializes in providing IT consulting services, such as software development, IT infrastructure consulting, and cybersecurity consulting.",
    placeholderCustomers:
      "Acme has served over 10 counties in the past 12 years.",
  },
  cybersecurity: {
    name: "Cyber Security",
    placeholderDescription:
      "Acme Co. is a cybersecurity consulting firm that specializes in providing cybersecurity consulting services to both private and public clients.",
    placeholderServices:
      "Acme specializes in providing cybersecurity consulting services, such as cybersecurity assessment, cybersecurity training, and cybersecurity consulting.",
    placeholderCustomers:
      "Acme has served over 10 counties in the past 12 years.",
  },
  infrastructure: {
    name: "Infrastructure",
    placeholderDescription:
      "Acme Co. is an infrastructure consulting firm that specializes in providing infrastructure consulting services to both private and public clients.",
    placeholderServices:
      "Acme specializes in providing infrastructure consulting services, such as infrastructure assessment, infrastructure training, and infrastructure consulting.",
    placeholderCustomers:
      "Acme has served over 10 counties in the past 12 years.",
  },
  healthcare: {
    name: "Healthcare",
    placeholderDescription:
      "Acme Co. is a healthcare consulting firm that specializes in providing healthcare consulting services to both private and public clients.",
    placeholderServices:
      "Acme specializes in providing healthcare consulting services, such as healthcare assessment, healthcare training, and healthcare consulting.",
    placeholderCustomers:
      "Acme has served over 10 counties in the past 12 years.",
  },
};

interface CategoryPickerProps {
  categories: string[];
  categoryDisplayNames: Record<string, string>;
  onChange: (categories: string[], sampleCategory: Category) => void;
}

const getPrimaryCategory = (categories: string[]) => {
  for (const category of categories) {
    if (category in FIXED_CATEGORIES) {
      return FIXED_CATEGORIES[category];
    }
  }
  return FIXED_CATEGORIES["web-development"];
};

export const CategoryPicker: FC<CategoryPickerProps> = ({
  categories,
  categoryDisplayNames,
  onChange,
}) => {
  const textAreaConfirm = useTextAreaConfirm();

  const toggleCategory = (category: string) => (on: boolean) => {
    const newCategories = new Set<string>(categories);
    if (newCategories.has(category)) {
      newCategories.delete(category);
    } else {
      newCategories.add(category);
    }
    const categoryList = Array.from(newCategories);
    onChange(categoryList, getPrimaryCategory(categoryList));
  };

  let allCategories: { id: string; name: string }[] = [];
  for (const [id, category] of Object.entries(FIXED_CATEGORIES)) {
    allCategories.push({ id, name: category.name });
  }
  for (const category of categories) {
    if (category in FIXED_CATEGORIES) {
      continue;
    }
    allCategories.push({
      id: category,
      name: categoryDisplayNames[category] ?? category,
    });
  }
  allCategories.sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    // Set the initial sample category
    onChange(categories, getPrimaryCategory(categories));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addOtherCategory = async () => {
    const result = await textAreaConfirm("Enter a new category", {
      buttons: [
        { id: "cancel", text: "Cancel" },
        { id: "add", text: "Add", variant: "solid" },
      ],
    });
    if (result.id === "add") {
      onChange([...categories, result.text], getPrimaryCategory(categories));
    }
  };

  return (
    <Rows className="items-start">
      {allCategories.map((category) => (
        <Toggle
          key={category.id}
          toggleType="checkbox"
          text={category.name}
          on={categories.includes(category.id)}
          onToggle={toggleCategory(category.id)}
        />
      ))}
      <Button
        onClick={addOtherCategory}
        text="Add Other Category"
        className="mt-sm text-primary"
      />
    </Rows>
  );
};
