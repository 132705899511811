import PastProposalList from "components/PastProposalList";
import NavigationView from "components/common/NavigationView";

const PastProposalsRoute = () => {
  return (
    <NavigationView selected="past-proposals">
      <PastProposalList />
    </NavigationView>
  );
};

export default PastProposalsRoute;
