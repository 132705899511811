import { isElement } from "@udecode/plate-common";
import { v4 } from "uuid";
import lodash from "lodash";
import { yjsProviderFromEditor } from "./editorUtils.js";
const { cloneDeep } = lodash;
const withHeadingIds = (editor) => {
    const { normalizeNode, getFragment, apply, insertFragment } = editor;
    editor.normalizeNode = ([node, path]) => {
        // Ensure every header has an id
        normalizeNode([node, path]);
        if (isElement(node)) {
            if (!node.id) {
                // @ts-ignore
                editor.setNodes({ id: v4() }, { at: path });
            }
        }
    };
    editor.insertFragment = (fragment) => {
        var _a;
        console.log("insertFragment", fragment);
        const doc = (_a = yjsProviderFromEditor(editor)) === null || _a === void 0 ? void 0 : _a.document;
        for (const element of fragment) {
            if ("id" in element) {
                // We need to create a new id for the fragment
                // and copy the section details to the new id
                const newId = v4();
                if (doc) {
                    const map = doc.getMap("sections");
                    const section = map.get(element.id);
                    if (section) {
                        map.set(newId, Object.assign({}, section));
                    }
                }
                element.id = newId;
            }
        }
        insertFragment(fragment);
    };
    editor.apply = editor.apply = (op) => {
        var _a;
        apply(op);
        switch (op.type) {
            case "split_node":
                const [element] = editor.node(op.path);
                if (!isElement(element)) {
                    // Only work with split elements
                    // The op is called for both the text and the element
                    return;
                }
                // Set a new id for the new node
                let newPath = [...op.path];
                if (newPath.length > 0) {
                    newPath[op.path.length - 1] += 1;
                }
                if (editor.hasPath(newPath)) {
                    const newId = v4();
                    const doc = (_a = yjsProviderFromEditor(editor)) === null || _a === void 0 ? void 0 : _a.document;
                    if (doc) {
                        // Copy the section details to the new id
                        const map = doc.getMap("sections");
                        const section = map.get(element.id);
                        if (section) {
                            map.set(newId, Object.assign({}, section));
                        }
                    }
                    // @ts-ignore
                    editor.setNodes({ id: newId }, { at: newPath });
                }
                break;
            case "insert_node":
            case "merge_node":
            case "move_node":
            case "remove_node":
            case "set_node":
            case "set_selection":
            case "insert_text":
            case "remove_text":
                // Do nothing
                break;
        }
    };
    // editor.getFragment = () => {
    //   const fragment = getFragment();
    //   const newFragement: TDescendant[] = [];
    //   fragment.forEach((node) => {
    //     if (isElement(node)) {
    //       const newNode = cloneDeep(node);
    //       delete newNode.id;
    //       delete newNode.requirements;
    //       delete newNode.blocks;
    //       newFragement.push(newNode);
    //       return;
    //     }
    //     newFragement.push(node);
    //   });
    //   return newFragement as any;
    // };
    return editor;
};
export default withHeadingIds;
