import { useEditorRef } from "@udecode/plate-common";
import Columns from "components/common/containers/Columns";
import Overlay from "components/common/containers/overlays/Overlay";
import ContentLibraryProjectDetails from "components/content-library/ContentLibraryDetails";
import ContentLibraryNavigation from "components/content-library/ContentLibraryNavigation";
import useContentLibrary, { LibraryItem } from "hooks/useContentLibrary";
import useContentLibraryActions from "hooks/useContentLibraryActions";
import { getSection } from "lib/plate/getPreviousSection";
import { useAlert } from "providers/AlertProvider";
import { useProposalData } from "providers/ProposalDetailsProvider";
import { useEditorDocData } from "providers/RequirementContentEditorProvider";
import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { toast } from "react-toastify";

const ContentLibraryPanel: FC = () => {
  const editor = useEditorRef();
  const { details } = useProposalData();
  const { activeSection, setDroppingSection, highlightRightPanel } =
    useEditorDocData();
  const {
    activeCategory,
    activeItems,
    activePaginatedData,
    selectedItem,
    setActiveCategory,
    setSelectedItemSaveState,
    setSelectedItem,
    addItem,
    updateItem,
    deleteItem,
  } = useContentLibrary(undefined, undefined, details.id);

  const { insertItem } = useContentLibraryActions(details.id);
  const activeSectionRef = useRef(activeSection);
  const alert = useAlert();

  useEffect(() => {
    activeSectionRef.current = activeSection;
  }, [activeSection]);

  const handleInsert = useCallback(
    async (item: LibraryItem) => {
      if (!activeSectionRef.current) {
        await alert("Please click a section first", {
          body: "This will be where the content is inserted.",
        });
        return;
      }
      const sectionNode = getSection(activeSectionRef.current.id, editor);
      if (!sectionNode) {
        toast.error("Failed to find section to insert into");
        return;
      }
      await insertItem(
        item.id,
        activeCategory,
        sectionNode,
        activeSectionRef.current
      );
    },
    [editor, insertItem, activeCategory, alert]
  );

  const handleInsertPreview = useCallback(
    (item: LibraryItem | null) => {
      if (!item) {
        setDroppingSection(null);
        return;
      }
      if (!activeSectionRef.current) {
        setDroppingSection(null);
        return;
      }

      setDroppingSection({
        id: activeSectionRef.current.id,
        name: activeSectionRef.current.name,
        location: "below",
      });
    },
    [setDroppingSection, activeSectionRef]
  );

  return useMemo(
    () => (
      <Columns className="w-full grow">
        <ContentLibraryNavigation
          items={activeItems}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          paginatedData={activePaginatedData}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
          addItem={addItem}
          onInsert={handleInsert}
          onInsertPreview={handleInsertPreview}
          highlight={highlightRightPanel}
        />
        {selectedItem && (
          <Overlay onClose={() => setSelectedItem(null)} className="w-full">
            <Columns>
              <ContentLibraryProjectDetails
                proposalId={details.id}
                activeCategory={activeCategory}
                selectedItem={selectedItem}
                setSelectedItemSaveState={setSelectedItemSaveState}
                updateItem={updateItem}
                deleteItem={deleteItem}
              />
            </Columns>
          </Overlay>
        )}
      </Columns>
    ),
    [
      activeItems,
      activeCategory,
      setActiveCategory,
      activePaginatedData,
      selectedItem,
      setSelectedItem,
      addItem,
      handleInsert,
      handleInsertPreview,
      highlightRightPanel,
      details.id,
      setSelectedItemSaveState,
      updateItem,
      deleteItem,
    ]
  );
};

export default ContentLibraryPanel;
