/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface ApplyCode {
  /**
   * Code
   * @minLength 1
   */
  code: string;
}

export interface ForgotPassword {
  /**
   * Email
   * @format email
   * @minLength 1
   */
  email: string;
}

export interface RecoverPasswordEmailUser {
  /**
   * Email
   * @format email
   * @minLength 1
   */
  email: string;
  /**
   * Token
   * @format uuid
   */
  token: string;
  /**
   * Password
   * @minLength 1
   */
  password: string;
}

export interface ReverifyEmailUser {
  /**
   * Email
   * @format email
   * @minLength 1
   */
  email: string;
}

export interface EmailTokenObtainPair {
  /**
   * Username
   * @minLength 1
   */
  username: string;
  /**
   * Password
   * @minLength 1
   */
  password: string;
}

export interface UserDetailPostAuth {
  /**
   * Public id
   * @format uuid
   */
  public_id?: string;
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
  /**
   * Display name
   * @minLength 1
   * @maxLength 255
   */
  display_name: string;
  /**
   * Staff status
   * Designates whether the user can log into this admin site.
   */
  is_staff?: boolean;
  /** Is writer */
  is_writer?: boolean;
  /** Is verified */
  is_verified?: boolean;
  /** Features */
  features?: Record<string, boolean>;
}

export interface GoogleTokenObtainPair {
  /**
   * Credentials
   * @minLength 1
   */
  credentials: string;
}

export interface TokenRefresh {
  /**
   * Refresh
   * @minLength 1
   */
  refresh: string;
  /**
   * Access
   * @minLength 1
   */
  access?: string;
}

export interface CreateEmailUser {
  /**
   * Email
   * @format email
   * @minLength 1
   */
  email: string;
  /**
   * Password
   * @minLength 1
   */
  password: string;
  /**
   * Display name
   * @minLength 1
   */
  display_name: string;
}

export interface UserEmail {
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
}

export interface VerifyEmailUser {
  /**
   * Email
   * @format email
   * @minLength 1
   */
  email: string;
  /**
   * Verify token
   * @format uuid
   */
  verify_token: string;
}

export interface Debug {
  /** Use llm fixtures */
  use_llm_fixtures?: boolean;
}

export interface Feedback {
  /**
   * Email
   * @format email
   * @minLength 1
   */
  email?: string;
  /**
   * Feedback
   * @minLength 1
   */
  feedback: string;
  /**
   * Data
   * @minLength 1
   */
  data?: string;
  /**
   * Subject
   * @minLength 1
   */
  subject?: string;
}

export interface ImageCreate {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /**
   * Extension
   * @minLength 1
   * @maxLength 16
   */
  extension: string;
  /**
   * Doc id
   * @minLength 1
   */
  doc_id?: string;
  /** Doc type */
  doc_type?: "proposal" | "collab";
  /** Upload url */
  upload_url?: string;
}

export interface ImageDetails {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /** Download url */
  download_url?: string;
  /**
   * Extension
   * @minLength 1
   * @maxLength 16
   */
  extension: string;
}

export interface AddJob {
  /**
   * Name
   * @minLength 1
   */
  name: string;
  /** Arguments */
  arguments: object;
}

export type AddJobResult = object;

export interface EventCapture {
  /** Name */
  name: "proposal_changed";
  /** Data */
  data?: Record<string, string | null>;
}

export interface EventCreate {
  /**
   * Kind
   * @minLength 1
   * @maxLength 32
   */
  kind: string;
  /**
   * Doc id
   * @minLength 1
   * @maxLength 36
   */
  doc_id?: string | null;
  /** Details */
  details?: object | null;
  /**
   * Prompt name
   * @minLength 1
   * @maxLength 64
   */
  prompt_name?: string | null;
  /**
   * Llm model
   * @minLength 1
   * @maxLength 64
   */
  llm_model?: string | null;
  /** Llm temperature */
  llm_temperature?: number | null;
  /**
   * Llm system prompt
   * @minLength 1
   */
  llm_system_prompt?: string | null;
  /**
   * Llm user prompt
   * @minLength 1
   */
  llm_user_prompt?: string | null;
  /**
   * Llm response ms
   * @min -2147483648
   * @max 2147483647
   */
  llm_response_ms?: number | null;
  /**
   * Llm response text
   * @minLength 1
   */
  llm_response_text?: string | null;
  /**
   * Llm prompt tokens
   * @min -2147483648
   * @max 2147483647
   */
  llm_prompt_tokens?: number | null;
  /**
   * Llm completion tokens
   * @min -2147483648
   * @max 2147483647
   */
  llm_completion_tokens?: number | null;
  /**
   * Llm total tokens
   * @min -2147483648
   * @max 2147483647
   */
  llm_total_tokens?: number | null;
  /**
   * Bot question id
   * @minLength 1
   * @maxLength 64
   */
  bot_question_id?: string | null;
  /** Bot answer result */
  bot_answer_result?: "none" | "suggested" | "answered" | null;
}

export interface Event {
  /** Id */
  id?: number;
  /** User username */
  user_username?: string;
  /**
   * Kind
   * @minLength 1
   * @maxLength 32
   */
  kind: string;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * User id
   * @min -2147483648
   * @max 2147483647
   */
  user_id?: number | null;
  /**
   * Doc id
   * @minLength 1
   * @maxLength 36
   */
  doc_id?: string | null;
  /** Details */
  details?: object | null;
  /**
   * Prompt name
   * @minLength 1
   * @maxLength 64
   */
  prompt_name?: string | null;
  /**
   * Llm model
   * @minLength 1
   * @maxLength 64
   */
  llm_model?: string | null;
  /** Llm temperature */
  llm_temperature?: number | null;
  /**
   * Llm system prompt
   * @minLength 1
   */
  llm_system_prompt?: string | null;
  /**
   * Llm user prompt
   * @minLength 1
   */
  llm_user_prompt?: string | null;
  /**
   * Llm response ms
   * @min -2147483648
   * @max 2147483647
   */
  llm_response_ms?: number | null;
  /**
   * Llm response text
   * @minLength 1
   */
  llm_response_text?: string | null;
  /**
   * Llm prompt tokens
   * @min -2147483648
   * @max 2147483647
   */
  llm_prompt_tokens?: number | null;
  /**
   * Llm completion tokens
   * @min -2147483648
   * @max 2147483647
   */
  llm_completion_tokens?: number | null;
  /**
   * Llm total tokens
   * @min -2147483648
   * @max 2147483647
   */
  llm_total_tokens?: number | null;
  /**
   * Bot question id
   * @minLength 1
   * @maxLength 64
   */
  bot_question_id?: string | null;
  /** Bot answer result */
  bot_answer_result?: "none" | "suggested" | "answered" | null;
}

export interface OrgDetails {
  /** ID */
  id?: number;
  /**
   * Name
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /**
   * Description
   * @maxLength 4096
   */
  description?: string | null;
  /**
   * Services
   * @maxLength 4096
   */
  services?: string | null;
  /**
   * Customers
   * @maxLength 4096
   */
  customers?: string | null;
  categories: string[];
  state_locations: string[];
  issuing_org_kinds: string[];
  /**
   * Profile
   * @maxLength 4096
   */
  profile?: string | null;
  raw_excluded_scopes?: string[];
  raw_preferred_scopes?: string[];
  /** Category display names */
  category_display_names?: Record<string, string>;
}

export interface Domain {
  /**
   * Domain
   * @minLength 1
   */
  domain: string;
}

export interface Org {
  /**
   * Public id
   * @format uuid
   */
  public_id?: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  email_domains?: Domain[];
  /** Is trial approved */
  is_trial_approved?: boolean;
  /** Is subscribed */
  is_subscribed?: boolean;
  categories?: string[];
}

export interface OrgCreate {
  /**
   * Name
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /** Domain */
  domain?: string;
}

export interface OrgSet {
  /**
   * Public id
   * @format uuid
   */
  public_id: string;
}

export interface OdoUser {
  /**
   * Display name
   * @minLength 1
   * @maxLength 255
   */
  display_name: string;
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
  /**
   * Public id
   * @format uuid
   */
  public_id?: string;
}

export interface AdminOrgDetails {
  /**
   * Name
   * @minLength 1
   * @maxLength 100
   */
  name: string;
  /** Is trial approved */
  is_trial_approved?: boolean;
  /** Is subscribed */
  is_subscribed?: boolean;
  /**
   * Profile
   * @maxLength 4096
   */
  profile?: string | null;
  members: OdoUser[];
}

export interface Prompt {
  /** Id */
  id?: number;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * Description
   * @minLength 1
   */
  description?: string | null;
  /** Used in product */
  used_in_product?: boolean;
}

export interface PromptDetail {
  /** Id */
  id?: number;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 255
   */
  name?: string;
  /**
   * Description
   * @minLength 1
   */
  description?: string | null;
  /** Content */
  content?: object;
  /** Variables */
  variables?: object;
  /** Value groups */
  value_groups?: object;
  /** Variations */
  variations?: object;
  /** Variation model */
  variation_model?: object;
  /** Variation notes */
  variation_notes?: object;
  /** Variation tools */
  variation_tools?: object;
  /**
   * Active variation
   * @min -2147483648
   * @max 2147483647
   */
  active_variation?: number | null;
  /** Used in product */
  used_in_product?: boolean;
}

export interface PromptFixture {
  /** Id */
  id?: number;
  /** Fixtures */
  fixtures: object;
}

export interface PromptVariable {
  /** Id */
  id?: number;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * Description
   * @minLength 1
   */
  description?: string | null;
}

export interface PromptVariablesDetail {
  /** Id */
  id?: number;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 255
   */
  name?: string;
  /**
   * Description
   * @minLength 1
   */
  description?: string | null;
}

export interface PromptVariableValue {
  /** Id */
  id?: number;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * Value
   * @minLength 1
   */
  value: string;
}

export interface Diff {
  /**
   * Finalize
   * @default true
   */
  finalize?: boolean;
}

export interface RFPCategory {
  /**
   * Name
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * Display name
   * @minLength 1
   * @maxLength 255
   */
  display_name: string;
  /** Description */
  description?: string | null;
  /** ID */
  id?: number;
  /** Applied */
  applied?: boolean;
  keywords: string[];
}

export interface RFPCategoryDetails {
  /**
   * Name
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * Display name
   * @minLength 1
   * @maxLength 255
   */
  display_name: string;
  /** Description */
  description?: string | null;
  /** Id */
  id?: number;
}

export interface RFPKeyDates {
  /**
   * Date
   * @minLength 1
   */
  date: string;
  /**
   * Name
   * @minLength 1
   */
  name: string;
}

export interface Label {
  /**
   * Name
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /** ID */
  id?: number;
}

export interface Comparison {
  /**
   * Score
   * @min -2147483648
   * @max 2147483647
   */
  score?: number | null;
  /** ID */
  id?: number;
  labels: Label[];
}

export interface RFPComparison {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /** Title */
  title?: string;
  /** Issuing org */
  issuing_org?: string;
  /** Description */
  description?: string;
  /**
   * Due date
   * @format date
   */
  due_date?: string | null;
  other_key_dates?: RFPKeyDates[];
  /** Budget */
  budget?: string;
  /** Tasks */
  tasks?: string;
  /** Minimum qualifications */
  minimum_qualifications?: string;
  /**
   * Slug
   * @format slug
   * @minLength 1
   * @maxLength 255
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  slug?: string | null;
  categories?: string[];
  /** Location */
  location?: string;
  comparison?: Comparison;
}

export interface RFPComparisonList {
  /** ID */
  id?: number;
  /**
   * Score
   * @min -2147483648
   * @max 2147483647
   */
  score?: number | null;
  /**
   * Reasons
   * @minLength 1
   */
  reasons?: string | null;
  /**
   * Org name
   * @minLength 1
   */
  org_name?: string;
  /**
   * Org id
   * @minLength 1
   */
  org_id?: string;
  /**
   * Rfp created
   * @format date-time
   */
  rfp_created?: string;
  /**
   * Rfp name
   * @minLength 1
   */
  rfp_name?: string;
  /** Rfp id */
  rfp_id?: string;
}

export interface ComparisonCreate {
  /**
   * Rfp id
   * @minLength 1
   */
  rfp_id: string;
  /**
   * Org id
   * @minLength 1
   */
  org_id: string;
}

export interface ComparisonDetail {
  /** Id */
  id?: number;
  /** @uniqueItems true */
  labels?: number[];
}

export interface ComparisonFeedback {
  /** Feedback */
  feedback?: string;
}

export interface CoverLetterList {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /**
   * Topic
   * @maxLength 255
   */
  topic?: string;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /** In proposal */
  in_proposal?: boolean;
}

export interface CoverLetterDetails {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * Topic
   * @maxLength 255
   */
  topic?: string;
  /**
   * Writeup id
   * @format uuid
   */
  writeup_id?: string | null;
}

export interface CoverLetterGenerate {
  /**
   * Location
   * @minLength 1
   * @maxLength 255
   */
  location: string;
  /**
   * Section depth
   * @min 1
   * @max 6
   */
  section_depth: number;
  /** Mode */
  mode: "as-is" | "tailored";
}

export interface OverviewList {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /**
   * Topic
   * @maxLength 255
   */
  topic?: string;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /** In proposal */
  in_proposal?: boolean;
}

export interface OverviewDetails {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * Topic
   * @maxLength 255
   */
  topic?: string;
  /**
   * Writeup id
   * @format uuid
   */
  writeup_id?: string | null;
}

export interface OverviewGenerate {
  /**
   * Location
   * @minLength 1
   * @maxLength 255
   */
  location: string;
  /**
   * Section depth
   * @min 1
   * @max 6
   */
  section_depth: number;
  /** Mode */
  mode: "as-is" | "tailored";
}

export interface PersonList {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /**
   * Name
   * @maxLength 255
   */
  name?: string;
  /**
   * Title
   * @maxLength 255
   */
  title?: string;
  /** In proposal */
  in_proposal?: boolean;
}

export interface PersonDetails {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * Name
   * @maxLength 255
   */
  name?: string;
  /**
   * Title
   * @maxLength 255
   */
  title?: string;
  /**
   * Bio id
   * @format uuid
   */
  bio_id?: string | null;
}

export interface PersonGenerate {
  /**
   * Location
   * @minLength 1
   * @maxLength 255
   */
  location: string;
  /**
   * Section depth
   * @min 1
   * @max 6
   */
  section_depth: number;
}

export interface ProjectList {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /**
   * Title
   * @maxLength 255
   */
  title?: string;
  /**
   * Start year
   * @min -2147483648
   * @max 2147483647
   */
  start_year?: number | null;
  /**
   * Start month
   * @min -2147483648
   * @max 2147483647
   */
  start_month?: number | null;
  /**
   * Customer
   * @maxLength 255
   */
  customer?: string;
  /**
   * Location
   * @maxLength 255
   */
  location?: string;
  /**
   * Contact name
   * @maxLength 255
   */
  contact_name?: string;
  /**
   * Contact email
   * @maxLength 1023
   */
  contact_email?: string;
  /** In proposal */
  in_proposal?: boolean;
}

export interface ProjectDetails {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * Title
   * @maxLength 255
   */
  title?: string;
  /**
   * Customer
   * @maxLength 255
   */
  customer?: string;
  /** Description */
  description?: string;
  /**
   * Description id
   * @format uuid
   */
  description_id?: string | null;
  /**
   * Location
   * @maxLength 255
   */
  location?: string;
  /**
   * Start month
   * @min -2147483648
   * @max 2147483647
   */
  start_month?: number | null;
  /**
   * Start year
   * @min -2147483648
   * @max 2147483647
   */
  start_year?: number | null;
  /**
   * End month
   * @min -2147483648
   * @max 2147483647
   */
  end_month?: number | null;
  /**
   * End year
   * @min -2147483648
   * @max 2147483647
   */
  end_year?: number | null;
  /**
   * Contact name
   * @maxLength 255
   */
  contact_name?: string;
  /**
   * Contact title
   * @maxLength 255
   */
  contact_title?: string;
  /**
   * Contact email
   * @maxLength 1023
   */
  contact_email?: string;
  /**
   * Contact phone
   * @maxLength 255
   */
  contact_phone?: string;
  /**
   * Contact address
   * @maxLength 255
   */
  contact_address?: string;
}

export interface ProjectGenerate {
  /**
   * Location
   * @minLength 1
   * @maxLength 255
   */
  location: string;
  /**
   * Section depth
   * @min 1
   * @max 6
   */
  section_depth: number;
  /** Mode */
  mode: "as-is" | "reference" | "tailored";
}

export interface SubcontractorList {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /** In proposal */
  in_proposal?: boolean;
}

export interface SubcontractorDetails {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * Overview id
   * @format uuid
   */
  overview_id?: string | null;
}

export interface SubcontractorGenerate {
  /**
   * Location
   * @minLength 1
   * @maxLength 255
   */
  location: string;
  /**
   * Section depth
   * @min 1
   * @max 6
   */
  section_depth: number;
  /** Mode */
  mode: "as-is";
}

export interface FinalCheckList {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 256
   */
  name: string;
}

export interface FinalCheckCreate {
  /**
   * Rfp hash
   * @minLength 1
   */
  rfp_hash: string;
  /**
   * Proposal hash
   * @minLength 1
   */
  proposal_hash: string;
  /**
   * Name
   * @minLength 1
   */
  name: string;
  /**
   * Email
   * @format email
   * @minLength 1
   */
  email?: string;
}

export interface FinalCheckDetails {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 256
   */
  name: string;
  /** Is proposal uploaded */
  is_proposal_uploaded?: boolean;
  /** Report */
  report?: object | null;
  /** Rfp url */
  rfp_url?: string;
  /** Rfp name */
  rfp_name?: string;
}

export interface InternalProposalDetails {
  /** Participant emails */
  participant_emails?: string;
}

export interface ProposalSearch {
  /**
   * Query
   * @minLength 1
   */
  query: string;
  /**
   * Organization id
   * @format uuid
   */
  organization_id: string;
  /**
   * Strategy
   * @minLength 1
   * @default "default"
   */
  strategy?: string;
  /** Topic */
  topic?: string;
}

export interface LibTestSimilarityInput {
  /**
   * Query
   * @minLength 1
   */
  query: string;
  /**
   * Result
   * @minLength 1
   */
  result: string;
  /**
   * Encode query as document
   * @default false
   */
  encode_query_as_document?: boolean;
}

export interface ProposalListCreate {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * Title
   * @minLength 1
   */
  title?: string;
  /**
   * Rfp id
   * @format uuid
   */
  rfp_id: string;
  /**
   * Due date
   * @format date
   */
  due_date?: string | null;
}

export interface Org {
  /**
   * Public id
   * @format uuid
   */
  public_id?: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 100
   */
  name: string;
}

export interface ProposalAdminDetail {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * Title
   * @minLength 1
   */
  title?: string;
  /**
   * Rfp id
   * @format uuid
   */
  rfp_id: string;
  /**
   * Due date
   * @format date
   */
  due_date?: string | null;
  org?: Org;
}

export interface ProposalEligibility {
  /** Force */
  force?: boolean;
}

export interface PastProposalCreate {
  /**
   * Hash
   * @minLength 1
   */
  hash: string;
  /**
   * Name
   * @minLength 1
   */
  name: string;
  /**
   * Topic
   * @minLength 1
   */
  topic?: string;
}

export interface PastProposalList {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /** Is processed */
  is_processed?: boolean;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * Processing job id
   * @min -2147483648
   * @max 2147483647
   */
  processing_job_id?: number | null;
  /** Is uploaded */
  is_uploaded?: boolean;
  /** In proposal */
  in_proposal?: boolean;
  /**
   * Topic
   * @minLength 1
   * @maxLength 255
   */
  topic?: string | null;
}

export interface PastProposalDetail {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /** Is processed */
  is_processed?: boolean;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /**
   * Processing job id
   * @min -2147483648
   * @max 2147483647
   */
  processing_job_id?: number | null;
  /** Is uploaded */
  is_uploaded?: boolean;
  /** View url */
  view_url?: string;
  /** Markdown url */
  markdown_url?: string;
  /**
   * Topic
   * @minLength 1
   * @maxLength 255
   */
  topic?: string | null;
}

export interface ProposalMetadataSnapshot {
  /** ID */
  id?: number;
  /** Metadata */
  metadata: object;
  /**
   * Created at
   * @format date-time
   */
  created_at?: string;
  /**
   * Proposal
   * @format uuid
   */
  proposal?: string;
  /** Creator */
  creator?: number;
}

export interface Participant {
  /**
   * Display name
   * @minLength 1
   * @maxLength 255
   */
  display_name: string;
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
  /**
   * Public id
   * @format uuid
   */
  public_id?: string;
  /** Is owner */
  is_owner?: boolean;
}

export interface BorderStyle {
  /** Size */
  size?: number;
  /**
   * Color
   * @minLength 1
   */
  color?: string;
  /** Style */
  style?: "single" | "double" | "dotted" | "dashed" | "thick" | "thin";
  /** Position */
  position?: "top" | "bottom" | "around";
}

export interface TextStyle {
  /**
   * Font
   * @minLength 1
   */
  font?: string;
  /** Font size */
  font_size?: number;
  /**
   * Font weight
   * @minLength 1
   */
  font_weight?: string;
  /** Character style */
  character_style?: "normal" | "italic" | "underline";
  /** Case style */
  case_style?: "normal" | "uppercase" | "lowercase";
  /** Padding top */
  padding_top?: number;
  /** Padding bottom */
  padding_bottom?: number;
  /** Padding left */
  padding_left?: number;
  /** Padding right */
  padding_right?: number;
  /** Margin top */
  margin_top?: number;
  /** Margin bottom */
  margin_bottom?: number;
  /** Line spacing */
  line_spacing?: number;
  /** Contextual spacing */
  contextual_spacing?: boolean;
  /**
   * Color
   * @minLength 1
   */
  color?: string;
  /**
   * Background color
   * @minLength 1
   */
  background_color?: string;
  border?: BorderStyle;
  /** Alignment */
  alignment?: "left" | "center" | "right" | "both";
}

export interface TextStyles {
  default?: TextStyle;
  p?: TextStyle;
  p_list?: TextStyle;
  h1?: TextStyle;
  h2?: TextStyle;
  h3?: TextStyle;
  h4?: TextStyle;
  h5?: TextStyle;
  h6?: TextStyle;
}

export interface PageSize {
  /** Width */
  width?: number;
  /** Height */
  height?: number;
  /** Margin top */
  margin_top?: number;
  /** Margin bottom */
  margin_bottom?: number;
  /** Margin left */
  margin_left?: number;
  /** Margin right */
  margin_right?: number;
  /** Header */
  header?: number;
  /** Footer */
  footer?: number;
  /** Orientation */
  orientation?: "portrait" | "landscape";
}

export interface TextRun {
  /**
   * Text
   * @minLength 1
   */
  text?: string;
  style?: TextStyle;
  /** Type */
  type?: "gif" | "jpg" | "png";
  /**
   * Url
   * @minLength 1
   */
  url?: string;
  /**
   * Data
   * @minLength 1
   */
  data?: string;
  /** Width */
  width?: number;
  /** Height */
  height?: number;
  /** X */
  x?: number;
  /** Y */
  y?: number;
}

export interface Paragraph {
  children: TextRun[];
  style?: TextStyle;
}

export interface StyleGuide {
  text_styles?: TextStyles;
  page_size?: PageSize;
  header?: Paragraph[];
  footer?: Paragraph[];
}

export interface ProposalDetail {
  participants?: Participant[];
  /**
   * Title
   * @minLength 1
   * @maxLength 255
   */
  title: string;
  /**
   * Rfp id
   * @minLength 1
   */
  rfp_id?: string;
  /**
   * Published at
   * @format date-time
   */
  published_at?: string | null;
  /**
   * Due date
   * @format date
   */
  due_date?: string | null;
  /**
   * Org id
   * @minLength 1
   */
  org_id?: string;
  /** Instructions */
  instructions?: string | null;
  style_guide?: StyleGuide;
  /**
   * Archived at
   * @format date-time
   */
  archived_at?: string | null;
  /**
   * Topic
   * @maxLength 255
   */
  topic?: string | null;
  /**
   * Initiated at
   * @format date-time
   */
  initiated_at?: string | null;
  /** Is initialized */
  is_initialized?: boolean;
}

export interface RequirementAdd {
  /**
   * Target id
   * @minLength 1
   */
  target_id: string;
  /** Target name */
  target_name: string;
  /** Mode */
  mode: "add-above" | "add-below" | "rewrite";
  /** Level */
  level: number;
  /**
   * Rfp block id
   * @minLength 1
   */
  rfp_block_id: string;
  previous_headings?: string[];
  /**
   * Update content
   * @default true
   */
  update_content?: boolean;
}

export interface AIAction {
  /** Action */
  action: "turn_into_paragraphs" | "turn_into_lists" | "make_shorter" | "make_longer";
  content: (string | null)[];
  /**
   * Section id
   * @minLength 1
   */
  section_id?: string;
}

export interface RequirementGetRelevantContext {
  /**
   * Name
   * @minLength 1
   */
  name: string;
}

export interface RequirementGenerate {
  /**
   * Use fixture
   * @default false
   */
  use_fixture?: boolean;
  /** Feedback */
  feedback?: string;
  /**
   * Title
   * @minLength 1
   */
  title: string;
  /**
   * Outline only
   * @default false
   */
  outline_only?: boolean;
  /**
   * Heading level
   * @default 1
   */
  heading_level?: number;
  /**
   * Include previous content
   * @default true
   */
  include_previous_content?: boolean;
}

export interface RequirementRelevantExperienceGenerate {
  /**
   * Use fixture
   * @default false
   */
  use_fixture?: boolean;
  selected_project_ids: string[];
}

export interface RequirementStatus {
  /** Id */
  id?: number;
  /**
   * Name
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * Icon
   * @minLength 1
   */
  icon?: string;
}

export interface ProposalGrant {
  /**
   * User id
   * @format uuid
   */
  user_id: string;
  /**
   * Make owner
   * @default false
   */
  make_owner?: boolean;
}

export interface RFPLabel {
  /** Id */
  id?: number;
  /**
   * Name
   * @minLength 1
   * @maxLength 255
   */
  name: string;
}

export interface RFPCreate {
  /**
   * Hash
   * @minLength 1
   */
  hash: string;
  /**
   * Name
   * @minLength 1
   */
  name: string;
  /**
   * For admin
   * @default false
   */
  for_admin?: boolean;
  /**
   * Source
   * @minLength 1
   */
  source?: string;
}

export interface RFPCSVUpload {
  /**
   * Content
   * @minLength 1
   */
  content: string;
  /**
   * Source
   * @minLength 1
   */
  source?: string;
}

export interface RFPList {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /** Display name */
  display_name?: string;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /** Processing status */
  processing_status?: string;
  /** Next step */
  next_step?: string;
  orgs?: string[];
  /** Is uploaded */
  is_uploaded?: boolean;
  /** Has markdown */
  has_markdown?: boolean;
  categories?: string[];
  /** Issuing org */
  issuing_org?: string;
  /**
   * Foia request job id
   * @min -2147483648
   * @max 2147483647
   */
  foia_request_job_id?: number | null;
  /**
   * Invalid reason
   * @maxLength 255
   */
  invalid_reason?: string | null;
  /**
   * Test data id
   * @min -2147483648
   * @max 2147483647
   */
  test_data_id?: number | null;
}

export interface RFPComparisonDetail {
  /** Link */
  link?: string;
}

export interface RFPDetail {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 255
   */
  name: string;
  /**
   * Created
   * @format date-time
   */
  created?: string;
  /** View url */
  view_url?: string;
  /** Markdown url */
  markdown_url?: string;
  /**
   * Processing job id
   * @min -2147483648
   * @max 2147483647
   */
  processing_job_id?: number | null;
  /**
   * Project name
   * @maxLength 1024
   */
  project_name?: string | null;
  /** Issuing org */
  issuing_org?: string;
  categories: RFPCategory[];
  /**
   * Slug
   * @format slug
   * @minLength 1
   * @maxLength 255
   * @pattern ^[-a-zA-Z0-9_]+$
   */
  slug?: string | null;
  start_questions?: string[];
}

export interface RFPFile {
  /** Id */
  id?: number;
  /**
   * Name
   * @minLength 1
   * @maxLength 1024
   */
  name: string;
  /**
   * Extension
   * @minLength 1
   * @maxLength 10
   */
  extension: string;
  /** Upload url */
  upload_url?: string;
}

export interface RFPFileDetail {
  /**
   * Id
   * @format uuid
   */
  id?: string;
  /**
   * Name
   * @minLength 1
   * @maxLength 1024
   */
  name: string;
  /**
   * Extension
   * @minLength 1
   */
  extension?: string;
  /** Read url */
  read_url?: string;
  /** Uploaded */
  uploaded?: boolean;
}

export interface RFPREquirement {
  /** ID */
  id?: number;
  /**
   * Description
   * @minLength 1
   */
  description: string;
  /**
   * Category
   * @minLength 1
   * @maxLength 255
   */
  category: string;
}

export interface RFPBlock {
  /**
   * Index
   * @min -2147483648
   * @max 2147483647
   */
  index: number;
  /** Bbox */
  bbox: object;
  /**
   * Page
   * @min -2147483648
   * @max 2147483647
   */
  page: number;
  /**
   * Group id
   * @min -2147483648
   * @max 2147483647
   */
  group_id?: number | null;
  requirements: RFPREquirement[];
}

export interface Unsubscribe {
  /**
   * Template id
   * @minLength 1
   */
  template_id: string;
}

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "/api/v1" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Document Editor API
 * @version v1
 * @baseUrl /api/v1
 *
 * API for the Document Editor web app
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  applyCode = {
    /**
     * No description
     *
     * @tags apply-code
     * @name ApplyCodeCreate
     * @request POST:/apply-code/
     * @secure
     */
    applyCodeCreate: (data: ApplyCode, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/apply-code/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  auth = {
    /**
     * No description
     *
     * @tags auth
     * @name AuthForgotCreate
     * @request POST:/auth/forgot/
     * @secure
     */
    authForgotCreate: (data: ForgotPassword, params: RequestParams = {}) =>
      this.request<ForgotPassword, any>({
        path: `/auth/forgot/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthLogoutCreate
     * @request POST:/auth/logout/
     * @secure
     */
    authLogoutCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/auth/logout/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthRecoverCreate
     * @request POST:/auth/recover/
     * @secure
     */
    authRecoverCreate: (data: RecoverPasswordEmailUser, params: RequestParams = {}) =>
      this.request<RecoverPasswordEmailUser, any>({
        path: `/auth/recover/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthResendVerifyCreate
     * @request POST:/auth/resend-verify/
     * @secure
     */
    authResendVerifyCreate: (data: ReverifyEmailUser, params: RequestParams = {}) =>
      this.request<ReverifyEmailUser, any>({
        path: `/auth/resend-verify/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthTokenEmailCreate
     * @request POST:/auth/token/email/
     * @secure
     */
    authTokenEmailCreate: (data: EmailTokenObtainPair, params: RequestParams = {}) =>
      this.request<UserDetailPostAuth, any>({
        path: `/auth/token/email/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthTokenGoogleCreate
     * @request POST:/auth/token/google/
     * @secure
     */
    authTokenGoogleCreate: (data: GoogleTokenObtainPair, params: RequestParams = {}) =>
      this.request<UserDetailPostAuth, any>({
        path: `/auth/token/google/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthTokenRefreshCreate
     * @request POST:/auth/token/refresh/
     * @secure
     */
    authTokenRefreshCreate: (params: RequestParams = {}) =>
      this.request<TokenRefresh, any>({
        path: `/auth/token/refresh/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthUserCreate
     * @request POST:/auth/user/
     * @secure
     */
    authUserCreate: (data: CreateEmailUser, params: RequestParams = {}) =>
      this.request<CreateEmailUser, any>({
        path: `/auth/user/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthUserDetailsList
     * @request GET:/auth/user/details/
     * @secure
     */
    authUserDetailsList: (params: RequestParams = {}) =>
      this.request<UserDetailPostAuth, any>({
        path: `/auth/user/details/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthUserEmailRead
     * @request GET:/auth/user/email/
     * @secure
     */
    authUserEmailRead: (params: RequestParams = {}) =>
      this.request<UserEmail, any>({
        path: `/auth/user/email/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthVerifyCreate
     * @request POST:/auth/verify/
     * @secure
     */
    authVerifyCreate: (data: VerifyEmailUser, params: RequestParams = {}) =>
      this.request<VerifyEmailUser, any>({
        path: `/auth/verify/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  collabDocs = {
    /**
     * @description Check if the user has permission to access a collaborative document
     *
     * @tags collab_docs
     * @name CollabDocsPermissionList
     * @summary Check if the user has permission to access a collaborative document
     * @request GET:/collab_docs/{id}/permission/
     * @secure
     */
    collabDocsPermissionList: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          allow?: boolean;
          auth_data?: object;
        },
        any
      >({
        path: `/collab_docs/${id}/permission/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  debug = {
    /**
     * No description
     *
     * @tags debug
     * @name DebugRead
     * @request GET:/debug/
     * @secure
     */
    debugRead: (params: RequestParams = {}) =>
      this.request<Debug, any>({
        path: `/debug/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags debug
     * @name DebugUpdate
     * @request PUT:/debug/
     * @secure
     */
    debugUpdate: (data: Debug, params: RequestParams = {}) =>
      this.request<Debug, any>({
        path: `/debug/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags debug
     * @name DebugPartialUpdate
     * @request PATCH:/debug/
     * @secure
     */
    debugPartialUpdate: (data: Debug, params: RequestParams = {}) =>
      this.request<Debug, any>({
        path: `/debug/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  email = {
    /**
     * No description
     *
     * @tags email
     * @name EmailCreate
     * @request POST:/email/
     * @secure
     */
    emailCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/email/`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
  error = {
    /**
     * No description
     *
     * @tags error
     * @name ErrorList
     * @request GET:/error/
     * @secure
     */
    errorList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/error/`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  feedback = {
    /**
     * No description
     *
     * @tags feedback
     * @name FeedbackCreate
     * @request POST:/feedback/
     * @secure
     */
    feedbackCreate: (data: Feedback, params: RequestParams = {}) =>
      this.request<Feedback, any>({
        path: `/feedback/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  healthcheck = {
    /**
     * No description
     *
     * @tags healthcheck
     * @name HealthcheckList
     * @request GET:/healthcheck/
     * @secure
     */
    healthcheckList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/healthcheck/`,
        method: "GET",
        secure: true,
        ...params,
      }),
  };
  images = {
    /**
     * No description
     *
     * @tags images
     * @name ImagesCreate
     * @request POST:/images/
     * @secure
     */
    imagesCreate: (data: ImageCreate, params: RequestParams = {}) =>
      this.request<ImageCreate, any>({
        path: `/images/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags images
     * @name ImagesRead
     * @request GET:/images/{id}/
     * @secure
     */
    imagesRead: (id: string, params: RequestParams = {}) =>
      this.request<ImageDetails, any>({
        path: `/images/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags images
     * @name ImagesUploadedCreate
     * @request POST:/images/{id}/uploaded/
     * @secure
     */
    imagesUploadedCreate: (id: string, params: RequestParams = {}) =>
      this.request<ImageDetails, any>({
        path: `/images/${id}/uploaded/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  jobs = {
    /**
     * No description
     *
     * @tags jobs
     * @name JobsCreate
     * @request POST:/jobs/
     * @secure
     */
    jobsCreate: (data: AddJob, params: RequestParams = {}) =>
      this.request<AddJobResult, any>({
        path: `/jobs/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  metrics = {
    /**
     * @description Returns whether the user can debug
     *
     * @tags metrics
     * @name CanDebug
     * @request GET:/metrics/
     * @secure
     */
    canDebug: (params: RequestParams = {}) =>
      this.request<
        {
          can_debug?: boolean;
        },
        any
      >({
        path: `/metrics/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags metrics
     * @name MetricsEventCaptureCreate
     * @request POST:/metrics/event/capture/
     * @secure
     */
    metricsEventCaptureCreate: (data: EventCapture, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/metrics/event/capture/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags metrics
     * @name MetricsEventsCreate
     * @request POST:/metrics/events/
     * @secure
     */
    metricsEventsCreate: (data: EventCreate, params: RequestParams = {}) =>
      this.request<EventCreate, any>({
        path: `/metrics/events/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags metrics
     * @name MetricsEventsDocRead
     * @request GET:/metrics/events/doc/{id}/
     * @secure
     */
    metricsEventsDocRead: (id: string, params: RequestParams = {}) =>
      this.request<Event[], any>({
        path: `/metrics/events/doc/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags metrics
     * @name MetricsEventsUserList
     * @request GET:/metrics/events/user/
     * @secure
     */
    metricsEventsUserList: (
      query?: {
        /** A search term. */
        search?: string;
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: Event[];
        },
        any
      >({
        path: `/metrics/events/user/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  org = {
    /**
     * No description
     *
     * @tags org
     * @name OrgRead
     * @request GET:/org/
     * @secure
     */
    orgRead: (params: RequestParams = {}) =>
      this.request<OrgDetails, any>({
        path: `/org/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags org
     * @name OrgUpdate
     * @request PUT:/org/
     * @secure
     */
    orgUpdate: (data: OrgDetails, params: RequestParams = {}) =>
      this.request<OrgDetails, any>({
        path: `/org/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags org
     * @name OrgPartialUpdate
     * @request PATCH:/org/
     * @secure
     */
    orgPartialUpdate: (data: OrgDetails, params: RequestParams = {}) =>
      this.request<OrgDetails, any>({
        path: `/org/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  orgs = {
    /**
     * No description
     *
     * @tags orgs
     * @name OrgsList
     * @request GET:/orgs/
     * @secure
     */
    orgsList: (
      query?: {
        /** A search term. */
        search?: string;
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: Org[];
        },
        any
      >({
        path: `/orgs/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags orgs
     * @name OrgsCreateCreate
     * @request POST:/orgs/create/
     * @secure
     */
    orgsCreateCreate: (data: OrgCreate, params: RequestParams = {}) =>
      this.request<OrgCreate, any>({
        path: `/orgs/create/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags orgs
     * @name OrgsSetCreate
     * @request POST:/orgs/set/
     * @secure
     */
    orgsSetCreate: (data: OrgSet, params: RequestParams = {}) =>
      this.request<OrgSet, any>({
        path: `/orgs/set/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags orgs
     * @name OrgsRead
     * @request GET:/orgs/{org_id}/
     * @secure
     */
    orgsRead: (orgId: string, params: RequestParams = {}) =>
      this.request<AdminOrgDetails, any>({
        path: `/orgs/${orgId}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags orgs
     * @name OrgsUpdate
     * @request PUT:/orgs/{org_id}/
     * @secure
     */
    orgsUpdate: (orgId: string, data: AdminOrgDetails, params: RequestParams = {}) =>
      this.request<AdminOrgDetails, any>({
        path: `/orgs/${orgId}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags orgs
     * @name OrgsPartialUpdate
     * @request PATCH:/orgs/{org_id}/
     * @secure
     */
    orgsPartialUpdate: (orgId: string, data: AdminOrgDetails, params: RequestParams = {}) =>
      this.request<AdminOrgDetails, any>({
        path: `/orgs/${orgId}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags orgs
     * @name OrgsDelete
     * @request DELETE:/orgs/{org_id}/
     * @secure
     */
    orgsDelete: (orgId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/orgs/${orgId}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags orgs
     * @name OrgsUsersList
     * @request GET:/orgs/{org_id}/users/
     * @secure
     */
    orgsUsersList: (orgId: string, params: RequestParams = {}) =>
      this.request<OdoUser[], any>({
        path: `/orgs/${orgId}/users/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  payments = {
    /**
     * No description
     *
     * @tags payments
     * @name PaymentsEventsCreate
     * @request POST:/payments/events/
     * @secure
     */
    paymentsEventsCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/payments/events/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * @description This view is responsible for creating a checkout session with Stripe.
     *
     * @tags payments
     * @name PaymentsSessionCreate
     * @request POST:/payments/session/
     * @secure
     */
    paymentsSessionCreate: (params: RequestParams = {}) =>
      this.request<
        {
          secret?: string;
          already_subscribed?: string;
        },
        any
      >({
        path: `/payments/session/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags payments
     * @name PaymentsSessionRead
     * @request GET:/payments/session/{id}/
     * @secure
     */
    paymentsSessionRead: (id: string, params: RequestParams = {}) =>
      this.request<
        {
          status: string;
          customer_email?: string;
        },
        any
      >({
        path: `/payments/session/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  promptRefinery = {
    /**
     * @description Create a new user using pydantic model
     *
     * @tags prompt_refinery
     * @name PromptRefineryArbitraryCreate
     * @request POST:/prompt_refinery/arbitrary/
     * @secure
     */
    promptRefineryArbitraryCreate: (
      data: {
        /**
         * Model
         * @default "gpt-4"
         */
        model?: string;
        /**
         * Temperature
         * @default 0.2
         */
        temperature?: number;
        /** Messages */
        messages: any[];
        /**
         * Tools
         * @default null
         */
        tools?: object[];
        /**
         * Functions
         * @default null
         */
        functions?: any[];
      },
      params: RequestParams = {},
    ) =>
      this.request<string, void>({
        path: `/prompt_refinery/arbitrary/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryAuthList
     * @request GET:/prompt_refinery/auth/
     * @secure
     */
    promptRefineryAuthList: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/prompt_refinery/auth/`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryPromptsList
     * @request GET:/prompt_refinery/prompts/
     * @secure
     */
    promptRefineryPromptsList: (
      query?: {
        /** A search term. */
        search?: string;
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: Prompt[];
        },
        any
      >({
        path: `/prompt_refinery/prompts/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryPromptsCreate
     * @request POST:/prompt_refinery/prompts/
     * @secure
     */
    promptRefineryPromptsCreate: (data: Prompt, params: RequestParams = {}) =>
      this.request<Prompt, any>({
        path: `/prompt_refinery/prompts/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryPromptsRead
     * @request GET:/prompt_refinery/prompts/{id}/
     * @secure
     */
    promptRefineryPromptsRead: (id: number, params: RequestParams = {}) =>
      this.request<PromptDetail, any>({
        path: `/prompt_refinery/prompts/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryPromptsUpdate
     * @request PUT:/prompt_refinery/prompts/{id}/
     * @secure
     */
    promptRefineryPromptsUpdate: (id: number, data: PromptDetail, params: RequestParams = {}) =>
      this.request<PromptDetail, any>({
        path: `/prompt_refinery/prompts/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryPromptsPartialUpdate
     * @request PATCH:/prompt_refinery/prompts/{id}/
     * @secure
     */
    promptRefineryPromptsPartialUpdate: (id: number, data: PromptDetail, params: RequestParams = {}) =>
      this.request<PromptDetail, any>({
        path: `/prompt_refinery/prompts/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryPromptsDelete
     * @request DELETE:/prompt_refinery/prompts/{id}/
     * @secure
     */
    promptRefineryPromptsDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/prompt_refinery/prompts/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryPromptsFixturesRead
     * @request GET:/prompt_refinery/prompts/{id}/fixtures/
     * @secure
     */
    promptRefineryPromptsFixturesRead: (id: number, params: RequestParams = {}) =>
      this.request<PromptFixture, any>({
        path: `/prompt_refinery/prompts/${id}/fixtures/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryPromptsFixturesUpdate
     * @request PUT:/prompt_refinery/prompts/{id}/fixtures/
     * @secure
     */
    promptRefineryPromptsFixturesUpdate: (id: number, data: PromptFixture, params: RequestParams = {}) =>
      this.request<PromptFixture, any>({
        path: `/prompt_refinery/prompts/${id}/fixtures/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryPromptsFixturesPartialUpdate
     * @request PATCH:/prompt_refinery/prompts/{id}/fixtures/
     * @secure
     */
    promptRefineryPromptsFixturesPartialUpdate: (id: number, data: PromptFixture, params: RequestParams = {}) =>
      this.request<PromptFixture, any>({
        path: `/prompt_refinery/prompts/${id}/fixtures/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryVariablesList
     * @request GET:/prompt_refinery/variables/
     * @secure
     */
    promptRefineryVariablesList: (params: RequestParams = {}) =>
      this.request<PromptVariable[], any>({
        path: `/prompt_refinery/variables/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryVariablesCreate
     * @request POST:/prompt_refinery/variables/
     * @secure
     */
    promptRefineryVariablesCreate: (data: PromptVariable, params: RequestParams = {}) =>
      this.request<PromptVariable, any>({
        path: `/prompt_refinery/variables/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryVariablesRead
     * @request GET:/prompt_refinery/variables/{id}
     * @secure
     */
    promptRefineryVariablesRead: (id: number, params: RequestParams = {}) =>
      this.request<PromptVariablesDetail, any>({
        path: `/prompt_refinery/variables/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryVariablesUpdate
     * @request PUT:/prompt_refinery/variables/{id}
     * @secure
     */
    promptRefineryVariablesUpdate: (id: number, data: PromptVariablesDetail, params: RequestParams = {}) =>
      this.request<PromptVariablesDetail, any>({
        path: `/prompt_refinery/variables/${id}`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryVariablesPartialUpdate
     * @request PATCH:/prompt_refinery/variables/{id}
     * @secure
     */
    promptRefineryVariablesPartialUpdate: (id: number, data: PromptVariablesDetail, params: RequestParams = {}) =>
      this.request<PromptVariablesDetail, any>({
        path: `/prompt_refinery/variables/${id}`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryVariablesDelete
     * @request DELETE:/prompt_refinery/variables/{id}
     * @secure
     */
    promptRefineryVariablesDelete: (id: number, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/prompt_refinery/variables/${id}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryVariablesValuesList
     * @request GET:/prompt_refinery/variables/{prompt_variable_id}/values/
     * @secure
     */
    promptRefineryVariablesValuesList: (promptVariableId: string, params: RequestParams = {}) =>
      this.request<PromptVariableValue[], any>({
        path: `/prompt_refinery/variables/${promptVariableId}/values/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryVariablesValuesCreate
     * @request POST:/prompt_refinery/variables/{prompt_variable_id}/values/
     * @secure
     */
    promptRefineryVariablesValuesCreate: (
      promptVariableId: string,
      data: PromptVariableValue,
      params: RequestParams = {},
    ) =>
      this.request<PromptVariableValue, any>({
        path: `/prompt_refinery/variables/${promptVariableId}/values/`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryVariablesValuesRead
     * @request GET:/prompt_refinery/variables/{prompt_variable_id}/values/{id}/
     * @secure
     */
    promptRefineryVariablesValuesRead: (promptVariableId: string, id: number, params: RequestParams = {}) =>
      this.request<PromptVariableValue[], any>({
        path: `/prompt_refinery/variables/${promptVariableId}/values/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags prompt_refinery
     * @name PromptRefineryVariablesValuesCreate2
     * @request POST:/prompt_refinery/variables/{prompt_variable_id}/values/{id}/
     * @originalName promptRefineryVariablesValuesCreate
     * @duplicate
     * @secure
     */
    promptRefineryVariablesValuesCreate2: (
      promptVariableId: string,
      id: number,
      data: PromptVariableValue,
      params: RequestParams = {},
    ) =>
      this.request<PromptVariableValue, any>({
        path: `/prompt_refinery/variables/${promptVariableId}/values/${id}/`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  rfp = {
    /**
     * No description
     *
     * @tags rfp
     * @name RfpAdminDiffCreate
     * @request POST:/rfp/admin/diff/
     * @secure
     */
    rfpAdminDiffCreate: (data: Diff, params: RequestParams = {}) =>
      this.request<Diff, any>({
        path: `/rfp/admin/diff/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpCategoryList
     * @request GET:/rfp/category/
     * @secure
     */
    rfpCategoryList: (
      query?: {
        /** A search term. */
        search?: string;
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: RFPCategory[];
        },
        any
      >({
        path: `/rfp/category/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpCategoryCreate
     * @request POST:/rfp/category/
     * @secure
     */
    rfpCategoryCreate: (data: RFPCategory, params: RequestParams = {}) =>
      this.request<RFPCategory, any>({
        path: `/rfp/category/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpCategoryUpdate
     * @request PUT:/rfp/category/{id}/
     * @secure
     */
    rfpCategoryUpdate: (id: number, data: RFPCategoryDetails, params: RequestParams = {}) =>
      this.request<RFPCategoryDetails, any>({
        path: `/rfp/category/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpCategoryPartialUpdate
     * @request PATCH:/rfp/category/{id}/
     * @secure
     */
    rfpCategoryPartialUpdate: (id: number, data: RFPCategoryDetails, params: RequestParams = {}) =>
      this.request<RFPCategoryDetails, any>({
        path: `/rfp/category/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description List all comparisons for the user's organization
     *
     * @tags rfp
     * @name RfpComparisonList
     * @request GET:/rfp/comparison/
     * @secure
     */
    rfpComparisonList: (
      query?: {
        /** A search term. */
        search?: string;
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: RFPComparison[];
        },
        any
      >({
        path: `/rfp/comparison/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpComparisonByOrgRead
     * @request GET:/rfp/comparison/by_org/{org_id}/
     * @secure
     */
    rfpComparisonByOrgRead: (
      orgId: string,
      query?: {
        /** A search term. */
        search?: string;
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: RFPComparisonList[];
        },
        any
      >({
        path: `/rfp/comparison/by_org/${orgId}/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpComparisonCreateCreate
     * @request POST:/rfp/comparison/create/
     * @secure
     */
    rfpComparisonCreateCreate: (data: ComparisonCreate, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/comparison/create/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpComparisonStartCreate
     * @request POST:/rfp/comparison/start/
     * @secure
     */
    rfpComparisonStartCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/comparison/start/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpComparisonRead
     * @request GET:/rfp/comparison/{rfp_id}/
     * @secure
     */
    rfpComparisonRead: (rfpId: string, params: RequestParams = {}) =>
      this.request<ComparisonDetail, any>({
        path: `/rfp/comparison/${rfpId}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpComparisonUpdate
     * @request PUT:/rfp/comparison/{rfp_id}/
     * @secure
     */
    rfpComparisonUpdate: (rfpId: string, data: ComparisonDetail, params: RequestParams = {}) =>
      this.request<ComparisonDetail, any>({
        path: `/rfp/comparison/${rfpId}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpComparisonPartialUpdate
     * @request PATCH:/rfp/comparison/{rfp_id}/
     * @secure
     */
    rfpComparisonPartialUpdate: (rfpId: string, data: ComparisonDetail, params: RequestParams = {}) =>
      this.request<ComparisonDetail, any>({
        path: `/rfp/comparison/${rfpId}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Send an email to support with feedback on a comparison.
     *
     * @tags rfp
     * @name RfpComparisonFeedbackCreate
     * @request POST:/rfp/comparison/{rfp_id}/feedback/
     * @secure
     */
    rfpComparisonFeedbackCreate: (rfpId: string, data: ComparisonFeedback, params: RequestParams = {}) =>
      this.request<ComparisonFeedback, any>({
        path: `/rfp/comparison/${rfpId}/feedback/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryCoverLetterList
     * @request GET:/rfp/content_library/cover_letter/
     * @secure
     */
    rfpContentLibraryCoverLetterList: (
      query?: {
        /** A search term. */
        search?: string;
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: CoverLetterList[];
        },
        any
      >({
        path: `/rfp/content_library/cover_letter/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryCoverLetterCreate
     * @request POST:/rfp/content_library/cover_letter/
     * @secure
     */
    rfpContentLibraryCoverLetterCreate: (data: CoverLetterList, params: RequestParams = {}) =>
      this.request<CoverLetterList, any>({
        path: `/rfp/content_library/cover_letter/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryCoverLetterCreateCreate
     * @request POST:/rfp/content_library/cover_letter/create/
     * @secure
     */
    rfpContentLibraryCoverLetterCreateCreate: (params: RequestParams = {}) =>
      this.request<CoverLetterDetails, any>({
        path: `/rfp/content_library/cover_letter/create/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryCoverLetterRead
     * @request GET:/rfp/content_library/cover_letter/{id}/
     * @secure
     */
    rfpContentLibraryCoverLetterRead: (id: string, params: RequestParams = {}) =>
      this.request<CoverLetterDetails, any>({
        path: `/rfp/content_library/cover_letter/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryCoverLetterUpdate
     * @request PUT:/rfp/content_library/cover_letter/{id}/
     * @secure
     */
    rfpContentLibraryCoverLetterUpdate: (id: string, data: CoverLetterDetails, params: RequestParams = {}) =>
      this.request<CoverLetterDetails, any>({
        path: `/rfp/content_library/cover_letter/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryCoverLetterPartialUpdate
     * @request PATCH:/rfp/content_library/cover_letter/{id}/
     * @secure
     */
    rfpContentLibraryCoverLetterPartialUpdate: (id: string, data: CoverLetterDetails, params: RequestParams = {}) =>
      this.request<CoverLetterDetails, any>({
        path: `/rfp/content_library/cover_letter/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryCoverLetterDelete
     * @request DELETE:/rfp/content_library/cover_letter/{id}/
     * @secure
     */
    rfpContentLibraryCoverLetterDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/content_library/cover_letter/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryCoverLetterAssociationCreate
     * @request POST:/rfp/content_library/cover_letter/{id}/association/{proposal_id}/
     * @secure
     */
    rfpContentLibraryCoverLetterAssociationCreate: (id: string, proposalId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/content_library/cover_letter/${id}/association/${proposalId}/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryCoverLetterAssociationDelete
     * @request DELETE:/rfp/content_library/cover_letter/{id}/association/{proposal_id}/
     * @secure
     */
    rfpContentLibraryCoverLetterAssociationDelete: (id: string, proposalId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/content_library/cover_letter/${id}/association/${proposalId}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryCoverLetterGenerateCreate
     * @request POST:/rfp/content_library/cover_letter/{id}/generate/{proposal_id}/{requirement_id}/
     * @secure
     */
    rfpContentLibraryCoverLetterGenerateCreate: (
      id: string,
      proposalId: string,
      requirementId: string,
      data: CoverLetterGenerate,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/rfp/content_library/cover_letter/${id}/generate/${proposalId}/${requirementId}/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryOverviewList
     * @request GET:/rfp/content_library/overview/
     * @secure
     */
    rfpContentLibraryOverviewList: (
      query?: {
        /** A search term. */
        search?: string;
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: OverviewList[];
        },
        any
      >({
        path: `/rfp/content_library/overview/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryOverviewCreate
     * @request POST:/rfp/content_library/overview/
     * @secure
     */
    rfpContentLibraryOverviewCreate: (data: OverviewList, params: RequestParams = {}) =>
      this.request<OverviewList, any>({
        path: `/rfp/content_library/overview/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryOverviewCreateCreate
     * @request POST:/rfp/content_library/overview/create/
     * @secure
     */
    rfpContentLibraryOverviewCreateCreate: (params: RequestParams = {}) =>
      this.request<OverviewDetails, any>({
        path: `/rfp/content_library/overview/create/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryOverviewRead
     * @request GET:/rfp/content_library/overview/{id}/
     * @secure
     */
    rfpContentLibraryOverviewRead: (id: string, params: RequestParams = {}) =>
      this.request<OverviewDetails, any>({
        path: `/rfp/content_library/overview/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryOverviewUpdate
     * @request PUT:/rfp/content_library/overview/{id}/
     * @secure
     */
    rfpContentLibraryOverviewUpdate: (id: string, data: OverviewDetails, params: RequestParams = {}) =>
      this.request<OverviewDetails, any>({
        path: `/rfp/content_library/overview/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryOverviewPartialUpdate
     * @request PATCH:/rfp/content_library/overview/{id}/
     * @secure
     */
    rfpContentLibraryOverviewPartialUpdate: (id: string, data: OverviewDetails, params: RequestParams = {}) =>
      this.request<OverviewDetails, any>({
        path: `/rfp/content_library/overview/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryOverviewDelete
     * @request DELETE:/rfp/content_library/overview/{id}/
     * @secure
     */
    rfpContentLibraryOverviewDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/content_library/overview/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryOverviewAssociationCreate
     * @request POST:/rfp/content_library/overview/{id}/association/{proposal_id}/
     * @secure
     */
    rfpContentLibraryOverviewAssociationCreate: (id: string, proposalId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/content_library/overview/${id}/association/${proposalId}/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryOverviewAssociationDelete
     * @request DELETE:/rfp/content_library/overview/{id}/association/{proposal_id}/
     * @secure
     */
    rfpContentLibraryOverviewAssociationDelete: (id: string, proposalId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/content_library/overview/${id}/association/${proposalId}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryOverviewGenerateCreate
     * @request POST:/rfp/content_library/overview/{id}/generate/{proposal_id}/{requirement_id}/
     * @secure
     */
    rfpContentLibraryOverviewGenerateCreate: (
      id: string,
      proposalId: string,
      requirementId: string,
      data: OverviewGenerate,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/rfp/content_library/overview/${id}/generate/${proposalId}/${requirementId}/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryPastProposalAssociationCreate
     * @request POST:/rfp/content_library/past_proposal/{id}/association/{proposal_id}/
     * @secure
     */
    rfpContentLibraryPastProposalAssociationCreate: (id: string, proposalId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/content_library/past_proposal/${id}/association/${proposalId}/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryPastProposalAssociationDelete
     * @request DELETE:/rfp/content_library/past_proposal/{id}/association/{proposal_id}/
     * @secure
     */
    rfpContentLibraryPastProposalAssociationDelete: (id: string, proposalId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/content_library/past_proposal/${id}/association/${proposalId}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryPersonList
     * @request GET:/rfp/content_library/person/
     * @secure
     */
    rfpContentLibraryPersonList: (
      query?: {
        /** A search term. */
        search?: string;
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: PersonList[];
        },
        any
      >({
        path: `/rfp/content_library/person/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryPersonCreate
     * @request POST:/rfp/content_library/person/
     * @secure
     */
    rfpContentLibraryPersonCreate: (data: PersonList, params: RequestParams = {}) =>
      this.request<PersonList, any>({
        path: `/rfp/content_library/person/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryPersonCreateCreate
     * @request POST:/rfp/content_library/person/create/
     * @secure
     */
    rfpContentLibraryPersonCreateCreate: (params: RequestParams = {}) =>
      this.request<PersonDetails, any>({
        path: `/rfp/content_library/person/create/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryPersonRead
     * @request GET:/rfp/content_library/person/{id}/
     * @secure
     */
    rfpContentLibraryPersonRead: (id: string, params: RequestParams = {}) =>
      this.request<PersonDetails, any>({
        path: `/rfp/content_library/person/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryPersonUpdate
     * @request PUT:/rfp/content_library/person/{id}/
     * @secure
     */
    rfpContentLibraryPersonUpdate: (id: string, data: PersonDetails, params: RequestParams = {}) =>
      this.request<PersonDetails, any>({
        path: `/rfp/content_library/person/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryPersonPartialUpdate
     * @request PATCH:/rfp/content_library/person/{id}/
     * @secure
     */
    rfpContentLibraryPersonPartialUpdate: (id: string, data: PersonDetails, params: RequestParams = {}) =>
      this.request<PersonDetails, any>({
        path: `/rfp/content_library/person/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryPersonDelete
     * @request DELETE:/rfp/content_library/person/{id}/
     * @secure
     */
    rfpContentLibraryPersonDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/content_library/person/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryPersonAssociationCreate
     * @request POST:/rfp/content_library/person/{id}/association/{proposal_id}/
     * @secure
     */
    rfpContentLibraryPersonAssociationCreate: (id: string, proposalId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/content_library/person/${id}/association/${proposalId}/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryPersonAssociationDelete
     * @request DELETE:/rfp/content_library/person/{id}/association/{proposal_id}/
     * @secure
     */
    rfpContentLibraryPersonAssociationDelete: (id: string, proposalId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/content_library/person/${id}/association/${proposalId}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryPersonGenerateCreate
     * @request POST:/rfp/content_library/person/{id}/generate/{proposal_id}/{requirement_id}/
     * @secure
     */
    rfpContentLibraryPersonGenerateCreate: (
      id: string,
      proposalId: string,
      requirementId: string,
      data: PersonGenerate,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/rfp/content_library/person/${id}/generate/${proposalId}/${requirementId}/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryProjectList
     * @request GET:/rfp/content_library/project/
     * @secure
     */
    rfpContentLibraryProjectList: (
      query?: {
        /** A search term. */
        search?: string;
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: ProjectList[];
        },
        any
      >({
        path: `/rfp/content_library/project/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryProjectCreate
     * @request POST:/rfp/content_library/project/
     * @secure
     */
    rfpContentLibraryProjectCreate: (data: ProjectList, params: RequestParams = {}) =>
      this.request<ProjectList, any>({
        path: `/rfp/content_library/project/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryProjectCreateCreate
     * @request POST:/rfp/content_library/project/create/
     * @secure
     */
    rfpContentLibraryProjectCreateCreate: (params: RequestParams = {}) =>
      this.request<ProjectDetails, any>({
        path: `/rfp/content_library/project/create/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryProjectRead
     * @request GET:/rfp/content_library/project/{id}/
     * @secure
     */
    rfpContentLibraryProjectRead: (id: string, params: RequestParams = {}) =>
      this.request<ProjectDetails, any>({
        path: `/rfp/content_library/project/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryProjectUpdate
     * @request PUT:/rfp/content_library/project/{id}/
     * @secure
     */
    rfpContentLibraryProjectUpdate: (id: string, data: ProjectDetails, params: RequestParams = {}) =>
      this.request<ProjectDetails, any>({
        path: `/rfp/content_library/project/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryProjectPartialUpdate
     * @request PATCH:/rfp/content_library/project/{id}/
     * @secure
     */
    rfpContentLibraryProjectPartialUpdate: (id: string, data: ProjectDetails, params: RequestParams = {}) =>
      this.request<ProjectDetails, any>({
        path: `/rfp/content_library/project/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryProjectDelete
     * @request DELETE:/rfp/content_library/project/{id}/
     * @secure
     */
    rfpContentLibraryProjectDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/content_library/project/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryProjectAssociationCreate
     * @request POST:/rfp/content_library/project/{id}/association/{proposal_id}/
     * @secure
     */
    rfpContentLibraryProjectAssociationCreate: (id: string, proposalId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/content_library/project/${id}/association/${proposalId}/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryProjectAssociationDelete
     * @request DELETE:/rfp/content_library/project/{id}/association/{proposal_id}/
     * @secure
     */
    rfpContentLibraryProjectAssociationDelete: (id: string, proposalId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/content_library/project/${id}/association/${proposalId}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibraryProjectGenerateCreate
     * @request POST:/rfp/content_library/project/{id}/generate/{proposal_id}/{requirement_id}/
     * @secure
     */
    rfpContentLibraryProjectGenerateCreate: (
      id: string,
      proposalId: string,
      requirementId: string,
      data: ProjectGenerate,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/rfp/content_library/project/${id}/generate/${proposalId}/${requirementId}/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibrarySubcontractorList
     * @request GET:/rfp/content_library/subcontractor/
     * @secure
     */
    rfpContentLibrarySubcontractorList: (
      query?: {
        /** A search term. */
        search?: string;
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: SubcontractorList[];
        },
        any
      >({
        path: `/rfp/content_library/subcontractor/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibrarySubcontractorCreate
     * @request POST:/rfp/content_library/subcontractor/
     * @secure
     */
    rfpContentLibrarySubcontractorCreate: (data: SubcontractorList, params: RequestParams = {}) =>
      this.request<SubcontractorList, any>({
        path: `/rfp/content_library/subcontractor/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibrarySubcontractorCreateCreate
     * @request POST:/rfp/content_library/subcontractor/create/
     * @secure
     */
    rfpContentLibrarySubcontractorCreateCreate: (params: RequestParams = {}) =>
      this.request<SubcontractorDetails, any>({
        path: `/rfp/content_library/subcontractor/create/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibrarySubcontractorRead
     * @request GET:/rfp/content_library/subcontractor/{id}/
     * @secure
     */
    rfpContentLibrarySubcontractorRead: (id: string, params: RequestParams = {}) =>
      this.request<SubcontractorDetails, any>({
        path: `/rfp/content_library/subcontractor/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibrarySubcontractorUpdate
     * @request PUT:/rfp/content_library/subcontractor/{id}/
     * @secure
     */
    rfpContentLibrarySubcontractorUpdate: (id: string, data: SubcontractorDetails, params: RequestParams = {}) =>
      this.request<SubcontractorDetails, any>({
        path: `/rfp/content_library/subcontractor/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibrarySubcontractorPartialUpdate
     * @request PATCH:/rfp/content_library/subcontractor/{id}/
     * @secure
     */
    rfpContentLibrarySubcontractorPartialUpdate: (id: string, data: SubcontractorDetails, params: RequestParams = {}) =>
      this.request<SubcontractorDetails, any>({
        path: `/rfp/content_library/subcontractor/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibrarySubcontractorDelete
     * @request DELETE:/rfp/content_library/subcontractor/{id}/
     * @secure
     */
    rfpContentLibrarySubcontractorDelete: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/content_library/subcontractor/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibrarySubcontractorAssociationCreate
     * @request POST:/rfp/content_library/subcontractor/{id}/association/{proposal_id}/
     * @secure
     */
    rfpContentLibrarySubcontractorAssociationCreate: (id: string, proposalId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/content_library/subcontractor/${id}/association/${proposalId}/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibrarySubcontractorAssociationDelete
     * @request DELETE:/rfp/content_library/subcontractor/{id}/association/{proposal_id}/
     * @secure
     */
    rfpContentLibrarySubcontractorAssociationDelete: (id: string, proposalId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/content_library/subcontractor/${id}/association/${proposalId}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpContentLibrarySubcontractorGenerateCreate
     * @request POST:/rfp/content_library/subcontractor/{id}/generate/{proposal_id}/{requirement_id}/
     * @secure
     */
    rfpContentLibrarySubcontractorGenerateCreate: (
      id: string,
      proposalId: string,
      requirementId: string,
      data: SubcontractorGenerate,
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/rfp/content_library/subcontractor/${id}/generate/${proposalId}/${requirementId}/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpEventsCreate
     * @request POST:/rfp/events/
     * @secure
     */
    rfpEventsCreate: (
      data: {
        /** Event object */
        event: object;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/rfp/events/`,
        method: "POST",
        body: data,
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpFinalCheckList
     * @request GET:/rfp/final-check/
     * @secure
     */
    rfpFinalCheckList: (
      query?: {
        /** A search term. */
        search?: string;
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: FinalCheckList[];
        },
        any
      >({
        path: `/rfp/final-check/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpFinalCheckCreateCreate
     * @request POST:/rfp/final-check/create/
     * @secure
     */
    rfpFinalCheckCreateCreate: (data: FinalCheckCreate, params: RequestParams = {}) =>
      this.request<
        {
          id: string;
          proposal_upload_url?: string;
          rfp_upload_url?: string;
        },
        any
      >({
        path: `/rfp/final-check/create/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpFinalCheckRead
     * @request GET:/rfp/final-check/{id}/
     * @secure
     */
    rfpFinalCheckRead: (id: string, params: RequestParams = {}) =>
      this.request<FinalCheckDetails, any>({
        path: `/rfp/final-check/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpFinalCheckUploadedCreate
     * @request POST:/rfp/final-check/{id}/uploaded/
     * @secure
     */
    rfpFinalCheckUploadedCreate: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/final-check/${id}/uploaded/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpInternalProposalRead
     * @request GET:/rfp/internal/proposal/{id}/
     * @secure
     */
    rfpInternalProposalRead: (id: string, params: RequestParams = {}) =>
      this.request<InternalProposalDetails, any>({
        path: `/rfp/internal/proposal/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpLibSearchCreate
     * @request POST:/rfp/lib/search/
     * @secure
     */
    rfpLibSearchCreate: (data: ProposalSearch, params: RequestParams = {}) =>
      this.request<
        {
          results?: {
            text?: string;
            similarity?: number;
            topic?: string;
            debug?: object;
          }[];
        },
        any
      >({
        path: `/rfp/lib/search/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpLibTestSimilarityCreate
     * @request POST:/rfp/lib/test-similarity/
     * @secure
     */
    rfpLibTestSimilarityCreate: (data: LibTestSimilarityInput, params: RequestParams = {}) =>
      this.request<
        {
          similarity?: number;
        },
        any
      >({
        path: `/rfp/lib/test-similarity/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalList
     * @request GET:/rfp/proposal/
     * @secure
     */
    rfpProposalList: (
      query?: {
        /** A search term. */
        search?: string;
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: ProposalListCreate[];
        },
        any
      >({
        path: `/rfp/proposal/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalCreate
     * @request POST:/rfp/proposal/
     * @secure
     */
    rfpProposalCreate: (data: ProposalListCreate, params: RequestParams = {}) =>
      this.request<ProposalListCreate, any>({
        path: `/rfp/proposal/`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description List all unpulbished proposals across all orgs
     *
     * @tags rfp
     * @name RfpProposalAdminList
     * @request GET:/rfp/proposal/admin/
     * @secure
     */
    rfpProposalAdminList: (
      query?: {
        /** A search term. */
        search?: string;
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: ProposalAdminDetail[];
        },
        any
      >({
        path: `/rfp/proposal/admin/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Return details on the user's eligibility to create new proposals.
     *
     * @tags rfp
     * @name RfpProposalEligibilityCreate
     * @request POST:/rfp/proposal/eligibility/
     * @secure
     */
    rfpProposalEligibilityCreate: (data: ProposalEligibility, params: RequestParams = {}) =>
      this.request<
        {
          eligibility?: "demo_required" | "trial" | "trial_expired" | "allowed";
        },
        any
      >({
        path: `/rfp/proposal/eligibility/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalInactiveList
     * @request GET:/rfp/proposal/inactive/
     * @secure
     */
    rfpProposalInactiveList: (
      query?: {
        /** A search term. */
        search?: string;
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: ProposalListCreate[];
        },
        any
      >({
        path: `/rfp/proposal/inactive/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalInactiveCreate
     * @request POST:/rfp/proposal/inactive/
     * @secure
     */
    rfpProposalInactiveCreate: (data: ProposalListCreate, params: RequestParams = {}) =>
      this.request<ProposalListCreate, any>({
        path: `/rfp/proposal/inactive/`,
        method: "POST",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalPastCreate
     * @request POST:/rfp/proposal/past/
     * @secure
     */
    rfpProposalPastCreate: (data: PastProposalCreate, params: RequestParams = {}) =>
      this.request<
        {
          id: string;
          upload_url?: string;
          topic?: string;
        },
        any
      >({
        path: `/rfp/proposal/past/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalPastListList
     * @request GET:/rfp/proposal/past/list/
     * @secure
     */
    rfpProposalPastListList: (
      query?: {
        /** A search term. */
        search?: string;
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: PastProposalList[];
        },
        any
      >({
        path: `/rfp/proposal/past/list/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalPastRead
     * @request GET:/rfp/proposal/past/{id}/
     * @secure
     */
    rfpProposalPastRead: (id: string, params: RequestParams = {}) =>
      this.request<PastProposalDetail, any>({
        path: `/rfp/proposal/past/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalPastUpdate
     * @request PUT:/rfp/proposal/past/{id}/
     * @secure
     */
    rfpProposalPastUpdate: (id: string, data: PastProposalDetail, params: RequestParams = {}) =>
      this.request<PastProposalDetail, any>({
        path: `/rfp/proposal/past/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalPastPartialUpdate
     * @request PATCH:/rfp/proposal/past/{id}/
     * @secure
     */
    rfpProposalPastPartialUpdate: (id: string, data: PastProposalDetail, params: RequestParams = {}) =>
      this.request<PastProposalDetail, any>({
        path: `/rfp/proposal/past/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description An empty post request indicates that the file for the past proposal has been uploaded
     *
     * @tags rfp
     * @name RfpProposalPastConfirmCreate
     * @request POST:/rfp/proposal/past/{id}/confirm/
     * @secure
     */
    rfpProposalPastConfirmCreate: (id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/proposal/past/${id}/confirm/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalSnapshotCreate
     * @request POST:/rfp/proposal/snapshot/{proposal_id}/
     * @secure
     */
    rfpProposalSnapshotCreate: (proposalId: string, data: ProposalMetadataSnapshot, params: RequestParams = {}) =>
      this.request<ProposalMetadataSnapshot, any>({
        path: `/rfp/proposal/snapshot/${proposalId}/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalRead
     * @request GET:/rfp/proposal/{proposal_id}/
     * @secure
     */
    rfpProposalRead: (proposalId: string, params: RequestParams = {}) =>
      this.request<ProposalDetail, any>({
        path: `/rfp/proposal/${proposalId}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalUpdate
     * @request PUT:/rfp/proposal/{proposal_id}/
     * @secure
     */
    rfpProposalUpdate: (proposalId: string, data: ProposalDetail, params: RequestParams = {}) =>
      this.request<ProposalDetail, any>({
        path: `/rfp/proposal/${proposalId}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalPartialUpdate
     * @request PATCH:/rfp/proposal/{proposal_id}/
     * @secure
     */
    rfpProposalPartialUpdate: (proposalId: string, data: ProposalDetail, params: RequestParams = {}) =>
      this.request<ProposalDetail, any>({
        path: `/rfp/proposal/${proposalId}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalDelete
     * @request DELETE:/rfp/proposal/{proposal_id}/
     * @secure
     */
    rfpProposalDelete: (proposalId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/proposal/${proposalId}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalAddRequirementCreate
     * @request POST:/rfp/proposal/{proposal_id}/add_requirement/
     * @secure
     */
    rfpProposalAddRequirementCreate: (proposalId: string, data: RequirementAdd, params: RequestParams = {}) =>
      this.request<RequirementAdd, any>({
        path: `/rfp/proposal/${proposalId}/add_requirement/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalAiActionCreate
     * @request POST:/rfp/proposal/{proposal_id}/ai_action/
     * @secure
     */
    rfpProposalAiActionCreate: (proposalId: string, data: AIAction, params: RequestParams = {}) =>
      this.request<AIAction, any>({
        path: `/rfp/proposal/${proposalId}/ai_action/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalAuthList
     * @request GET:/rfp/proposal/{proposal_id}/auth/
     * @secure
     */
    rfpProposalAuthList: (proposalId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/proposal/${proposalId}/auth/`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalExportList
     * @request GET:/rfp/proposal/{proposal_id}/export/
     * @secure
     */
    rfpProposalExportList: (proposalId: string, params: RequestParams = {}) =>
      this.request<
        {
          download_url?: string;
        },
        any
      >({
        path: `/rfp/proposal/${proposalId}/export/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalInsertRequirementCreate
     * @request POST:/rfp/proposal/{proposal_id}/insert_requirement/
     * @secure
     */
    rfpProposalInsertRequirementCreate: (proposalId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/proposal/${proposalId}/insert_requirement/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalPastList
     * @request GET:/rfp/proposal/{proposal_id}/past/
     * @secure
     */
    rfpProposalPastList: (
      proposalId: string,
      query?: {
        /** A search term. */
        search?: string;
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: PastProposalList[];
        },
        any
      >({
        path: `/rfp/proposal/${proposalId}/past/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalPastRead2
     * @request GET:/rfp/proposal/{proposal_id}/past/{id}/
     * @originalName rfpProposalPastRead
     * @duplicate
     * @secure
     */
    rfpProposalPastRead2: (proposalId: string, id: string, params: RequestParams = {}) =>
      this.request<PastProposalDetail, any>({
        path: `/rfp/proposal/${proposalId}/past/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalPastUpdate2
     * @request PUT:/rfp/proposal/{proposal_id}/past/{id}/
     * @originalName rfpProposalPastUpdate
     * @duplicate
     * @secure
     */
    rfpProposalPastUpdate2: (proposalId: string, id: string, data: PastProposalDetail, params: RequestParams = {}) =>
      this.request<PastProposalDetail, any>({
        path: `/rfp/proposal/${proposalId}/past/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalPastPartialUpdate2
     * @request PATCH:/rfp/proposal/{proposal_id}/past/{id}/
     * @originalName rfpProposalPastPartialUpdate
     * @duplicate
     * @secure
     */
    rfpProposalPastPartialUpdate2: (
      proposalId: string,
      id: string,
      data: PastProposalDetail,
      params: RequestParams = {},
    ) =>
      this.request<PastProposalDetail, any>({
        path: `/rfp/proposal/${proposalId}/past/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalRefreshComplianceCreate
     * @request POST:/rfp/proposal/{proposal_id}/refresh_compliance/
     * @secure
     */
    rfpProposalRefreshComplianceCreate: (proposalId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/proposal/${proposalId}/refresh_compliance/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalRelevantContextCreate
     * @request POST:/rfp/proposal/{proposal_id}/relevant_context/
     * @secure
     */
    rfpProposalRelevantContextCreate: (
      proposalId: string,
      data: RequirementGetRelevantContext,
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** Relevant context from past proposal */
          relevant_context?: string;
        },
        any
      >({
        path: `/rfp/proposal/${proposalId}/relevant_context/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalRequestPermissionCreate
     * @request POST:/rfp/proposal/{proposal_id}/request_permission/
     * @secure
     */
    rfpProposalRequestPermissionCreate: (proposalId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/proposal/${proposalId}/request_permission/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalRequirementGenerateCreate
     * @request POST:/rfp/proposal/{proposal_id}/requirement/{requirement_id}/generate/
     * @secure
     */
    rfpProposalRequirementGenerateCreate: (
      proposalId: string,
      requirementId: string,
      data: RequirementGenerate,
      params: RequestParams = {},
    ) =>
      this.request<RequirementGenerate, any>({
        path: `/rfp/proposal/${proposalId}/requirement/${requirementId}/generate/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalRequirementGenerateDelete
     * @request DELETE:/rfp/proposal/{proposal_id}/requirement/{requirement_id}/generate/
     * @secure
     */
    rfpProposalRequirementGenerateDelete: (proposalId: string, requirementId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/proposal/${proposalId}/requirement/${requirementId}/generate/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalRequirementGeneratePastExperienceCreate
     * @request POST:/rfp/proposal/{proposal_id}/requirement/{requirement_id}/generate_past_experience/
     * @secure
     */
    rfpProposalRequirementGeneratePastExperienceCreate: (
      proposalId: string,
      requirementId: string,
      data: RequirementRelevantExperienceGenerate,
      params: RequestParams = {},
    ) =>
      this.request<RequirementRelevantExperienceGenerate, any>({
        path: `/rfp/proposal/${proposalId}/requirement/${requirementId}/generate_past_experience/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalRequirementStatusList
     * @request GET:/rfp/proposal/{proposal_id}/requirement_status/
     * @secure
     */
    rfpProposalRequirementStatusList: (proposalId: string, params: RequestParams = {}) =>
      this.request<RequirementStatus[], any>({
        path: `/rfp/proposal/${proposalId}/requirement_status/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalRequirementStatusCreate
     * @request POST:/rfp/proposal/{proposal_id}/requirement_status/
     * @secure
     */
    rfpProposalRequirementStatusCreate: (proposalId: string, data: RequirementStatus, params: RequestParams = {}) =>
      this.request<RequirementStatus, any>({
        path: `/rfp/proposal/${proposalId}/requirement_status/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalUsersCreate
     * @request POST:/rfp/proposal/{proposal_id}/users/
     * @secure
     */
    rfpProposalUsersCreate: (proposalId: string, data: ProposalGrant, params: RequestParams = {}) =>
      this.request<
        {
          message?: string;
        },
        any
      >({
        path: `/rfp/proposal/${proposalId}/users/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpProposalUsersDelete
     * @request DELETE:/rfp/proposal/{proposal_id}/users/{user_id}/
     * @secure
     */
    rfpProposalUsersDelete: (proposalId: string, userId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/proposal/${proposalId}/users/${userId}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpLabelList
     * @request GET:/rfp/rfp-label/
     * @secure
     */
    rfpRfpLabelList: (
      query?: {
        /** A search term. */
        search?: string;
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: RFPLabel[];
        },
        any
      >({
        path: `/rfp/rfp-label/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpLabelCreate
     * @request POST:/rfp/rfp-label/
     * @secure
     */
    rfpRfpLabelCreate: (data: RFPLabel, params: RequestParams = {}) =>
      this.request<RFPLabel, any>({
        path: `/rfp/rfp-label/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpCreate
     * @request POST:/rfp/rfp/
     * @secure
     */
    rfpRfpCreate: (data: RFPCreate, params: RequestParams = {}) =>
      this.request<
        {
          id: string;
          upload_url?: string;
        },
        any
      >({
        path: `/rfp/rfp/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpCsvCreate
     * @request POST:/rfp/rfp/csv/
     * @secure
     */
    rfpRfpCsvCreate: (data: RFPCSVUpload, params: RequestParams = {}) =>
      this.request<
        {
          id: string;
        },
        any
      >({
        path: `/rfp/rfp/csv/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpListList
     * @request GET:/rfp/rfp/list/
     * @secure
     */
    rfpRfpListList: (
      query?: {
        /** A search term. */
        search?: string;
        /** A page number within the paginated result set. */
        page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          count: number;
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: RFPList[];
          /** The current page number. */
          page?: number;
          /** The total number of pages. */
          page_count?: number;
        },
        any
      >({
        path: `/rfp/rfp/list/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpListMatchCreate
     * @request POST:/rfp/rfp/list/{rfp_id}/match/
     * @secure
     */
    rfpRfpListMatchCreate: (rfpId: string, params: RequestParams = {}) =>
      this.request<
        {
          id: number;
        },
        {
          error: string;
        }
      >({
        path: `/rfp/rfp/list/${rfpId}/match/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpRecList
     * @request GET:/rfp/rfp/rec/
     * @secure
     */
    rfpRfpRecList: (
      query?: {
        /** A page number within the paginated result set. */
        page?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          count: number;
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: RFPComparison[];
          /** The current page number. */
          page?: number;
          /** The total number of pages. */
          page_count?: number;
        },
        any
      >({
        path: `/rfp/rfp/rec/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpRecCreate
     * @request POST:/rfp/rfp/rec/
     * @secure
     */
    rfpRfpRecCreate: (data: RFPComparison, params: RequestParams = {}) =>
      this.request<RFPComparison, any>({
        path: `/rfp/rfp/rec/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpRecRead
     * @request GET:/rfp/rfp/rec/{slug}/
     * @secure
     */
    rfpRfpRecRead: (slug: string, params: RequestParams = {}) =>
      this.request<RFPComparisonDetail, any>({
        path: `/rfp/rfp/rec/${slug}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpRead
     * @request GET:/rfp/rfp/{rfp_id}/
     * @secure
     */
    rfpRfpRead: (rfpId: string, params: RequestParams = {}) =>
      this.request<RFPDetail, any>({
        path: `/rfp/rfp/${rfpId}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpUpdate
     * @request PUT:/rfp/rfp/{rfp_id}/
     * @secure
     */
    rfpRfpUpdate: (rfpId: string, data: RFPDetail, params: RequestParams = {}) =>
      this.request<RFPDetail, any>({
        path: `/rfp/rfp/${rfpId}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpPartialUpdate
     * @request PATCH:/rfp/rfp/{rfp_id}/
     * @secure
     */
    rfpRfpPartialUpdate: (rfpId: string, data: RFPDetail, params: RequestParams = {}) =>
      this.request<RFPDetail, any>({
        path: `/rfp/rfp/${rfpId}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpComparisonList
     * @request GET:/rfp/rfp/{rfp_id}/comparison/
     * @secure
     */
    rfpRfpComparisonList: (
      rfpId: string,
      query?: {
        /** A search term. */
        search?: string;
        /** The pagination cursor value. */
        cursor?: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          /** @format uri */
          next?: string | null;
          /** @format uri */
          previous?: string | null;
          results: RFPComparisonList[];
        },
        any
      >({
        path: `/rfp/rfp/${rfpId}/comparison/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpComparisonCreate
     * @request POST:/rfp/rfp/{rfp_id}/comparison/
     * @secure
     */
    rfpRfpComparisonCreate: (
      rfpId: string,
      data: {
        id?: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/rfp/rfp/${rfpId}/comparison/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpFilesList
     * @request GET:/rfp/rfp/{rfp_id}/files/
     * @secure
     */
    rfpRfpFilesList: (rfpId: string, params: RequestParams = {}) =>
      this.request<RFPFile[], any>({
        path: `/rfp/rfp/${rfpId}/files/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpFilesCreate
     * @request POST:/rfp/rfp/{rfp_id}/files/
     * @secure
     */
    rfpRfpFilesCreate: (rfpId: string, data: RFPFile, params: RequestParams = {}) =>
      this.request<RFPFile, any>({
        path: `/rfp/rfp/${rfpId}/files/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpFilesRead
     * @request GET:/rfp/rfp/{rfp_id}/files/{id}/
     * @secure
     */
    rfpRfpFilesRead: (rfpId: string, id: string, params: RequestParams = {}) =>
      this.request<RFPFileDetail, any>({
        path: `/rfp/rfp/${rfpId}/files/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpFilesUpdate
     * @request PUT:/rfp/rfp/{rfp_id}/files/{id}/
     * @secure
     */
    rfpRfpFilesUpdate: (rfpId: string, id: string, data: RFPFileDetail, params: RequestParams = {}) =>
      this.request<RFPFileDetail, any>({
        path: `/rfp/rfp/${rfpId}/files/${id}/`,
        method: "PUT",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpFilesPartialUpdate
     * @request PATCH:/rfp/rfp/{rfp_id}/files/{id}/
     * @secure
     */
    rfpRfpFilesPartialUpdate: (rfpId: string, id: string, data: RFPFileDetail, params: RequestParams = {}) =>
      this.request<RFPFileDetail, any>({
        path: `/rfp/rfp/${rfpId}/files/${id}/`,
        method: "PATCH",
        body: data,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpFilesDelete
     * @request DELETE:/rfp/rfp/{rfp_id}/files/{id}/
     * @secure
     */
    rfpRfpFilesDelete: (rfpId: string, id: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/rfp/${rfpId}/files/${id}/`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpFoiaCreate
     * @request POST:/rfp/rfp/{rfp_id}/foia/
     * @secure
     */
    rfpRfpFoiaCreate: (rfpId: string, params: RequestParams = {}) =>
      this.request<
        {
          job_id?: string;
        },
        any
      >({
        path: `/rfp/rfp/${rfpId}/foia/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpRegenerateProfileCreate
     * @request POST:/rfp/rfp/{rfp_id}/regenerate_profile/
     * @secure
     */
    rfpRfpRegenerateProfileCreate: (rfpId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/rfp/${rfpId}/regenerate_profile/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpUploadedCreate
     * @request POST:/rfp/rfp/{rfp_id}/uploaded/
     * @secure
     */
    rfpRfpUploadedCreate: (rfpId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/rfp/rfp/${rfpId}/uploaded/`,
        method: "POST",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpBlocksList
     * @request GET:/rfp/rfp/{rfp_id}/{page}/blocks/
     * @secure
     */
    rfpRfpBlocksList: (rfpId: string, page: string, params: RequestParams = {}) =>
      this.request<RFPBlock[], any>({
        path: `/rfp/rfp/${rfpId}/${page}/blocks/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpRfpsMarkAsTestDataCreate
     * @request POST:/rfp/rfps/{rfp_id}/mark-as-test-data/
     * @secure
     */
    rfpRfpsMarkAsTestDataCreate: (rfpId: string, params: RequestParams = {}) =>
      this.request<RFPList, any>({
        path: `/rfp/rfps/${rfpId}/mark-as-test-data/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags rfp
     * @name RfpTaskEventsCreate
     * @request POST:/rfp/task-events/
     * @secure
     */
    rfpTaskEventsCreate: (
      data: {
        /** Event object */
        event: object;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/rfp/task-events/`,
        method: "POST",
        body: data,
        secure: true,
        ...params,
      }),
  };
  unsubscribe = {
    /**
     * No description
     *
     * @tags unsubscribe
     * @name UnsubscribeCreate
     * @request POST:/unsubscribe/
     * @secure
     */
    unsubscribeCreate: (data: Unsubscribe, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/unsubscribe/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
}
