import { Plate, PlateContent, useEditorRef } from "@udecode/plate-common";
import {
  ComponentPropsWithoutRef,
  FC,
  SetStateAction,
  useMemo,
  useRef,
  useState,
} from "react";
import { isEmptyContent, OdoCursorData, OdoEditor, OdoValue } from "odo";
import { TooltipProvider } from "components/EditorView/Menus/Tooltip";
import { RemoteCursorOverlay } from "components/cursors/RemoteCursorOverlay";
import {
  CursorCaret,
  CursorSelectionRect,
} from "components/cursors/RemoteCursor";
import { LinkFloatingToolbar } from "components/EditorView/Menus/LinkFloatingToolbar";
import FixedToolbar from "components/EditorView/Menus/FixedToolbar";
import CommentsProvider from "providers/CommentsProvider";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import generatePlugins from "lib/plate/plugins";
import useImageUpload from "api/useImageUpload";
import { toast } from "react-toastify";
import useYjs from "hooks/yjs/useYjs";
import { cn } from "lib/utils";
import { ProposalDetailsProvider } from "providers/ProposalDetailsProvider";
import { ProposalMetadata } from "hooks/useProposalDetails";
import { ProposalDetails } from "types/ProposalDetails";
import { ProposalParticipant } from "types/ProposalParticipant";

interface CollaborativeTextAreaProps extends ComponentPropsWithoutRef<"div"> {
  docId: string;
}

const CollaborativeTextArea: FC<CollaborativeTextAreaProps> = ({
  docId: rawDocId,
  className,
  placeholder,
  ...props
}) => {
  const docId = "collab-" + rawDocId;
  const containerRef = useRef<HTMLDivElement>(null);
  const currentUser = useAuthenticatedUser();
  const { uploadImage } = useImageUpload(docId);

  const plugins = useMemo(() => {
    return generatePlugins(
      docId,
      false,
      currentUser,
      uploadImage,
      (content: string) => {
        toast.error(content, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }
    );
  }, [currentUser, docId, uploadImage]);

  return (
    <div
      ref={containerRef}
      className={cn(
        "border rounded-md overflow-hidden relative flex flex-col",
        className
      )}
      {...props}
    >
      <Plate<OdoValue, OdoEditor> plugins={plugins} id={docId}>
        <CommentsProvider>
          <TooltipProvider>
            <FixedToolbar aiEnabled={false} commentsEnabled={false} />
            <CollaborativeTextAreaContent
              docId={docId}
              containerRef={containerRef}
              placeholder={placeholder}
            />
          </TooltipProvider>
        </CommentsProvider>
      </Plate>
    </div>
  );
};

interface CollaborativeTextAreaContentProps {
  docId: string;
  containerRef: React.RefObject<HTMLDivElement>;
  placeholder?: string;
}

const CollaborativeTextAreaContent: FC<CollaborativeTextAreaContentProps> = ({
  docId,
  containerRef,
  placeholder,
}) => {
  useYjs(docId);
  const editor = useEditorRef();
  const isEmpty = isEmptyContent(editor.children);
  const [isFocused, setIsFocused] = useState(false);

  return (
    <PlateContent
      className="[&>*:first-child]:pt-0 focus:outline-none p-2m flex flex-col grow"
      id={docId}
      onFocus={() => {
        setIsFocused(true);
      }}
      onBlur={() => {
        setIsFocused(false);
      }}
      renderEditable={(editable) => {
        return (
          <>
            <div
              className={cn(
                "grow flex flex-col overflow-y-scroll",
                isFocused || !isEmpty ? "opacity-100" : "opacity-0"
              )}
            >
              {editable}
            </div>
            <RemoteCursorOverlay<OdoCursorData>
              className="pt-3xl"
              onRenderCaret={CursorCaret}
              onRenderSelectionRect={CursorSelectionRect}
              containerRef={containerRef}
            />
            <LinkFloatingToolbar />
            {isEmpty && !isFocused && (
              <div className="absolute top-[54px] left-2m text-placeholder pointer-events-none">
                {placeholder}
              </div>
            )}
          </>
        );
      }}
    />
  );
};

export default CollaborativeTextArea;
