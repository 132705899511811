import NavigationView from "components/common/NavigationView";
import FinalCheckChooseDocsForm from "components/final-check/FinalCheckChooseDocsForm";
import useFileHash from "hooks/useFileHash";
import { useApiClient } from "providers/ApiClientProvider";
import { useOptionalAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { message_from_exception } from "utils";
import MessageView from "components/common/containers/MessageView";
import { AxiosError } from "axios";
import Rows from "components/common/containers/Rows";
import LoadableView from "components/common/containers/LoadableView";
import usePaginatedData from "hooks/usePaginatedData";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import Columns from "components/common/containers/Columns";
import Spacer from "components/common/containers/Spacer";
import Button from "components/common/Button";
import { format_datetime } from "lib/utils";
import Overlay from "components/common/containers/overlays/Overlay";

const FinalCheckRoute = () => {
  const apiClient = useApiClient();
  const authenticatedUser = useOptionalAuthenticatedUser();
  const navigate = useNavigate();
  const [finalChecks, , paginatedData] = usePaginatedData({
    endpoint: apiClient.rfp.rfpFinalCheckList,
    map: (fc) => ({
      id: fc.id!,
      name: fc.name!,
      created: fc.created!,
    }),
  });
  const [startingFinalCheck, setStartingFinalCheck] = useState(false);

  if (!authenticatedUser) {
    return <FinalCheckCreateForm />;
  }

  return (
    <NavigationView selected="final-check">
      <Rows className="grow gap-lg">
        <Columns className="flex mb-md shrink-0 items-center">
          <h1 className="text-2xl font-semibold w-full mb-md">Final Checks</h1>
          <Spacer />
          <Button
            text="Start new final check"
            variant="solid"
            className="shrink-0"
            icon="plus"
            onClick={() => setStartingFinalCheck(true)}
          />
        </Columns>
        <PaginatedTableView
          results={finalChecks}
          paginatedData={paginatedData}
          columns={[{ name: "Name" }, { name: "Created", size: "min" }]}
          rowSeparators={true}
          rowHeight={84}
          renderRow={(fc, Cell, Row) => (
            <Row key={fc.id}>
              <Cell>{fc.name}</Cell>
              <Cell>{format_datetime(fc.created)}</Cell>
            </Row>
          )}
          onSelect={(fc) => {
            navigate(`/final-check/${fc.id}/`);
          }}
        />
      </Rows>
      {startingFinalCheck && (
        <Overlay onClose={() => setStartingFinalCheck(false)}>
          <FinalCheckCreateForm inOverlay={true} />
        </Overlay>
      )}
    </NavigationView>
  );
};

interface FinalCheckCreateFormProps {
  inOverlay?: boolean;
}

const FinalCheckCreateForm: FC<FinalCheckCreateFormProps> = ({ inOverlay }) => {
  const authenticatedUser = useOptionalAuthenticatedUser();
  const apiClient = useApiClient();
  const navigate = useNavigate();
  const hashFile = useFileHash();
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [directToLogin, setDirectToLogin] = useState(false);

  const handleSubmit = async (rfp: File, proposal: File, email?: string) => {
    try {
      setUploading(true);
      setError(null);
      const proposalName = proposal.name.split(".").slice(0, -1).join(".");
      const rfpHash = await hashFile(rfp);
      const proposalHash = await hashFile(proposal);

      const response = await apiClient.rfp.rfpFinalCheckCreateCreate({
        rfp_hash: rfpHash,
        proposal_hash: proposalHash,
        name: proposalName,
        email: email ? email : undefined,
      });

      const finalCheckId = response.data.id!;

      let uploaded = false;
      if (response.data.rfp_upload_url) {
        // We have to upload the file to this url
        await fetch(response.data.rfp_upload_url, {
          method: "PUT",
          body: rfp,
        });
        uploaded = true;
      }

      if (response.data.proposal_upload_url) {
        // We have to upload the file to this url
        await fetch(response.data.proposal_upload_url, {
          method: "PUT",
          body: proposal,
        });
        uploaded = true;
      }

      if (uploaded) {
        // We have to tell the server that we have uploaded the files
        await apiClient.rfp.rfpFinalCheckUploadedCreate(finalCheckId);
      }

      navigate(`/final-check/${finalCheckId}/`);
    } catch (e) {
      if (e instanceof AxiosError && e.response?.status === 409) {
        setDirectToLogin(true);
      }
      setError(message_from_exception(e));
    } finally {
      setUploading(false);
    }
  };

  const content = (
    <MessageView
      icon="square-check"
      title="Final Check"
      variant={inOverlay ? "DEFAULT" : "above"}
      className="max-w-[600px]"
    >
      <LoadableView isLoading={uploading} className="w-full">
        <FinalCheckChooseDocsForm
          onContinue={handleSubmit}
          collectEmail={!authenticatedUser}
          directToLogin={directToLogin}
        />
        {error && (
          <div className="text-destructive text-center mt-lg">{error}</div>
        )}
        {!authenticatedUser && (
          <>
            <p className="text-secondary text-sm text-center mt-lg">
              Your data is safe with us
              <br />
              <a
                href="https://odo.do/policy/privacy"
                className="underline text-xs"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
            </p>
          </>
        )}
      </LoadableView>
    </MessageView>
  );

  if (!authenticatedUser) {
    return <Rows className="grow bg-background-secondary">{content}</Rows>;
  }
  return content;
};

export default FinalCheckRoute;
