import { PlateEditor } from "@udecode/plate-common";
import { Path } from "slate";

let elementsForEditor: Record<string, Record<string, HTMLElement>> = {};

export const setElement = (
  editor: PlateEditor,
  key: string,
  element: HTMLElement | null
) => {
  if (!elementsForEditor[editor.id]) {
    elementsForEditor[editor.id] = {};
  }
  if (element === null) {
    delete elementsForEditor[editor.id][key];
  } else {
    elementsForEditor[editor.id][key] = element;
  }
};

export const getElement = (editor: PlateEditor, key: string | undefined) => {
  if (!key) return null;
  return elementsForEditor[editor.id]?.[key] ?? null;
};
