import { Requirement } from "odo";
import ProposalEditor from "components/proposal/proposal-answer-editor/ProposalAnswerEditor";
import { FC, useState } from "react";

interface TestEditorViewProps {
  id: string;
}

const TestEditorView: FC<TestEditorViewProps> = ({ id }) => {
  const [requirement, setRequirement] = useState<Requirement>({
    id,
    title: "Test Requirement",
    status: "blank",
  });

  return <ProposalEditor proposalId={id} isTestEditor={true} />;
};

export default TestEditorView;
