import { isCollapsed, useEditorRef } from "@udecode/plate-common";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "./DropdownMenu";
import Columns from "components/common/containers/Columns";
import Icon, { IconName } from "components/common/Icon";
import { cn } from "lib/utils";
import { useCallback } from "react";
import useAddComment from "../../../lib/plate/plugins/useAddComment";
import { odoUser } from "odo";

interface AIActionOption {
  label: string;
  icon: IconName;
  commentText: string;
}

const AIActionsMenu = () => {
  const editor = useEditorRef();
  const addComment = useAddComment();

  const options: AIActionOption[] = [
    {
      label: "Turn into paragraphs",
      icon: "paragraph",
      commentText: "turn this into paragraphs",
    },
    {
      label: "Turn into lists",
      icon: "list",
      commentText: "turn this into lists",
    },
    {
      label: "Make shorter",
      icon: "grip-lines",
      commentText: "make this shorter",
    },
    {
      label: "Make longer",
      icon: "align-justify",
      commentText: "make this longer",
    },
  ];

  const handleAction = useCallback(
    async (option: AIActionOption) => {
      addComment(
        [
          {
            type: "p",
            children: [
              { type: "mention", user: odoUser, children: [{ text: "" }] },
              { text: " " + option.commentText },
            ],
          },
        ],
        undefined,
        true
      );
    },
    [addComment]
  );

  const isDisabled = isCollapsed(editor.selection);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger disabled={isDisabled}>
        <Columns
          className={cn(
            "gap-sm items-center text-sm px-md",
            isDisabled ? "text-secondary" : "text-primary"
          )}
        >
          <Icon name="sparkles" />
          AI Actions
        </Columns>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {options.map((option) => (
          <DropdownMenuItem
            key={option.label}
            onClick={() => handleAction(option)}
            className="text-primary"
          >
            <Icon name={option.icon} />
            {option.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default AIActionsMenu;
