import {
  ProposalDetail as APIProposalDetails,
  Participant,
  StyleGuide,
} from "api/Api";

export interface ProposalDetails {
  id: string;
  rfpId: string;
  // User finished the start proposal flow
  isInitiated: boolean;
  // Backend finished processing the proposal
  isInitialized: boolean;
  // Proposal is available to customers
  isPublished: boolean;
  orgId: string;
  participants: Participant[];
  instructions: string;
  styleGuide: StyleGuide;
  topic: string;

  staticTitle: string;
  staticDueDate: string | null;
}

export function proposalDetialsFromApi(
  id: string,
  data: APIProposalDetails
): ProposalDetails {
  const styleGuide: StyleGuide = data.style_guide as StyleGuide;

  return {
    id,
    rfpId: data.rfp_id!,
    isPublished: !!data.published_at,
    isInitialized: data.is_initialized ?? false,
    isInitiated: !!data.initiated_at,
    orgId: data.org_id!,
    participants: data.participants ?? [],
    instructions: data.instructions ?? "",
    topic: data.topic ?? "",
    staticTitle: data.title,
    staticDueDate: data.due_date ?? null,
    styleGuide,
  };
}

export const mergeStyleGuides = (
  a: StyleGuide,
  b: StyleGuide | undefined
): StyleGuide => {
  return mergeObject(a, b);
};

const mergeObject = (
  base: Record<string, any>,
  override: Record<string, any> | undefined
): Record<string, any> => {
  if (!override) {
    return base;
  }

  let merged = JSON.parse(JSON.stringify(base));
  for (const key in override) {
    if (key in base) {
      if (typeof base[key] === "object") {
        merged[key] = mergeObject(base[key], override[key]);
      } else {
        merged[key] = override[key];
      }
    } else {
      merged[key] = override[key];
    }
  }

  return merged;
};
