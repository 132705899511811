import Button from "components/common/Button";
import LoadableView from "components/common/containers/LoadableView";
import PaginatedTableView from "components/common/containers/PaginatedTableView";
import Rows from "components/common/containers/Rows";
import {
  Category,
  LibraryItem,
  singularNameFromCategory,
} from "hooks/useContentLibrary";
import { PaginatedData } from "hooks/usePaginatedData";
import { cn } from "lib/utils";
import ContentLibraryListItem from "./ContentLibraryListItem";

interface ContentLibraryNavigationProps {
  items: LibraryItem[];
  paginatedData: PaginatedData;
  selectedItem: LibraryItem | null;
  setSelectedItem: (item: LibraryItem) => void;
  addItem: () => void;
  highlight?: boolean;
  activeCategory: Category;
  setActiveCategory: (category: Category) => void;
  onInsert?: (item: LibraryItem) => Promise<void>;
  onInsertPreview?: (item: LibraryItem | null) => void;
}

const ContentLibraryNavigation: React.FC<ContentLibraryNavigationProps> = ({
  items,
  highlight,
  paginatedData,
  setSelectedItem,
  addItem,
  activeCategory,
  setActiveCategory,
  onInsert,
  onInsertPreview,
}) => {
  const selectedClassName = "bg-primary hover:bg-primary text-background";
  const unselectedClassName =
    "py-sm px-lg hover:bg-background-selected cursor-pointer";

  const singularPropertyName = singularNameFromCategory(activeCategory);

  let showSegments = items.length > 0 && items[0].in_proposal === true;
  let segmentedItems: (LibraryItem | string)[];
  if (showSegments) {
    segmentedItems = [];
    let foundNotInProposal = false;
    for (let i = 0; i < items.length; i++) {
      if (i === 0) {
        segmentedItems.push("Recommended");
      }
      if (!foundNotInProposal && !(items[i].in_proposal ?? false)) {
        segmentedItems.push("Other");
        foundNotInProposal = true;
      }
      segmentedItems.push(items[i]);
    }
  } else {
    segmentedItems = items;
  }

  return (
    <>
      {/*********************** Categories ***********************/}
      <Rows className="bg-background-secondary border-r select-none">
        <div
          className={cn(
            unselectedClassName,
            activeCategory === "overviews" && selectedClassName
          )}
          onClick={() => setActiveCategory("overviews")}
        >
          Overviews
        </div>
        <div
          className={cn(
            unselectedClassName,
            activeCategory === "cover-letters" && selectedClassName
          )}
          onClick={() => setActiveCategory("cover-letters")}
        >
          Cover Letters
        </div>
        <div
          className={cn(
            unselectedClassName,
            activeCategory === "projects" && selectedClassName
          )}
          onClick={() => setActiveCategory("projects")}
        >
          Projects
        </div>
        <div
          className={cn(
            unselectedClassName,
            activeCategory === "people" && selectedClassName
          )}
          onClick={() => setActiveCategory("people")}
        >
          People
        </div>
        <div
          className={cn(
            unselectedClassName,
            activeCategory === "subcontractors" && selectedClassName
          )}
          onClick={() => setActiveCategory("subcontractors")}
        >
          Subcontractors
        </div>
      </Rows>
      {/*********************** Subcategories ***********************/}
      <LoadableView
        className="grow basis-0 flex"
        isLoading={paginatedData.status === "loading"}
      >
        <Rows className="grow select-none">
          <PaginatedTableView<string | LibraryItem>
            results={segmentedItems}
            variant="without-padding"
            paginatedData={paginatedData}
            searchable={true}
            columns={1}
            className="grow basis-0 min-w-[200px] border-b"
            rowSeparators={true}
            renderRow={(item, Cell, Row) => {
              if (typeof item === "string") {
                return (
                  <Row key={item}>
                    <Cell className="font-semibold bg-background-selected text-secondary font-thin text-sm py-xs">
                      {item}
                    </Cell>
                  </Row>
                );
              }
              return (
                <Row key={item.id!}>
                  <ContentLibraryListItem
                    Cell={Cell}
                    item={item}
                    highlight={highlight}
                    activeCategory={activeCategory}
                    singularPropertyName={singularPropertyName}
                    onInsert={onInsert}
                    onInsertPreview={onInsertPreview}
                    onSelected={() => setSelectedItem(item)}
                  />
                </Row>
              );
            }}
          />
          <Button
            text={`Add ${singularPropertyName}`}
            icon="plus"
            variant="solid-secondary"
            className={cn(
              "py-sm m-md",
              highlight &&
                items.length === 0 &&
                "bg-primary text-background font-semibold"
            )}
            onClick={addItem}
          />
        </Rows>
      </LoadableView>
    </>
  );
};

export default ContentLibraryNavigation;
