import { RFPFile } from "api/Api";
import Button from "components/common/Button";
import CenteredContainer from "components/common/containers/CenteredContainer";
import Icon, { IconName } from "components/common/Icon";
import Spinner from "components/common/Spinner";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/EditorView/Menus/DropdownMenu";
import useTriggerDownload from "hooks/useTriggerDownload";
import { cn } from "lib/utils";
import { useApiClient } from "providers/ApiClientProvider";
import { useProposalData } from "providers/ProposalDetailsProvider";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { message_from_exception } from "utils";

interface RFPFileMenuProps {
  rfpId: string;
  className?: string;
}

const RFPFileMenu: React.FC<RFPFileMenuProps> = ({ rfpId, className }) => {
  const apiClient = useApiClient();
  const triggerDownload = useTriggerDownload();
  const { metadata } = useProposalData();
  const [isOpen, setIsOpen] = useState(false);
  const [files, setFiles] = useState<RFPFile[] | null>(null);

  const handleView = async (file: RFPFile) => {
    try {
      let viewUrl: string | undefined;
      if (file.id === -1) {
        // Get the view url of the main RFP
        const response = await apiClient.rfp.rfpRfpRead(rfpId);
        viewUrl = response.data.view_url!;
      } else {
        // Get the view url of the specific file
        const response = await apiClient.rfp.rfpRfpFilesRead(
          rfpId,
          file.id!.toString()
        );
        viewUrl = response.data.read_url;
      }
      if (!viewUrl) {
        throw new Error("Could not find file");
      }
      triggerDownload(viewUrl, "tab");
    } catch (e) {
      toast.error(`Failed to view RFP ${message_from_exception(e)}`);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setFiles(null);
      return;
    }

    const fetchFiles = async () => {
      try {
        const response = await apiClient.rfp.rfpRfpFilesList(rfpId);
        if (response.data.length === 0) {
          // Use the main RFP instead
          setFiles([
            {
              id: -1,
              name: metadata.title,
              extension: "pdf",
            },
          ]);
        } else {
          setFiles(response.data);
        }
      } catch (e) {
        toast.error(`Failed to fetch files ${message_from_exception(e)}`);
      }
    };
    fetchFiles();
  }, [isOpen, rfpId, apiClient.rfp]);

  return (
    <DropdownMenu onOpenChange={setIsOpen}>
      <DropdownMenuTrigger asChild={true}>
        <div
          className={cn(
            "py-sm px-md flex gap-sm items-center border rounded-sm h-2xl cursor-pointer",
            className
          )}
        >
          <Icon name="magnifying-glass" />
          RFP
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {files ? (
          files.map((file) => {
            let icon: IconName = "file";
            switch (file.extension) {
              case "pdf":
                icon = "file-pdf";
                break;
              case "doc":
              case "docx":
                icon = "file-word";
                break;
              case "xls":
              case "xlsx":
                icon = "file-excel";
                break;
            }
            return (
              <DropdownMenuItem onClick={() => handleView(file)} icon={icon}>
                {file.name}
              </DropdownMenuItem>
            );
          })
        ) : (
          <CenteredContainer>
            <Spinner className="m-md" />
          </CenteredContainer>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default RFPFileMenu;
