import { FC } from "react";
import Button, {
  ButtonProps,
  ButtonVariantProps,
} from "components/common/Button";
import { IconName } from "../Icon";

interface ToggleProps extends ButtonProps {
  on?: boolean;
  onToggle?: (on: boolean) => void;
  toggleType?: "switch" | "checkbox";
  size?: ButtonVariantProps["size"];
  className?: string;
}

const Toggle: FC<ToggleProps> = ({
  on,
  onToggle,
  toggleType = "switch",
  ...props
}) => {
  let icon: IconName;
  switch (toggleType) {
    case "checkbox":
      icon = on ? "square-check" : "square";
      break;
    case "switch":
    default:
      icon = on ? "toggle-large-on" : "toggle-large-off";
      break;
  }
  return (
    <Button
      icon={icon}
      onClick={() => onToggle?.(!on)}
      aria-pressed={on}
      // Spread the rest of the props to the Button

      {...props}
    />
  );
};

export default Toggle;
