import CenteredContainer from "components/common/containers/CenteredContainer";
import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";
import Spacer from "components/common/containers/Spacer";
import Icon, { IconName } from "components/common/Icon";
import { useApiClient } from "providers/ApiClientProvider";
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useRef,
  useState,
} from "react";
import { toast } from "react-toastify";
import { message_from_exception } from "utils";
import RFPFileMenu from "../RFPFileMenu";
import { ProposalDetails } from "types/ProposalDetails";
import {
  FlowContainer,
  FlowContainerRef,
} from "components/common/containers/flow-container/FlowContainer";
import ContentItemFlowStep, {
  ContentItemFlowStepRef,
} from "./ContentItemFlowStep";
import ApproachFlowStep from "./ApproachFlowStep";
import AddPastProposalOverlay from "../AddPastProposalOverlay";
import Button from "components/common/Button";
import { format_absolute_date } from "lib/utils";

interface ProposalStartFlowProps {
  proposal: ProposalDetails;
  setProposal: Dispatch<SetStateAction<ProposalDetails>>;
  onProposalStarted: () => void;
}

const ProposalStartFlow: FC<ProposalStartFlowProps> = ({
  proposal,
  onProposalStarted,
  setProposal,
}) => {
  const apiClient = useApiClient();

  const [settingSubcontractors, setSettingSubcontractors] = useState(false);
  const [addingPastProposal, setAddingPastProposal] = useState(false);
  const flowRef = useRef<FlowContainerRef>(null);
  const pastProposalStepRef = useRef<ContentItemFlowStepRef>(null);

  const startProposal = async () => {
    try {
      // @ts-ignore
      await apiClient.rfp.rfpProposalPartialUpdate(proposal.id, {
        initiated_at: new Date().toISOString(),
      });
      onProposalStarted();
    } catch (e) {
      toast.error(message_from_exception(e));
      return;
    }
  };

  const setApproach = useCallback(
    async (approach: string) => {
      let previousInsructions = proposal.instructions;
      setProposal((prev) => ({ ...prev, instructions: approach }));
      try {
        // @ts-ignore
        await apiClient.rfp.rfpProposalPartialUpdate(proposal.id, {
          instructions: approach,
        });
      } catch (e) {
        setProposal((prev) => ({ ...prev, instructions: previousInsructions }));
        toast.error(message_from_exception(e));
      }
    },
    [apiClient.rfp, proposal.id, proposal.instructions, setProposal]
  );

  return (
    <Rows className="bg-background-secondary w-full grow flex flex-col tems-center overflow-auto">
      <Rows className="drop-shadow w-full max-w-[1000px] max-h-[850px] min-w-[500px] min-h-[750px] p-lg grow mx-auto my-auto">
        <Columns className="gap-lg bg-tertiary p-2xl py-lg rounded-t text-background items-center">
          <h1 className="w-full block text-lg truncate">
            {proposal.staticTitle}
          </h1>
          <Spacer />
          {proposal.staticDueDate && (
            <p className="shrink-0">
              Due: {format_absolute_date(proposal.staticDueDate)}
            </p>
          )}
          <RFPFileMenu rfpId={proposal.rfpId} />
        </Columns>
        <Rows className="gap-md border-x border-b rounded-b bg-background w-full grow">
          <FlowContainer ref={flowRef} className="w-full grow">
            <ProposalStartFlowStep
              icon="clipboard-list"
              title="Tell Us More"
              question="We’ll ask you a few questions to guide the proposal drafting"
            >
              <Button
                variant="solid"
                className="mt-lg"
                onClick={() => flowRef.current?.goNext()}
              >
                Continue
              </Button>
            </ProposalStartFlowStep>
            <ProposalStartFlowStep
              icon="buildings"
              title="Subcontractors"
              question="Will you have any subcontractors?"
            >
              <ContentItemFlowStep
                libraryCategory="subcontractors"
                proposalId={proposal.id}
                flowRef={flowRef.current}
                confirmation={{
                  confirmed: settingSubcontractors,
                  setConfirmed: setSettingSubcontractors,
                }}
                createAssociationEndpoint={
                  apiClient.rfp.rfpContentLibrarySubcontractorAssociationCreate
                }
                deleteAssociationEndpoint={
                  apiClient.rfp.rfpContentLibrarySubcontractorAssociationDelete
                }
              />
            </ProposalStartFlowStep>
            <ProposalStartFlowStep
              icon="person"
              title="Staffing"
              question="Who will you staff on this project?"
            >
              <ContentItemFlowStep
                libraryCategory="people"
                proposalId={proposal.id}
                flowRef={flowRef.current}
                createAssociationEndpoint={
                  apiClient.rfp.rfpContentLibraryPersonAssociationCreate
                }
                deleteAssociationEndpoint={
                  apiClient.rfp.rfpContentLibraryPersonAssociationDelete
                }
              />
            </ProposalStartFlowStep>
            <ProposalStartFlowStep
              icon="briefcase"
              title="References"
              question="Which past projects or references would you like to include?"
            >
              <ContentItemFlowStep
                libraryCategory="projects"
                proposalId={proposal.id}
                flowRef={flowRef.current}
                createAssociationEndpoint={
                  apiClient.rfp.rfpContentLibraryProjectAssociationCreate
                }
                deleteAssociationEndpoint={
                  apiClient.rfp.rfpContentLibraryProjectAssociationDelete
                }
              />
            </ProposalStartFlowStep>
            <ProposalStartFlowStep
              icon="bullseye"
              title="Approach Strategy"
              question="Are there any specific win themes or strategies to shape the approach?"
            >
              <ApproachFlowStep
                approach={proposal.instructions}
                rfpId={proposal.rfpId}
                setApproach={setApproach}
                flowRef={flowRef.current}
              />
            </ProposalStartFlowStep>
            <ProposalStartFlowStep
              icon="file"
              title="Past Proposals"
              question="Which of the past proposals are particularly relevant?"
            >
              <ContentItemFlowStep
                stepRef={pastProposalStepRef}
                libraryCategory="past-proposals"
                continueText="Start Proposal"
                onGoNext={startProposal}
                proposalId={proposal.id}
                flowRef={flowRef.current}
                createAssociationEndpoint={
                  apiClient.rfp.rfpContentLibraryPastProposalAssociationCreate
                }
                deleteAssociationEndpoint={
                  apiClient.rfp.rfpContentLibraryPastProposalAssociationDelete
                }
                onSelectItem={async (item) => {
                  try {
                    const response = await apiClient.rfp.rfpProposalPastRead2(
                      proposal.id,
                      item.id
                    );
                    window.open(response.data.view_url!);
                  } catch (e) {
                    toast.error(message_from_exception(e));
                  }
                }}
                onAddItem={() => setAddingPastProposal(true)}
              />
            </ProposalStartFlowStep>
          </FlowContainer>
        </Rows>
      </Rows>
      {addingPastProposal && (
        <AddPastProposalOverlay
          onClose={() => {
            setAddingPastProposal(false);
            pastProposalStepRef.current?.refresh();
          }}
        />
      )}
    </Rows>
  );
};

interface ProposalStartFlowStepProps {
  icon: IconName;
  title: string;
  question: string;
  children: ReactNode;
}

const ProposalStartFlowStep: FC<ProposalStartFlowStepProps> = ({
  icon,
  title,
  question,
  children,
}) => {
  return (
    <CenteredContainer className="grow flex flex-col w-full items-stretch px-4xl py-2xl overflow-hidden max-w-[750px] mx-auto">
      <Rows className="items-center justify-center grow">
        <Columns className="items-center gap-lg mb-xl border-b pb-md shrink-0">
          <Icon name={icon} size="xlarge" variant="solid" />
          <h1 className="text-3xl font-semibold">{title}</h1>
        </Columns>
        <p className="text-xl">{question}</p>
        {children}
      </Rows>
    </CenteredContainer>
  );
};

export default ProposalStartFlow;
