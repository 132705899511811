import { OrgDetails } from "api/Api";
import Button from "components/common/Button";
import AsyncLoadedDiv from "components/common/containers/AsyncLoadedDiv";
import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";
import Input from "components/common/forms/Input";
import LabeledFormInput from "components/common/forms/LabeledFormInput";
import NaturalHeightTextArea from "components/common/forms/NaturalHeightTextArea";
import NavigationView from "components/common/NavigationView";
import useFetchedData from "hooks/useFetchedData";
import SavedStateIndicator, {
  SaveState,
} from "components/common/forms/SavedStatusIndicator";
import { cn } from "lib/utils";
import { debounce } from "lodash";
import { useApiClient } from "providers/ApiClientProvider";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { FC, forwardRef, useEffect, useRef, useState } from "react";
import { message_from_exception } from "utils";
import { useTextAreaConfirm } from "providers/AlertProvider";
import StatePicker from "components/profile/StatePicker";
import { CustomerTypePicker } from "components/profile/CustomerTypePicker";
import { Category, CategoryPicker } from "components/profile/CategoryPicker";

const AccountRoute: FC = () => {
  const apiClient = useApiClient();
  const basicInfoRef = useRef<HTMLDivElement>(null);
  const customersRef = useRef<HTMLDivElement>(null);
  const recommendationProfileRef = useRef<HTMLDivElement>(null);
  const [highlightedSection, setHighlightedSection] = useState<
    "basic" | "recommend" | null
  >(null);
  const textAreaConfirm = useTextAreaConfirm();

  const [saveState, setSaveState] = useState<SaveState>("unchanged");
  const [saveError, setSaveError] = useState<string | null>(null);
  const [localOrgDetails, doSetLocalOrgDetails] = useState<OrgDetails | null>(
    null
  );
  const [orgDetails, , { error }] = useFetchedData(async () => {
    const response = await apiClient.org.orgRead();
    return response.data;
  }, []);
  const currentUser = useAuthenticatedUser();
  const [sampleCategory, setSampleCategory] = useState<Category | null>(null);

  const debouncedSave = useRef(
    debounce(async (details: OrgDetails) => {
      try {
        await apiClient.org.orgUpdate({
          ...details,
          name: details.name ? details.name : "Unnammed Company",
          categories: details?.categories.filter((c) => !!c),
        });
        setSaveState("saved");
        setSaveError(null);
      } catch (e) {
        setSaveState("error");
        setSaveError(message_from_exception(e));
      }
    }, 500)
  );

  useEffect(() => {
    if (orgDetails) {
      doSetLocalOrgDetails(orgDetails);
    }
  }, [orgDetails]);

  const setLocalOrgDetails = (details: OrgDetails) => {
    setSaveState("saving");
    doSetLocalOrgDetails(details);
    debouncedSave.current(details);
  };

  const scrollToElement = (ref: React.RefObject<HTMLDivElement>) => {
    if (!ref.current) return;

    // Scroll to the element and flash it
    ref.current.scrollIntoView({ behavior: "smooth" });
    setHighlightedSection(ref === basicInfoRef ? "basic" : "recommend");
    setTimeout(() => {
      setHighlightedSection(null);
    }, 500);
  };

  const tryAgain = () => {
    if (localOrgDetails) {
      setSaveState("saving");
      setSaveError(null);
      debouncedSave.current(localOrgDetails);
    }
  };

  const addExcludedScope = async () => {
    if (!localOrgDetails) return;
    const result = await textAreaConfirm("Enter a scope to exclude from RFPs", {
      buttons: [
        { text: "Add", id: "add" },
        { text: "Cancel", id: "cancel" },
      ],
      dismissId: "cancel",
    });
    if (result.id !== "add") return;
    const scope = result.text;
    const newExcludedScopes = new Set<string>(
      localOrgDetails.raw_excluded_scopes ?? []
    );
    newExcludedScopes.add(scope);
    setLocalOrgDetails({
      ...localOrgDetails,
      raw_excluded_scopes: Array.from(newExcludedScopes),
    });
  };

  const removeExcludedScope = (scope: string) => {
    if (!localOrgDetails) return;
    const newExcludedScopes = new Set<string>(
      localOrgDetails.raw_excluded_scopes ?? []
    );
    newExcludedScopes.delete(scope);
    setLocalOrgDetails({
      ...localOrgDetails,
      raw_excluded_scopes: Array.from(newExcludedScopes),
    });
  };

  const addPreferredScope = async () => {
    if (!localOrgDetails) return;
    const result = await textAreaConfirm("Enter a scope to prefer for RFPs", {
      buttons: [
        { text: "Add", id: "add" },
        { text: "Cancel", id: "cancel" },
      ],
      dismissId: "cancel",
    });
    if (result.id !== "add") return;
    const scope = result.text;
    const newPreferredScopes = new Set<string>(
      localOrgDetails.raw_preferred_scopes ?? []
    );
    newPreferredScopes.add(scope);
    setLocalOrgDetails({
      ...localOrgDetails,
      raw_preferred_scopes: Array.from(newPreferredScopes),
    });
  };

  const removePreferredScope = (scope: string) => {
    if (!localOrgDetails) return;
    const newPreferredScopes = new Set<string>(
      localOrgDetails.raw_preferred_scopes ?? []
    );
    newPreferredScopes.delete(scope);
    setLocalOrgDetails({
      ...localOrgDetails,
      raw_preferred_scopes: Array.from(newPreferredScopes),
    });
  };

  const hasSelectedCategory = (localOrgDetails?.categories?.length ?? 0) > 0;

  return (
    <NavigationView scrollable={false}>
      <AsyncLoadedDiv
        value={localOrgDetails}
        className="grow flex flex-col overflow-hidden"
        error={error ? message_from_exception(error) : null}
        whileLoaded={(details) => (
          <>
            {/********************** Header ***********************************/}
            <Columns className="items-center shrink-0 mb-md">
              <h1 className="text-2xl font-semibold grow shrink-0">
                Company Profile
              </h1>
              {saveError && (
                <p className="grow text-destructive text-right mr-md">
                  {saveError}
                </p>
              )}
              <SavedStateIndicator state={saveState} tryAgain={tryAgain} />
            </Columns>
            <Columns className="gap-lg border rounded-md">
              {/********************** Nav ***********************************/}
              <Rows className="items-start bg-background-secondary rounded-sm overflow-y-auto shrink-0 p-sm border-r hidden tablet:block">
                <Button
                  text="Basic Info"
                  className="pt-sm"
                  onClick={() => scrollToElement(basicInfoRef)}
                />
                <Button
                  text="Geography"
                  className="pt-sm"
                  onClick={() => scrollToElement(customersRef)}
                />
                <Button
                  text="Profile"
                  className="pt-sm"
                  onClick={() => scrollToElement(recommendationProfileRef)}
                />
              </Rows>
              {/********************** Sections ***********************************/}
              <Rows className="grow gap-lg overflow-y-auto">
                {/********************** Basic Info ***********************************/}
                <Section
                  title="Basic Info"
                  ref={basicInfoRef}
                  highlighted={highlightedSection === "basic"}
                >
                  <Field title="Company Name*">
                    <Input
                      placeholder="Acme Corporation"
                      value={details.name}
                      onChange={(e) => {
                        setLocalOrgDetails({
                          ...details,
                          name: e.target.value,
                        });
                      }}
                    />
                  </Field>
                  <Field title="Categories*" instructions="Choose at least one">
                    <CategoryPicker
                      categories={details.categories ?? []}
                      categoryDisplayNames={
                        details.category_display_names ?? {}
                      }
                      onChange={(categories, sampleCategory) => {
                        setLocalOrgDetails({
                          ...details,
                          categories,
                        });
                        setSampleCategory(sampleCategory);
                      }}
                    />
                  </Field>
                </Section>
                {/********************** Customers ***********************************/}
                <Section
                  title="Geography"
                  ref={customersRef}
                  className={
                    hasSelectedCategory ? "" : "opacity-30 pointer-events-none"
                  }
                >
                  <Field
                    title="States"
                    instructions="Choose specific states or leave blank if you're open to all states"
                  >
                    <StatePicker
                      stateLocations={details.state_locations ?? []}
                      setStateLocations={(states) => {
                        if (typeof states === "function") {
                          setLocalOrgDetails({
                            ...details,
                            state_locations: states(
                              details.state_locations ?? []
                            ),
                          });
                        } else {
                          setLocalOrgDetails({
                            ...details,
                            state_locations: states,
                          });
                        }
                      }}
                    />
                  </Field>
                </Section>
                {/********************** Profile ***********************************/}
                <Section
                  title="Profile"
                  ref={recommendationProfileRef}
                  highlighted={highlightedSection === "recommend"}
                  instructions="Tell us about your company to peronalize your RFP recommendations."
                  className={
                    hasSelectedCategory ? "" : "opacity-30 pointer-events-none"
                  }
                >
                  <Field
                    title="Company Description"
                    instructions="In one or two sentences, describe your company."
                  >
                    <NaturalHeightTextArea
                      placeholder={sampleCategory?.placeholderDescription}
                      characterLimit={4096}
                      className="tablet:min-h-[90px] min-h-[150px]"
                      value={details.description ?? ""}
                      onChange={(text) => {
                        setLocalOrgDetails({
                          ...details,
                          description: text,
                        });
                      }}
                      disabled={!hasSelectedCategory}
                    />
                  </Field>
                  <Field
                    title="Services"
                    instructions="What are your primary services? What makes you unique?"
                  >
                    <NaturalHeightTextArea
                      placeholder={sampleCategory?.placeholderServices}
                      className="tablet:min-h-[90px] min-h-[150px]"
                      characterLimit={4096}
                      value={details.services ?? ""}
                      onChange={(text) =>
                        setLocalOrgDetails({
                          ...details,
                          services: text,
                        })
                      }
                      disabled={!hasSelectedCategory}
                    />
                  </Field>
                  <Field
                    title="Customers"
                    instructions="Who are your customers?"
                  >
                    <CustomerTypePicker
                      values={details.issuing_org_kinds ?? []}
                      onChange={(values) =>
                        setLocalOrgDetails({
                          ...details,
                          issuing_org_kinds: values,
                        })
                      }
                    />
                  </Field>
                  {currentUser.isStaff && (
                    <Field title="Additional Instructions">
                      <NaturalHeightTextArea
                        className="tablet:min-h-[90px] min-h-[150px] admin border-primary"
                        characterLimit={4096}
                        value={details.profile ?? ""}
                        onChange={(text) =>
                          setLocalOrgDetails({
                            ...details,
                            profile: text,
                          })
                        }
                        disabled={!hasSelectedCategory}
                      />
                    </Field>
                  )}
                  {currentUser.isStaff && (
                    <Field title="Excluded Scope">
                      <Rows>
                        {(details.raw_excluded_scopes ?? []).map((s, index) => (
                          <Columns className="items-center" key={index}>
                            <Button
                              icon="xmark"
                              className="text-destructive"
                              onClick={() => removeExcludedScope(s)}
                            />
                            <p>{s}</p>
                          </Columns>
                        ))}
                      </Rows>
                      <Columns className="mt-md">
                        <Button
                          text="Add"
                          variant="solid-secondary"
                          className="admin"
                          onClick={addExcludedScope}
                        />
                      </Columns>
                    </Field>
                  )}
                  {currentUser.isStaff && (
                    <Field title="Preferred Scope">
                      <Rows>
                        {(details.raw_preferred_scopes ?? []).map(
                          (s, index) => (
                            <Columns className="items-center" key={index}>
                              <Button
                                icon="xmark"
                                className="text-destructive"
                                onClick={() => removePreferredScope(s)}
                              />
                              <p>{s}</p>
                            </Columns>
                          )
                        )}
                      </Rows>
                      <Columns className="mt-md">
                        <Button
                          text="Add"
                          variant="solid-secondary"
                          className="admin"
                          onClick={addPreferredScope}
                        />
                      </Columns>
                    </Field>
                  )}
                </Section>
              </Rows>
            </Columns>
          </>
        )}
      />
    </NavigationView>
  );
};

interface SectionProps {
  title: string;
  instructions?: string;
  children?: React.ReactNode;
  highlighted?: boolean;
  className?: string;
}

const Section = forwardRef<HTMLDivElement, SectionProps>(
  ({ title, instructions, highlighted, className, children }, ref) => {
    return (
      <Rows
        ref={ref}
        className={cn("gap-lg shrink-0 p-md relative", className)}
      >
        <Rows className="gap-sm shrink-0">
          <h2 className="text-xl font-semibold border-b py-sm">{title}</h2>
          {instructions && <p>{instructions}</p>}
        </Rows>
        <Rows className="gap-xl">{children}</Rows>
        <div
          className="bg-background-secondary absolute z-[-1] inset-0 transition-opacity duration-300"
          style={{ opacity: highlighted ? 1 : 0 }}
        />
      </Rows>
    );
  }
);

interface FieldProps {
  title: string;
  instructions?: string;
  children?: React.ReactNode;
}

const Field: FC<FieldProps> = ({ title, instructions, children }) => {
  return (
    <LabeledFormInput label={title} variant="long-form">
      {instructions && <p className="text-secondary">{instructions}</p>}
      <Rows className="mt-sm justify-start">{children}</Rows>
    </LabeledFormInput>
  );
};

export default AccountRoute;
