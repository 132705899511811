import { ComponentPropsWithoutRef, FC } from "react";
import Columns from "./common/containers/Columns";
import { cn } from "lib/utils";
import Button from "./common/Button";
import { copyToClipboard } from "lib/clipboard";

interface CopyableTextProps extends ComponentPropsWithoutRef<"div"> {
  text: string;
  iconOnly?: boolean;
}

const CopyableText: FC<CopyableTextProps> = ({
  text,
  className,
  iconOnly,
  ...props
}) => {
  const button = (
    <Button
      icon="copy"
      className="-my-md"
      onClick={() => copyToClipboard(text)}
    />
  );

  if (iconOnly) {
    return button;
  }

  return (
    <Columns {...props} className={cn("items-center", className)}>
      {text}
      {button}
    </Columns>
  );
};

export default CopyableText;
