import { FC, useRef, useState } from "react";
import { isSuggestedElementInsert } from "odo";
import { cn } from "lib/utils";
import BaseElement, {
  BaseElementProps,
} from "components/EditorView/Elements/BaseElement";
import SuggestionControl from "components/suggestions/SuggestionControl";
import useSuggestionId from "lib/plate/plugins/suggestions/useSuggestionId";
import SuggestionPreview from "components/suggestions/SuggestionPreview";

interface SuggestableElementProps extends BaseElementProps {
  areControlsHidden?: boolean;
  suggestionControlTopOffset?: number;
}

export const SuggestableElement: FC<SuggestableElementProps> = ({
  element,
  children,
  suggestionControlTopOffset,
  areControlsHidden = false,
  className,
  ...props
}) => {
  const elementRef = useRef<HTMLElement>(null);
  const suggestionId = useSuggestionId(element);
  const [isHovering, setIsHovering] = useState(false);

  return (
    <BaseElement
      {...props}
      element={element}
      className={cn(
        "relative",
        className,
        isSuggestedElementInsert(element) && "text-primary"
      )}
    >
      <div ref={elementRef as any}>
        {children}
        <div
          className="absolute -left-[46px] p-[inherit] flex gap-sm top-0 items-center top-0 h-[1.5em] select-none"
          contentEditable={false}
        >
          {suggestionId && !areControlsHidden && (
            <SuggestionControl
              suggestionId={suggestionId}
              onHover={setIsHovering}
            />
          )}
          {suggestionId && isHovering && (
            <SuggestionPreview
              suggestionId={suggestionId}
              className="-mt-sm z-[999]"
            />
          )}
        </div>
      </div>
    </BaseElement>
  );
};
