import {
  createContext,
  ReactNode,
  RefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { v4 } from "uuid";
import { isText, TNodeEntry, usePlateSelectors } from "@udecode/plate";
import { findNode, someNode } from "@udecode/plate-common";
import {
  clearAllOrphanedComments,
  getCommentIdFromKey,
  getCommentKeyFromId,
  getCommentKeys,
  Comment,
  MARK_COMMENT,
  OdoUser,
  isCommentKey,
} from "odo";
import useYjsMap from "api/useYjsMap";
import { useOdoEditorRef } from "hooks/odo-editor/useOdoEditorRef";
import { useLocation } from "react-router-dom";
import { BaseRange, Point } from "slate";
import { useAuthenticatedUser } from "./AuthenticatedUserProvider";

type CommentUpdate = (commentId: string, comment: Comment | null) => void;
type CommentMarkUpdate = (
  commentId: string,
  markId: string,
  data: CommentMarkData | null
) => void;
type AllCommentMarkData = Record<string, Record<string, CommentMarkData>>;

export type CommentMarkData = RefObject<HTMLElement>;

interface CommentsProviderProps {
  children: ReactNode;
}

interface CommentsStateData {
  pendingComment: Comment | null;
  pendingReplies: Record<string, any[]>;
  comments: Record<string, Comment>;
  commentMarkData: AllCommentMarkData;
  activeComment: string | null;
  users: Record<string, OdoUser>;
}

interface CommentsUpdateData {
  setYjsProvider: (provider: HocuspocusProvider | null) => void;
  saveComment: CommentUpdate;
  savePendingReply: (parentId: string, content: any[]) => void;
  createPendingComment: (startingContent: any[], parentId?: string) => Comment;
  updateCommentMarkData: CommentMarkUpdate;
  setActiveComment: (commentId: string | null) => void;
}

const CommentsStateContext = createContext<CommentsStateData | null>(null);
const CommentsUpdateContext = createContext<CommentsUpdateData | null>(null);

const defaultUsers: Record<string, OdoUser> = {
  odo: {
    publicId: "odo",
    displayName: "odo",
  },
};

const CommentsProvider: React.FC<CommentsProviderProps> = ({ children }) => {
  const editor = useOdoEditorRef();
  const editorKey = usePlateSelectors().versionEditor();
  const location = useLocation();
  const [hasLoadedFragment, setHasLoadedFragement] = useState(false);
  const currentUser = useAuthenticatedUser();
  const [yjsProvider, setYjsProvider] = useState<HocuspocusProvider | null>(
    null
  );
  const [pendingComment, setPendingComment] = useState<Comment | null>(null);
  const [pendingReplies, setPendingReplies] = useState<Record<string, any[]>>(
    {}
  );
  const [activeComment, _setActiveComment] = useState<string | null>(null);
  const [commentMarkData, setCommentMarkData] = useState<AllCommentMarkData>(
    {}
  );
  const openThreadsCount = useRef(0);
  const [commentsCleared, setCommentsCleared] = useState(false);

  let defaultUsersAndCurrent = { ...defaultUsers };
  if (currentUser) {
    defaultUsersAndCurrent[currentUser.publicId] = currentUser;
  }

  const [comments, setComment] = useYjsMap<Comment>(
    yjsProvider,
    "comments",
    {},
    false
  );
  const [users] = useYjsMap<OdoUser>(
    yjsProvider,
    "users",
    defaultUsersAndCurrent,
    true
  );

  useEffect(() => {
    // Update the open thread count based on the number of open comments
    openThreadsCount.current = Object.values(comments).reduce(
      (count, comment) => {
        if (comment.isResolved) return count;
        if (comment.parentId) return count;
        return count + 1;
      },
      0
    );
  }, [comments]);

  useEffect(() => {
    if (
      hasLoadedFragment ||
      !yjsProvider ||
      yjsProvider.status !== "connected" ||
      !location.hash ||
      !location.hash.startsWith("#comment_")
    )
      return;
    setTimeout(() => {
      const commentKey = location.hash.slice("#".length);
      const entry = findNode(editor, {
        at: [],
        match: (n) => {
          return isText(n) && !!n[MARK_COMMENT] && !!n[commentKey];
        },
      });
      if (!entry) return;
      const [, path] = entry;
      const start = editor.point(path, { edge: "start" });
      // focusEditor(editor, start);
      setHasLoadedFragement(true);
    }, 100);
  }, [
    yjsProvider?.status,
    location.hash,
    hasLoadedFragment,
    yjsProvider,
    editor,
  ]);

  const clearCommentsWithoutMarks = useCallback(() => {
    if (!editor || editor.children.length < 2) {
      return;
    }

    for (const commentId in comments) {
      if (commentMarkData[commentId]) {
        continue;
      }

      const comment = comments[commentId];
      if (!comment.parentId) {
        setComment(commentId, { ...comment, isResolved: true });
      }
    }
  }, [editor, comments, commentMarkData, setComment]);

  const commentCount = Object.keys(comments).length;
  useEffect(() => {
    if (commentsCleared || commentCount === 0) {
      return;
    }
    setCommentsCleared(true);
    // Clear out comments that don't have any marks
    clearCommentsWithoutMarks();
  }, [
    commentCount,
    commentsCleared,
    setCommentsCleared,
    clearCommentsWithoutMarks,
  ]);

  const createPendingComment = useCallback(
    (startingContent: any[], parentId?: string) => {
      if (pendingComment) {
        return pendingComment;
      }
      if (!currentUser) {
        throw new Error("Cannot comment without being logged in");
      }
      const newComment: Comment = {
        id: v4().toString(),
        userId: currentUser.publicId,
        createdAt: new Date().toISOString(),
        parentId: parentId,
        isPending: true,
        isResolved: false,
        text: startingContent,
      };
      setPendingComment(newComment);
      return newComment;
    },
    [currentUser, pendingComment]
  );

  const saveComment = useCallback(
    (commentId: string, comment: Comment | null) => {
      if (commentId === pendingComment?.id) {
        setPendingComment(null);
        if (comment) {
          setComment(commentId, comment);
        } else {
          clearAllOrphanedComments(editor, comments);
        }
      } else {
        setComment(commentId, comment);
      }
    },
    [pendingComment?.id, setComment, editor, comments]
  );

  const updateCommentMarkData: CommentMarkUpdate = useCallback(
    (commentId, markId: string, data) => {
      setCommentMarkData((prev) => {
        // Create an updated comment record for this comment
        const commentRecord = prev[commentId] || {};
        let newCommentRecord = { ...commentRecord };
        if (data === null) {
          delete newCommentRecord[markId];
        } else {
          newCommentRecord[markId] = data;
        }

        // Update the overall commentMarkData
        const newPrev = { ...prev };
        if (Object.keys(newCommentRecord).length === 0) {
          delete newPrev[commentId];
        } else {
          newPrev[commentId] = newCommentRecord;
        }
        return newPrev;
      });
    },
    [setCommentMarkData]
  );

  const savePendingReply = useCallback(
    (parentId: string, content: any[]) => {
      setPendingReplies((prev) => {
        const newPrev = { ...prev };
        newPrev[parentId] = content;
        return newPrev;
      });
    },
    [setPendingReplies]
  );

  useEffect(() => {
    // Editor changed (including selection)
    // 1. Set the active comment based on the selection
    // 2. Clear any pending comments if the original text is no longer in selection

    // Don't set an active comment if nothing is selected
    if (!editor.selection) return;

    // First look specifically for the pending comment mark within the selection
    // If it's there, keep that as the active comment regardless of other marks
    if (pendingComment) {
      if (
        someNode(editor, {
          match: (n) => n[getCommentKeyFromId(pendingComment.id)],
        })
      ) {
        _setActiveComment(pendingComment.id);
        return;
      }
    }

    let activeCommentId: string | null = null;
    let node: TNodeEntry<any> | undefined = undefined;
    let rejectedKeys = new Set<string>();
    let foundNode = false;
    do {
      node = findNode(editor, {
        match: (n) => {
          if (!n[MARK_COMMENT]) return false;
          let foundCommentKey = false;
          for (const key of Object.keys(n)) {
            if (rejectedKeys.has(key)) return false;
            foundCommentKey = foundCommentKey || isCommentKey(key);
          }
          if (!foundCommentKey) return false;
          return true;
        },
      });
      if (node) {
        const [match] = node;
        const commentKeys = getCommentKeys(match);
        for (const key of commentKeys) {
          const commentId = getCommentIdFromKey(key);
          const comment = comments[commentId];
          if (!comment || comment.isResolved) {
            rejectedKeys.add(key);
            continue;
          }
          activeCommentId = commentId;
          foundNode = true;
          break;
        }
      }
    } while (!!node && !foundNode);
    _setActiveComment(activeCommentId);

    if (pendingComment && activeCommentId !== pendingComment.id) {
      setPendingComment(null);
      // Clear all of them instead of just the pending one in case there are other
      // orphaned comments. That can happen if a user reloads while still editing a
      // pending comment.
      // This should run rarely so this shouldn't be a performance problem.
      clearAllOrphanedComments(editor, comments);
    }
  }, [editorKey, editor, comments, pendingComment]);

  useEffect(() => {
    // Editor changed (including selection)
    // -> Make it easier to escape comments while editing
    // Primarily this is to address two scenarios:
    // 1. New text added immediately after a comment should not be marked as a comment
    // 2. When adding newlines before a comment, the comment should not be added to the new line

    let selection = { ...editor.selection };
    if (!selection || !selection.focus || !selection.anchor) {
      return;
    }

    let marks: Record<string, any> = { ...(editor.getMarks() ?? {}) };
    if (getCommentKeys(marks).length === 0) {
      // No comment marks to possible remove
      return;
    }

    // 1. Expand the selection by one character before and after the selection
    // 2. Look across all leafs nodes in the selection for comment marks
    // 3. If all nodes have the same comment mark, leave the comment mark as active
    //    otherwise, remove the comment mark

    // Expand the selection by one character before and after the selection
    if (Point.compare(selection.focus, selection.anchor) <= 0) {
      // The focus is before the anchor
      selection.focus = editor.before(selection.focus);
      selection.anchor = editor.after(selection.anchor);
    } else {
      // The anchor is before the focus
      selection.anchor = editor.before(selection.anchor);
      selection.focus = editor.after(selection.focus);
    }

    if (!selection.anchor || !selection.focus) {
      // We are at the beginning or end of the document
      // remove all comment marks
      for (const key of Object.keys(marks)) {
        if (isCommentKey(key)) {
          delete marks[key];
        } else if (key === MARK_COMMENT) {
          delete marks[key];
        }
      }
      editor.marks = marks;
      return;
    }

    // Look for comment marks and whether they are consistent across all nodes
    for (const [node] of editor.nodes({ at: selection as BaseRange })) {
      if (!isText(node)) {
        continue;
      }
      // Remove any comment marks not found on this node
      for (const key of Array.from(Object.keys(marks))) {
        if (!isCommentKey(key)) {
          continue;
        }

        if (!node[key]) {
          delete marks[key];
        }
      }

      // Check if no comment marks are left -> remove base comment mark
      if (getCommentKeys(marks).length === 0 && marks[MARK_COMMENT]) {
        delete marks[MARK_COMMENT];
      }

      editor.marks = marks;
    }
  }, [editorKey, editor]);

  const setActiveComment = useCallback(
    (commentId: string | null) => {
      if (commentId) {
        // Focus the editor on the comment
        const commentKey = getCommentKeyFromId(commentId);
        const startEntry = findNode(editor, {
          at: [],
          match: (n) => {
            return isText(n) && !!n[MARK_COMMENT] && !!n[commentKey];
          },
        });
        const endEntry = findNode(editor, {
          at: [],
          match: (n) => {
            return isText(n) && !!n[MARK_COMMENT] && !!n[commentKey];
          },
          reverse: true,
        });
        if (startEntry) {
          const start = editor.point(startEntry[1], { edge: "start" });
          const end = endEntry
            ? editor.point(endEntry[1], { edge: "end" })
            : start;
          editor.setSelection({ anchor: start, focus: end });
        }
      }
      _setActiveComment(commentId);
    },
    [_setActiveComment, editor]
  );

  return (
    <CommentsStateContext.Provider
      value={{
        comments,
        activeComment,
        pendingComment,
        pendingReplies,
        commentMarkData,
        users,
      }}
    >
      <CommentsUpdateContext.Provider
        value={{
          setYjsProvider,
          setActiveComment,
          saveComment,
          createPendingComment,
          updateCommentMarkData,
          savePendingReply,
        }}
      >
        {children}
      </CommentsUpdateContext.Provider>
    </CommentsStateContext.Provider>
  );
};

const useCommentsState = () => {
  const data = useContext(CommentsStateContext);
  if (data === null) {
    throw new Error("useCommentsState must be used within a CommentsProvider");
  }
  return data;
};

const useCommentsUpdate = () => {
  const data = useContext(CommentsUpdateContext);
  if (data === null) {
    throw new Error("useCommentsUpdate must be used within a CommentsProvider");
  }
  return data;
};

export const useComments = (): [
  Comment | null,
  Record<string, Comment>,
  AllCommentMarkData
] => {
  const data = useCommentsState();
  return [data.pendingComment, data.comments, data.commentMarkData];
};

export const useCommentReplies = (parentId: string): Comment[] => {
  const data = useCommentsState();
  // Filter to just the comments with the given parentId
  const comments = Object.values(data.comments).filter(
    (comment) => comment.parentId === parentId
  );
  // Sort by most recent first
  return comments.sort((a, b) => {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  });
};

export const useSetCommentsYjsProvider = () => {
  return useCommentsUpdate().setYjsProvider;
};

export const useCreatePendingComment = () => {
  return useCommentsUpdate().createPendingComment;
};

export const usePendingReplies = () => {
  return useCommentsState().pendingReplies;
};

export const useSaveComment = () => {
  return useCommentsUpdate().saveComment;
};

export const useSavePendingReply = () => {
  return useCommentsUpdate().savePendingReply;
};

export const useUpdateCommentMarkData = () => {
  return useCommentsUpdate().updateCommentMarkData;
};

export const useActiveComment = () => {
  return useCommentsState().activeComment;
};

export const useSetActiveComment = () => {
  return useCommentsUpdate().setActiveComment;
};

export default CommentsProvider;
