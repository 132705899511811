import { OdoUser } from "odo";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useApiClient } from "./ApiClientProvider";
import { useProposalData } from "./ProposalDetailsProvider";
import { useAuthenticatedUser } from "./AuthenticatedUserProvider";
import { PermissionedProposalParticipant } from "types/PermissionedProposalParticipant";
import { ProposalParticipant } from "types/ProposalParticipant";
import { FrontendUser, odoUserFromRemote } from "types/FrontendUser";

interface OrgUserProviderProps {
  children?: React.ReactNode;
}

interface OrgUserProviderData {
  users: PermissionedProposalParticipant[] | null;
  error: unknown | null;
  refresh: () => Promise<void>;
  transferedOwnership: (to: ProposalParticipant) => void;
  isCurrentUserOwner: boolean;
}

const OrgUsersProviderContext = createContext<OrgUserProviderData | null>(null);

const OrgUserProvider: React.FC<OrgUserProviderProps> = ({ children }) => {
  const apiClient = useApiClient();
  const [orgUsers, setOrgUsers] = useState<OdoUser[] | null>(null);
  const [users, setUsers] = useState<PermissionedProposalParticipant[] | null>(
    null
  );
  const [error, setError] = useState<unknown | null>(null);
  const { details, participants } = useProposalData();
  const currentUser = useAuthenticatedUser();
  const isCurrentUserOwner =
    users?.find((u) => u.isCurrentUser && u.permission === "owner") !==
    undefined;

  useEffect(() => {
    if (!orgUsers) {
      setUsers(null);
      return;
    }
    const users: PermissionedProposalParticipant[] = orgUsers
      .map((u) => {
        const participant = participants.find((p) => p.publicId === u.publicId);
        return {
          ...u,
          isPresent: participant?.isPresent ?? false,
          isOwner: participant?.isOwner ?? false,
          isCurrentUser: u.publicId === currentUser?.publicId,
          permission: (participant
            ? participant.isOwner
              ? "owner"
              : "collaborator"
            : "none") as PermissionedProposalParticipant["permission"],
        };
      })
      .sort((a, b) => a.displayName.localeCompare(b.displayName));
    setUsers(users);
  }, [orgUsers, currentUser?.publicId, participants]);

  const refresh = useCallback(async () => {
    try {
      if (!details.orgId) return;
      const orgUsers = (await apiClient.orgs.orgsUsersList(details.orgId)).data;
      setOrgUsers(orgUsers.map(odoUserFromRemote));
    } catch (e) {
      setError(e);
    }
  }, [apiClient.orgs, details.orgId]);

  const transferedOwnership = (to: FrontendUser) => {
    setUsers((prev) => {
      if (!prev) return prev;
      return prev.map((u) => {
        if (u.publicId === to.publicId) {
          return { ...u, permission: "owner" };
        }
        if (u.permission === "owner") {
          return { ...u, permission: "collaborator" };
        }
        return u;
      });
    });
  };

  useEffect(() => {
    // Clear cache when proposal changes
    setUsers(null);
    setError(null);
    refresh();
  }, [details.id, setUsers, setError, refresh]);

  return (
    <OrgUsersProviderContext.Provider
      value={{ users, error, refresh, isCurrentUserOwner, transferedOwnership }}
    >
      {children}
    </OrgUsersProviderContext.Provider>
  );
};

export const useOrgUsers = (): OrgUserProviderData => {
  const data = useContext(OrgUsersProviderContext);
  if (!data) {
    return {
      users: null,
      error: new Error("useOrgUsers must be used within a OrgUserProvider"),
      refresh: () => Promise.resolve(),
      transferedOwnership: () => {},
      isCurrentUserOwner: false,
    };
  }
  return data;
};

export const useOrgUserWithId = (userId: string) => {
  return useOrgUsers().users?.find((u) => u.publicId === userId) || null;
};

export default OrgUserProvider;
