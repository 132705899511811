import Columns from "components/common/containers/Columns";
import Rows from "components/common/containers/Rows";
import Icon, { IconName, IconProps } from "components/common/Icon";
import useSctionDetails from "hooks/section-details/useSectionDetails";
import useIsKeydown from "hooks/useIsKeyDown";
import { cn } from "lib/utils";
import { useApiClient } from "providers/ApiClientProvider";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import { useProposalData } from "providers/ProposalDetailsProvider";
import { useEditorDocData } from "providers/RequirementContentEditorProvider";
import { FC, useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import { message_from_exception } from "utils";
import { SectionMenuInfo } from "../SectionMenuOverlay";
import { useTextAreaConfirm } from "providers/AlertProvider";

interface SectionMenuProps {
  className?: string;
  hasContent: boolean;
  top?: number;
  sectionInfo: SectionMenuInfo;
}

const menuItemSize = 41;

export const getSectionMenuHeight = (generating: boolean) => {
  return generating ? 1 * menuItemSize : 3 * menuItemSize;
};

const SectionMenu: FC<SectionMenuProps> = ({
  className,
  hasContent,
  top,
  sectionInfo,
}) => {
  const apiClient = useApiClient();
  const { setActiveRightPanel } = useEditorDocData();
  const { details: proposalDetails } = useProposalData();
  const { id, name, headingLevel } = useSctionDetails();
  const { isStaff } = useAuthenticatedUser();
  const isShiftKeyDown = useIsKeydown("Shift");
  const textAreaConfirm = useTextAreaConfirm();

  const handleGenerate = useCallback(async () => {
    try {
      let feedback: string | undefined = undefined;
      if (sectionInfo.hasContent) {
        const { id, text } = await textAreaConfirm("Regenerate Draft", {
          placeholder: "How can Odo improve this section?",
          buttons: [
            {
              text: "Cancel",
              id: "cancel",
            },
            {
              text: "Regenerate",
              id: "submit",
              variant: "solid",
            },
          ],
        });

        if (id === "cancel") {
          return;
        }
        feedback = text;
      }

      await apiClient.rfp.rfpProposalRequirementGenerateCreate(
        proposalDetails.id,
        id,
        {
          title: name,
          use_fixture: isStaff && isShiftKeyDown,
          heading_level: headingLevel,
          include_previous_content: true,
          feedback: feedback,
        }
      );
      toast.success("Generation scheduled");
    } catch (e) {
      toast.error(
        "Failed to generate proposal requirement: " + message_from_exception(e)
      );
    }
  }, [
    sectionInfo.hasContent,
    apiClient.rfp,
    proposalDetails.id,
    id,
    name,
    isStaff,
    isShiftKeyDown,
    headingLevel,
    textAreaConfirm,
  ]);

  const handleStopGenerating = useCallback(async () => {
    try {
      await apiClient.rfp.rfpProposalRequirementGenerateDelete(
        proposalDetails.id,
        id
      );
    } catch (e) {
      toast.error("Failed to stop generating: " + message_from_exception(e));
    }
  }, [apiClient.rfp, id, proposalDetails.id]);

  const handleGenerateOutline = useCallback(async () => {
    try {
      await apiClient.rfp.rfpProposalRequirementGenerateCreate(
        proposalDetails.id,
        id,
        {
          title: name,
          use_fixture: isStaff && isShiftKeyDown,
          outline_only: true,
          heading_level: headingLevel,
        }
      );
      toast.success("Generation scheduled");
    } catch (e) {
      toast.error(
        "Failed to generate proposal requirement: " + message_from_exception(e)
      );
    }
  }, [
    apiClient.rfp,
    headingLevel,
    id,
    isShiftKeyDown,
    isStaff,
    name,
    proposalDetails.id,
  ]);

  const menuItems = useMemo<MenuItemProps[]>(() => {
    return [
      {
        icon: "octagon-xmark",
        iconVariant: "solid",
        text: "Stop Generating",
        whileGenerating: true,
        onClick: handleStopGenerating,
        className: "text-destructive",
      },
      {
        icon: "diagram-subtask",
        text: "Insert Subsections",
        whileGenerating: false,
        onClick: handleGenerateOutline,
      },
      {
        icon: "pen",
        iconVariant: "solid",
        text: hasContent ? "Regenerate Draft" : "Generate Draft",
        whileGenerating: false,
        onClick: handleGenerate,
      },
      {
        icon: "book-open-cover",
        iconVariant: "solid",
        text: "Insert Content",
        whileGenerating: false,
        onClick: () => setActiveRightPanel("content-library", true),
      },
    ];
  }, [handleGenerate, handleGenerateOutline, hasContent, setActiveRightPanel]);

  const visibleItems = menuItems.filter((item) => {
    return sectionInfo.isGenerating === item.whileGenerating;
  }, []);

  const visibleHeight = visibleItems.length * menuItemSize;
  const invisibleHeight = menuItems.length * menuItemSize - visibleHeight;

  // When not generating, we want to adjust the top to push hidden items off the top of the screen
  // when generating, we want to adjust the top to push hidden items off the bottom of the screen
  const topOffset: number = sectionInfo.isGenerating ? 0 : -invisibleHeight;

  return (
    <Rows
      className={cn(
        "border drop-shadow border-primary rounded bg-background pointer-events group verflow-hidden items-stretch select-none relative",
        // This comment makes sure the tailwind class is available:
        `w-[41px] transition-[width,height] duration-500`,
        sectionInfo.isGenerating ? "hover:w-[177px]" : "hover:w-[196px]",
        className
      )}
      // Add 2 for the borders
      style={{ top, height: visibleHeight + 2 }}
    >
      <Rows
        className="absolute w-full transition-[top] duration-500"
        style={{ top: topOffset, height: 99999999 }}
      >
        {menuItems.map((item, index) => (
          <MenuItem key={index} {...item} onClick={item.onClick} />
        ))}
      </Rows>
    </Rows>
  );
};

interface MenuItemProps {
  icon: IconName;
  iconVariant?: IconProps["variant"];
  whileGenerating: boolean;
  text: string;
  onClick?: () => void;
  className?: string;
}

const MenuItem: FC<MenuItemProps> = ({
  icon,
  text,
  iconVariant,
  onClick,
  className,
}) => {
  return (
    <Columns
      className={cn(
        "items-center hover:bg-background-selected cursor-pointer text-primary whitespace-nowrap",
        className
      )}
      style={{ height: menuItemSize }}
      onClick={onClick}
    >
      <Icon
        name={icon}
        variant={iconVariant}
        className="text-center shrink-0"
        style={{ width: menuItemSize }}
      />
      <p className=" whitespace-nowrap">{text}</p>
    </Columns>
  );
};

export default SectionMenu;
