import { Toolbar, ToolbarButton, ToolbarGroup } from "./Toolbar";
import { MarkToolbarButton } from "./MarkToolbarButton";
import {
  MARK_BOLD,
  MARK_ITALIC,
  MARK_STRIKETHROUGH,
  MARK_UNDERLINE,
} from "@udecode/plate-basic-marks";
import Icon from "../../common/Icon";
import { TurnIntoDropDownMenu } from "./TurnIntoDropDownMenu";
import { IndentToolbarButton } from "./IndentToolbarButton";
import { OutdentToolbarButton } from "./OutdentToolbarButton";
import { LinkToolbarButton } from "./LinkToolbarButton";
import { emptyContent, odoTagContent } from "odo";
import useAddComment from "../../../lib/plate/plugins/useAddComment";
import { isCollapsed, useEditorRef } from "@udecode/plate-common";
import { MARK_HIGHLIGHT } from "@udecode/plate-highlight";
import { useCallback, useMemo, useState } from "react";
import AddTableView from "./AddTableView";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";
import Overlay from "components/common/containers/overlays/Overlay";

import { MarkdownEncoder } from "odo";
import { toast } from "react-toastify";
import useCurrentFormatting, {
  FormattingType,
} from "hooks/odo-editor/useCurrentFormatting";
import AIActionsMenu from "./AIActionsMenu";
import ImageToolbarButton from "./ImageToolbarButton";

const FixedToolbar = ({ aiEnabled = true, commentsEnabled = true }) => {
  const addComment = useAddComment();
  const editor = useEditorRef();
  const [tableCreationOpen, setTableCreationOpen] = useState(false);
  const { isWriter, hasRegenerate } = useAuthenticatedUser();
  const { marks, elementType, refreshFormatting } = useCurrentFormatting();
  const isIndentable = isElementTypeIndentable(elementType);

  const handleCopyMarkdown = useCallback(() => {
    let elements: any[];
    if (editor.selection && !isCollapsed(editor.selection)) {
      elements = Array.from(
        editor.nodes({
          at: editor.selection,
          match: (element, path) => {
            return path.length === 1;
          },
        })
      ).map(([element]) => element);
    } else {
      elements = editor.children;
    }
    const encoder = new MarkdownEncoder(elements);
    const markdown = encoder.encode();
    navigator.clipboard.writeText(markdown);
    toast.success("Markdown Copied Successfully");
  }, [editor]);

  const content = useMemo(
    () => (
      <Toolbar className="border-b shrink-0">
        <TurnIntoDropDownMenu
          selectedElement={elementType}
          refreshFormatting={refreshFormatting}
        />
        <ToolbarGroup>
          <MarkToolbarButton
            currentMarks={marks}
            nodeType={MARK_BOLD}
            tooltip="Bold (⌘B)"
          >
            <Icon name="bold" />
          </MarkToolbarButton>
          <MarkToolbarButton
            currentMarks={marks}
            nodeType={MARK_ITALIC}
            tooltip="Italic (⌘I)"
          >
            <Icon name="italic" />
          </MarkToolbarButton>
          <MarkToolbarButton
            currentMarks={marks}
            nodeType={MARK_UNDERLINE}
            tooltip="Underline (⌘U)"
          >
            <Icon name="underline" />
          </MarkToolbarButton>
          <MarkToolbarButton
            currentMarks={marks}
            nodeType={MARK_STRIKETHROUGH}
            tooltip="Strikethrough (⌘⇧X)"
          >
            <Icon name="strikethrough" />
          </MarkToolbarButton>
          <MarkToolbarButton
            currentMarks={marks}
            nodeType={MARK_HIGHLIGHT}
            tooltip="Highlight (⌘⌥H)"
          >
            <Icon name="highlighter-line" />
          </MarkToolbarButton>
        </ToolbarGroup>
        <ToolbarGroup>
          <IndentToolbarButton disabled={!isIndentable} />
          <OutdentToolbarButton disabled={!isIndentable} />
        </ToolbarGroup>
        <ToolbarGroup>
          <LinkToolbarButton />
          <ToolbarButton
            tooltip="Insert Table"
            onClick={() => setTableCreationOpen(true)}
          >
            <Icon name="table" />
          </ToolbarButton>
          <ImageToolbarButton />
        </ToolbarGroup>
        {commentsEnabled && (
          <ToolbarGroup>
            <ToolbarButton
              tooltip="Comment (⌘⇧M)"
              onClick={() => addComment(emptyContent)}
              disabled={isCollapsed(editor.selection)}
            >
              <Icon name="comment-plus" />
            </ToolbarButton>
          </ToolbarGroup>
        )}
        {aiEnabled && (hasRegenerate || isWriter) && (
          <>
            <ToolbarGroup>
              <ToolbarButton
                tooltip="Tag Odo in a comment (⌘⇧O)"
                onClick={() => addComment(odoTagContent)}
                disabled={isCollapsed(editor.selection)}
                className="px-md"
                variant="ai"
              >
                @Odo
              </ToolbarButton>
            </ToolbarGroup>
            <ToolbarGroup>
              <AIActionsMenu />
            </ToolbarGroup>
          </>
        )}
        <div className="grow border-l" />
        {isWriter && (
          <ToolbarGroup>
            <ToolbarButton
              onClick={handleCopyMarkdown}
              tooltip="Copy the currently selected content (or the whole doc if nothing is selected) as markdown. This is useful for easily pasting existing content into section details."
            >
              <Icon name="copy" className="admin text-primary" />
            </ToolbarButton>
          </ToolbarGroup>
        )}
        {tableCreationOpen && (
          <Overlay
            title="Add Table"
            maxWidth={360}
            onClose={() => setTableCreationOpen(false)}
          >
            <AddTableView />
          </Overlay>
        )}
      </Toolbar>
    ),
    [
      elementType,
      refreshFormatting,
      marks,
      isIndentable,
      commentsEnabled,
      editor.selection,
      aiEnabled,
      hasRegenerate,
      isWriter,
      handleCopyMarkdown,
      tableCreationOpen,
      addComment,
    ]
  );

  return content;
};

const isElementTypeIndentable = (elementType: FormattingType | null) => {
  return (
    elementType === "p" ||
    elementType === "numbered" ||
    elementType === "bulleted"
  );
};

export default FixedToolbar;
