import { useCallback, useState } from "react";
import useYjsProvider from "./useYjsProvider";
import _useYjsLastChanged from "./_useYjsLastChanged";
import _useYjsConnection from "./_useYjsConnection";

const useYjs = (requirementId: string) => {
  const yjsProvider = useYjsProvider();
  const [ignoreChanges, setIgnoreChanges] = useState(false);
  const lastChanged = _useYjsLastChanged(
    requirementId,
    yjsProvider,
    ignoreChanges
  );
  const status = _useYjsConnection(yjsProvider);

  const whileIgnoringChanges = useCallback(
    async (callback: () => Promise<void>) => {
      setIgnoreChanges(true);
      await callback();
      setIgnoreChanges(false);
    },
    [setIgnoreChanges]
  );

  const reconnect = useCallback(() => {
    if (!yjsProvider) return;

    yjsProvider.configuration.websocketProvider.connect();
  }, [yjsProvider]);

  return { whileIgnoringChanges, lastChanged, status, yjsProvider, reconnect };
};

export default useYjs;
