import { useApiClient } from "providers/ApiClientProvider";
import usePaginatedData, {
  PaginatedData,
  UsePaginatedDataOptions,
} from "./usePaginatedData";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  CoverLetterList,
  OverviewList,
  PastProposalList,
  PersonList,
  ProjectList,
  SubcontractorList,
} from "api/Api";
import { SaveState } from "components/common/forms/SavedStatusIndicator";
import { useAlert } from "providers/AlertProvider";
import { toast } from "react-toastify";
import { message_from_exception } from "utils";

export interface Project {
  id: string;
  in_proposal?: boolean;
  title: string;
  customer: string;

  location: string;
  startMonth: number | null;
  startYear: number | null;
}

export interface Person {
  id: string;
  in_proposal?: boolean;
  name: string;
  title: string;
}

export interface Overview {
  id: string;
  in_proposal?: boolean;
  topic: string;
  created_at: string;
}

export interface CoverLetter {
  id: string;
  in_proposal?: boolean;
  topic: string;
  created_at: string;
}

export interface Subcontractor {
  id: string;
  in_proposal?: boolean;
  name: string;
  created_at: string;
}

export interface PastProposal {
  id: string;
  in_proposal?: boolean;
  name: string;
  created: string;
}

export type LibraryItem =
  | Project
  | Person
  | Overview
  | CoverLetter
  | Subcontractor
  | PastProposal;
export type Category =
  | "projects"
  | "people"
  | "overviews"
  | "cover-letters"
  | "subcontractors"
  | "past-proposals";

interface ContentLibrary {
  // The currently selected high level category
  activeCategory: Category;

  setActiveCategory: (category: Category) => void;

  // The items of the currently selected category (e.g. projects)
  activeItems: LibraryItem[];
  activePaginatedData: PaginatedData;

  // The currently selected item
  selectedItem: LibraryItem | null;
  selectedItemSaveState: SaveState;

  setSelectedItem: (item: LibraryItem | null) => void;
  setSelectedItemSaveState: (state: SaveState) => void;

  // Add a new item to the currently selected category
  addItem: () => void;
  deleteItem: () => Promise<void>;
  updateItem: (item: LibraryItem) => void;
}

const useContentLibrary = (
  initialCategory: Category | undefined,
  initialSelectedItemId: string | undefined,
  proposalId: string | null
): ContentLibrary => {
  const apiClient = useApiClient();
  const alert = useAlert();
  const [activeCategory, setActiveCategory] = useState<Category>(
    initialCategory ?? "projects"
  );
  const [selectedItemSaveState, setSelectedItemSaveState] =
    useState<SaveState>("unchanged");

  const paginatedDataOptions = useMemo<
    UsePaginatedDataOptions<LibraryItem, any>
  >(() => {
    return {
      endpoint: (options) => {
        switch (activeCategory) {
          case "projects":
            // @ts-ignore
            return apiClient.rfp.rfpContentLibraryProjectList({
              ...options,
              proposal_id: proposalId,
            });
          case "people":
            // @ts-ignore
            return apiClient.rfp.rfpContentLibraryPersonList({
              ...options,
              proposal_id: proposalId,
            });
          case "overviews":
            // @ts-ignore
            return apiClient.rfp.rfpContentLibraryOverviewList({
              ...options,
              proposal_id: proposalId,
            });
          case "cover-letters":
            // @ts-ignore
            return apiClient.rfp.rfpContentLibraryCoverLetterList({
              ...options,
              proposal_id: proposalId,
            });
          case "subcontractors":
            // @ts-ignore
            return apiClient.rfp.rfpContentLibrarySubcontractorList({
              ...options,
              proposal_id: proposalId,
            });
          case "past-proposals":
            return apiClient.rfp.rfpProposalPastList(proposalId!, options);
        }
      },
      map: (project) => {
        return itemFromRemote(project, activeCategory);
      },
      deps: [activeCategory],
    };
  }, [activeCategory, apiClient.rfp, proposalId]);

  const [items, setItems, paginatedData] = usePaginatedData<LibraryItem, any>(
    paginatedDataOptions
  );
  const [selectedItemId, setSelectedItemId] = useState<string | null>(
    initialSelectedItemId ?? null
  );

  const selectedItem = items?.find((i) => i.id === selectedItemId) ?? null;

  const addItem = useCallback(async () => {
    try {
      const options = { query: { proposal_id: proposalId } } as any;
      switch (activeCategory) {
        case "projects":
          const result =
            await apiClient.rfp.rfpContentLibraryProjectCreateCreate(options);
          const item = itemFromRemote(result.data, activeCategory);
          setItems((prev) => [item, ...(prev || [])]);
          setSelectedItemId(item.id);
          break;
        case "people":
          const personResult =
            await apiClient.rfp.rfpContentLibraryPersonCreateCreate(options);
          const person = itemFromRemote(personResult.data, activeCategory);
          setItems((prev) => [person, ...(prev || [])]);
          setSelectedItemId(person.id);
          break;
        case "overviews":
          const overviewResult =
            await apiClient.rfp.rfpContentLibraryOverviewCreateCreate(options);
          const overview = itemFromRemote(overviewResult.data, activeCategory);
          setItems((prev) => [overview, ...(prev || [])]);
          setSelectedItemId(overview.id);
          break;
        case "cover-letters":
          const coverLetterResult =
            await apiClient.rfp.rfpContentLibraryCoverLetterCreateCreate(
              options
            );
          const coverLetter = itemFromRemote(
            coverLetterResult.data,
            activeCategory
          );
          setItems((prev) => [coverLetter, ...(prev || [])]);
          setSelectedItemId(coverLetter.id);
          break;
        case "subcontractors":
          const subcontractorResult =
            await apiClient.rfp.rfpContentLibrarySubcontractorCreateCreate(
              options
            );
          const subcontractor = itemFromRemote(
            subcontractorResult.data,
            activeCategory
          );
          setItems((prev) => [subcontractor, ...(prev || [])]);
          setSelectedItemId(subcontractor.id);
          break;
        case "past-proposals":
          throw new Error("Cannot add past proposals");
      }
    } catch (e) {
      toast.error("Failed to create item: " + message_from_exception(e));
    }
  }, [proposalId, activeCategory, apiClient.rfp, setItems]);

  const deleteItem = useCallback(async () => {
    const item = selectedItem;
    if (!item) return;
    try {
      const options = { query: { proposal_id: proposalId } } as any;
      switch (activeCategory) {
        case "projects":
          if (!item.id) return;
          await apiClient.rfp.rfpContentLibraryProjectDelete(item.id, options);
          break;
        case "people":
          if (!item.id) return;
          await apiClient.rfp.rfpContentLibraryPersonDelete(item.id, options);
          break;
        case "overviews":
          if (!item.id) return;
          await apiClient.rfp.rfpContentLibraryOverviewDelete(item.id, options);
          break;
        case "cover-letters":
          if (!item.id) return;
          await apiClient.rfp.rfpContentLibraryCoverLetterDelete(
            item.id,
            options
          );
          break;
        case "subcontractors":
          if (!item.id) return;
          await apiClient.rfp.rfpContentLibrarySubcontractorDelete(
            item.id,
            options
          );
          break;
      }
      setItems((prev) => {
        if (!prev) return null;
        return prev.filter((i) => i.id !== item.id);
      });
      setSelectedItemId(null);
    } catch (e) {
      toast.error("Failed to delete item: " + message_from_exception(e));
    }
  }, [activeCategory, apiClient.rfp, proposalId, selectedItem, setItems]);

  const updateItem = useCallback(
    async (item: LibraryItem) => {
      setItems((prev) => {
        if (!prev) return null;
        return prev.map((i) => (i.id === item.id ? item : i));
      });
    },
    [setItems]
  );

  const setSelectedItem = useCallback(
    (item: LibraryItem | null) => {
      if (
        selectedItemSaveState === "saving" ||
        selectedItemSaveState === "error"
      ) {
        alert("The current project is still saving");
        return;
      }

      setSelectedItemId(item?.id ?? null);
      setSelectedItemSaveState("unchanged");
    },
    [alert, selectedItemSaveState]
  );

  return {
    activeCategory: activeCategory,
    activeItems: items || [],
    activePaginatedData: paginatedData,
    selectedItem,
    selectedItemSaveState,
    updateItem,
    setSelectedItemSaveState,
    setActiveCategory,
    setSelectedItem,
    addItem,
    deleteItem,
  };
};

const projectFromRemote = (remote: ProjectList): Project => {
  return {
    id: remote.id!,
    in_proposal: remote.in_proposal ?? false,
    title: remote.title ?? "",
    customer: remote.customer ?? "",
    location: remote.location ?? "",
    startMonth: remote.start_month ?? null,
    startYear: remote.start_year ?? null,
  };
};

const personFromRemote = (remote: PersonList): Person => {
  return {
    id: remote.id!,
    in_proposal: remote.in_proposal ?? false,
    name: remote.name ?? "",
    title: remote.title ?? "",
  };
};

const overviewFromRemote = (remote: OverviewList): Overview => {
  return {
    id: remote.id!,
    in_proposal: remote.in_proposal ?? false,
    topic: remote.topic ?? "",
    created_at: remote.created_at!,
  };
};

const coverLetterFromRemote = (remote: CoverLetterList): CoverLetter => {
  return {
    id: remote.id!,
    in_proposal: remote.in_proposal ?? false,
    topic: remote.topic ?? "",
    created_at: remote.created_at!,
  };
};

const subcontractorFromRemote = (remote: SubcontractorList): Subcontractor => {
  return {
    id: remote.id!,
    in_proposal: remote.in_proposal ?? false,
    name: remote.name ?? "",
    created_at: remote.created_at!,
  };
};

const pastProposalFromRemote = (remote: PastProposalList): PastProposal => {
  return {
    id: remote.id!,
    in_proposal: remote.in_proposal ?? false,
    name: remote.name ?? "",
    created: remote.created!,
  };
};

export const itemFromRemote = (
  remote: any,
  category: Category
): LibraryItem => {
  switch (category) {
    case "projects":
      return projectFromRemote(remote);
    case "people":
      return personFromRemote(remote);
    case "overviews":
      return overviewFromRemote(remote);
    case "cover-letters":
      return coverLetterFromRemote(remote);
    case "subcontractors":
      return subcontractorFromRemote(remote);
    case "past-proposals":
      return pastProposalFromRemote(remote);
    default:
      throw new Error(`Unknown category: ${category}`);
  }
};

export const singularNameFromCategory = (category: Category): string => {
  switch (category) {
    case "projects":
      return "Project";
    case "people":
      return "Person";
    case "overviews":
      return "Overview";
    case "cover-letters":
      return "Cover Letter";
    case "subcontractors":
      return "Subcontractor";
    case "past-proposals":
      return "Past Proposal";
  }
};

export default useContentLibrary;
