export const yjsProviderFromEditor = (editor) => {
    if (!("yjs" in editor)) {
        return null;
    }
    if (!("provider" in editor.yjs)) {
        return null;
    }
    if (!editor.yjs.provider) {
        return null;
    }
    return editor.yjs.provider;
};
