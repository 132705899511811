import { Api, ApiConfig } from "api/Api";
import { setRedirectAfterLogin } from "utils";
import log, { LogType } from "log";

import * as Sentry from "@sentry/react";
import { posthog } from "posthog-js";

export interface PaginatedResponse<T> {
  results: T[];
  next?: string | null;
  previous?: string | null;
  page?: number;
  page_count?: number;
}

class CoreApi extends Api<unknown> {
  canDebug: boolean = false;

  constructor(config: ApiConfig<unknown> = {}) {
    super(config);

    this.instance.interceptors.response.use(undefined, async (error) => {
      const originalConfig = error.config;
      if (error.response) {
        switch (error.response.status) {
          // Note: 403 is reserved for permission errors (don't want to redirect the user to login)
          case 401:
            // Attempt to refresh the access token
            if (
              originalConfig._retry ||
              originalConfig.refreshing ||
              originalConfig.url.endsWith("/token/")
            ) {
              // Was not able to refresh because:
              // 1. This is the refreshing request and it failed
              // 2. This is the retry of the request after refreshing and it still got a 401
              // 3. This is the original request to get tokens
              // Ask to login again
              this.logout();
              return Promise.reject(error);
            } else {
              originalConfig._retry = true;
              await this.refreshToken();
              log(LogType.Auth, "token refreshed");
              return this.instance(originalConfig);
            }
        }
      }

      return Promise.reject(error);
    });
  }

  public recordMetric(id: string) {
    // Don't need to wait for this to finish since the link is opened in a new tab
    this.metrics
      .metricsEventsCreate({
        kind: id.toLowerCase(),
      })
      .catch((e) => {
        /* Do Nothing */
      });
  }

  public logout() {
    Sentry.setUser(null);
    posthog.identify(undefined);

    if (
      window.location.pathname !== "/" &&
      !window.location.pathname.startsWith("/verify") &&
      !window.location.pathname.startsWith("/change-password") &&
      !window.location.pathname.endsWith("/login") &&
      !window.location.pathname.startsWith("/unsubscribe") &&
      !window.location.pathname.startsWith("/final-check")
    ) {
      setRedirectAfterLogin();
      window.location.href = "/login";
    }
  }

  public async refreshToken() {
    await this.auth.authTokenRefreshCreate({ refreshing: true } as any);
  }

  public async postStreamingResponse(path: string, data: any) {
    let baseUrl = this.instance.defaults.baseURL!;
    if (!baseUrl.endsWith("/")) {
      baseUrl += "/";
    }
    let normalizedPath = path;
    if (normalizedPath.startsWith("/")) {
      normalizedPath = normalizedPath.substring(1);
    }
    const url = baseUrl + normalizedPath;
    return await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  public async fetchFromCursor<T>(cursor: string) {
    // Cursor is a full url
    if (!cursor.startsWith(this.instance.defaults.baseURL!)) {
      throw Error("Cannot fetch from another domain");
    }

    // Get just the path from the url
    const path = cursor.split(this.instance.defaults.baseURL!)[1];

    return await this.request<PaginatedResponse<T>>({
      path: path,
      method: "GET",
    });
  }

  static async messageFromResponse(response: Response, defaultError: string) {
    try {
      const obj = await response.json();
      if (obj.error) {
        if (typeof obj.error === "string") {
          return obj.error;
        } else {
          if (obj.error.message) {
            if (typeof obj.error.message === "string") {
              return obj.error.message;
            } else {
              return JSON.stringify(obj.error.message);
            }
          } else {
            return JSON.stringify(obj.error);
          }
        }
      } else {
        return JSON.stringify(obj);
      }
    } catch {
      try {
        return await response.text();
      } catch {
        return defaultError;
      }
    }
  }
}
export default CoreApi;
