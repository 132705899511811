import Button from "components/common/Button";
import CenteredContainer from "components/common/containers/CenteredContainer";
import Rows from "components/common/containers/Rows";
import MultiFileInput, {
  MultiFileInputRef,
} from "components/common/forms/MultiFileInput";
import Spinner from "components/common/Spinner";
import useFileHash from "hooks/useFileHash";
import { useApiClient } from "providers/ApiClientProvider";
import { FC, useRef, useState } from "react";
import { message_from_exception } from "utils";
import { v4 } from "uuid";

export interface RFPLocalFile {
  id: string;
  name: string;
  file: File;
}

interface AddRFPFormProps {
  forAdmin: boolean;
  onRfpUploaded?: (rfpId: string) => Promise<void>;
  confirmText?: string;
}

const AddRFPForm: FC<AddRFPFormProps> = ({
  onRfpUploaded,
  forAdmin,
  confirmText,
}) => {
  const apiClient = useApiClient();
  const fileInputRef = useRef<MultiFileInputRef | null>(null);
  const [files, setFiles] = useState<RFPLocalFile[]>([]);
  const hashFile = useFileHash();
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>();

  const upload = async () => {
    if (files.length === 0) {
      return;
    }
    setIsUploading(true);

    try {
      const name = files[0].name.split(".").slice(0, -1).join(".");
      const hash = await hashFile(files[0].file);
      const createResponse = await apiClient.rfp.rfpRfpCreate({
        name,
        hash,
        for_admin: forAdmin,
      });

      const rfpId = createResponse.data.id;
      // Upload each of the files
      for (const localFile of files) {
        const components = localFile.name.split(".");
        const name = components.slice(0, -1).join(".");
        const extension = components.pop() ?? "";
        const createFileResponse = await apiClient.rfp.rfpRfpFilesCreate(
          rfpId,
          {
            name,
            extension,
          }
        );

        await fetch(createFileResponse.data.upload_url!, {
          method: "PUT",
          body: localFile.file,
        });

        await apiClient.rfp.rfpRfpFilesPartialUpdate(
          rfpId,
          createFileResponse.data.id!.toString(),
          {
            uploaded: true,
          } as any
        );
      }

      // Mark the RFP as fully uploaded
      await apiClient.rfp.rfpRfpUploadedCreate(rfpId);

      await onRfpUploaded?.(rfpId);
    } catch (e) {
      setError(message_from_exception(e));
    } finally {
      setIsUploading(false);
      fileInputRef.current?.clear();
    }
  };

  if (error) {
    return (
      <Rows className="gap-md">
        <p className="text-destructive text-center">{error}</p>
        <Button
          variant="solid"
          text="Try Again"
          onClick={() => setError(null)}
        />
      </Rows>
    );
  }

  if (!!isUploading) {
    return (
      <CenteredContainer>
        <Spinner className="mx-auto" />
      </CenteredContainer>
    );
  }

  return (
    <Rows className="gap-md w-full">
      <MultiFileInput<RFPLocalFile>
        ref={fileInputRef}
        renderFile={(file) => <p className="text-center">{file.name}</p>}
        renderPendingFile={(file) => (
          <p className="text-center">{file.name} is uploading...</p>
        )}
        onFilesChanged={(files) => {
          setFiles(files);
        }}
        processFile={async (file) => {
          return { id: v4(), name: file.name, file };
        }}
      />
      <Button
        text={confirmText ?? "Add RFP"}
        variant="solid"
        disabled={files.length === 0}
        onClick={upload}
      />
    </Rows>
  );
};

export default AddRFPForm;
