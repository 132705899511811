import StartNewProposalOverlay from "app/home/StartNewProposalOverlay";
import Button from "components/common/Button";
import { useTextAreaConfirm } from "providers/AlertProvider";
import { useApiClient } from "providers/ApiClientProvider";
import { FC, useEffect, useState } from "react";
import { RFPOrgComparison } from "types/RFPOrgComparison";

interface RFPComparisonOptionsViewProps {
  comparison: RFPOrgComparison;
  hideRFP: () => void;
}

const RFPComparisonOptionsView: FC<RFPComparisonOptionsViewProps> = ({
  comparison,
  hideRFP,
}) => {
  const textAreaConfirm = useTextAreaConfirm();
  const apiClient = useApiClient();
  const [starting, setStarting] = useState(false);
  const [didStart, setDidStart] = useState(false);

  useEffect(() => {
    if (!starting && didStart) {
      hideRFP();
      setDidStart(false);
    }
  }, [didStart, hideRFP, starting]);

  const handleDecline = async () => {
    const result = await textAreaConfirm("Decline Reason (optional)", {
      placeholder:
        "Let us know why this isn't a good match to improve our future recommendations",
      height: 100,
      buttons: [
        { id: "cancel", text: "Cancel", variant: "outline" },
        {
          id: "decline",
          text: "Decline",
          variant: "destructive",
          icon: "xmark",
        },
      ],
    });
    if (result.id === "decline") {
      await apiClient.rfp.rfpComparisonFeedbackCreate(comparison.id, {
        feedback: result.text,
      });
      hideRFP();
    }
  };

  return (
    <>
      <Button
        text="Decline"
        variant="outline"
        icon="xmark"
        onClick={handleDecline}
      />
      <Button
        text="Start Proposal"
        variant="solid"
        icon="file-plus"
        iconVariant="solid"
        onClick={() => setStarting(true)}
      />
      <StartNewProposalOverlay
        open={starting}
        existingRfp={{ id: comparison.rfpId, title: comparison.title }}
        onStarted={() => setDidStart(true)}
        onClose={() => setStarting(false)}
      />
    </>
  );
};

export default RFPComparisonOptionsView;
