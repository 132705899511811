import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import useYjs from "../hooks/yjs/useYjs";
import CommentsProvider, {
  useSetCommentsYjsProvider,
} from "./CommentsProvider";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { PresentUsersProvider, useSetAwareness } from "./PresentUserProvider";
import { DocConnectionStatus } from "hooks/yjs/_useYjsConnection";
import { useProposalData } from "./ProposalDetailsProvider";
import {
  PlateEditor,
  TElement,
  useEditorRef,
  useEditorSelection,
  Value,
} from "@udecode/plate-common";
import { BaseSelection } from "slate";
import { getSectionInfoFromElement, SectionInfo } from "odo";
import useLocalStorage from "lib/useLocalStorage";
import { ReactEditor } from "slate-react";

export type DropLocation = "above" | "below" | "into";

export interface ActiveSection {
  id: string;
  name: string;
  mode: DropLocation | "active";
  level: number;
}

interface DropSection {
  id: string;
  name: string;
  location: DropLocation;
}

export type RightPanel =
  | "section-details"
  | "outline-verification"
  | "content-library";
export type LeftPanel = "outline-nav" | "user-choices";

interface RequirementContentEditorData {
  // The container for just the doc view (centered in the container)
  containerRef: React.RefObject<HTMLDivElement>;
  // The entire canvas available for the doc view (including margins)
  canvasRef?: React.RefObject<HTMLDivElement>;
  lastChanged: string | undefined | null;
  whileIgnoringChanges: (callback: () => Promise<void>) => Promise<void>;
  status: DocConnectionStatus;

  reconnect: () => void;

  configuringRegeneration: boolean;
  setConfiguringRegeneration: (configuring: boolean) => void;

  // The section whos details are being displayed right now
  activeSection: ActiveSection | null;
  // The section where the carrot currently is
  cursorFocusedSection: { id: string; name: string } | null;
  activeRightPanel?: RightPanel | null;
  setActiveRightPanel: (panel: RightPanel | null, highlight?: boolean) => void;
  highlightRightPanel: boolean;
  activeLeftPanel?: LeftPanel | null;
  setActiveLeftPanel: (panel: LeftPanel | null) => void;

  droppingSection: DropSection | null;
  setDroppingSection: (section: DropSection | null) => void;

  highlightedRequirements: string[];
  highlightedBlocks: number[];
  setHighlight: (requirements: string[], blocks: number[]) => void;

  scrollToElement: (
    mathing: (element: TElement, path: number[]) => boolean
  ) => void;
}

const RequirementContentEditorContext = createContext<
  RequirementContentEditorData | undefined
>(undefined);

export interface EditorDocProviderProps {
  children: ReactNode;
  containerRef: React.RefObject<HTMLDivElement>;
  canvasRef: React.RefObject<HTMLDivElement>;
}

const RequirementContentEditorProvider: React.FC<EditorDocProviderProps> = ({
  children,
  containerRef,
  canvasRef,
}) => {
  const { details } = useProposalData();
  const editor = useEditorRef();
  const selection = useEditorSelection();
  const { whileIgnoringChanges, lastChanged, status, yjsProvider, reconnect } =
    useYjs(details.id);
  const [activeRightPanel, doSetActiveRightPanel] =
    useLocalStorage<RightPanel | null>("active-right-panel", null);
  const [highlightRightPanel, setHighlightRightPanel] = useState(false);
  const [activeLeftPanel, setActiveLeftPanel] =
    useLocalStorage<LeftPanel | null>("active-left-panel", "outline-nav");
  const [droppingSection, setDroppingSection] =
    React.useState<DropSection | null>(null);
  const [cursorFocusedSection, setCursorFocusedSection] = React.useState<{
    id: string;
    name: string;
    level: number;
  } | null>(null);
  const [activeSection, setActiveSection] =
    React.useState<ActiveSection | null>(null);
  const [highlightedRequirements, setHighlightedRequirements] = React.useState<
    string[]
  >([]);
  const [highlightedBlocks, setHighlightedBlocks] = React.useState<number[]>(
    []
  );
  const [configuringRegeneration, setConfiguringRegeneration] =
    React.useState(false);

  const focusedPath = JSON.stringify(selection?.focus.path ?? []);
  // Get the focused section
  useEffect(() => {
    if (!selection) {
      setCursorFocusedSection(null);
      return;
    }

    const focusedSection = getFocusedSection(editor, selection);
    setCursorFocusedSection((prev) => {
      if (prev?.id !== focusedSection?.id) {
        return focusedSection;
      }
      return prev;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focusedPath, editor]);

  useEffect(() => {
    if (droppingSection) {
      setActiveSection({
        id: droppingSection.id,
        name: droppingSection.name,
        mode: droppingSection.location,
        level: 0,
      });
    } else if (cursorFocusedSection) {
      setActiveSection({
        id: cursorFocusedSection.id,
        name: cursorFocusedSection.name,
        mode: "active",
        level: cursorFocusedSection.level,
      });
    } else {
      setActiveSection(null);
    }
  }, [droppingSection, cursorFocusedSection]);

  const scrollToElement = useCallback(
    (matching: (element: TElement, path: number[]) => boolean) => {
      if (!canvasRef.current) return;
      let index = 0;
      for (const child of editor.children) {
        if (matching(child, [index])) {
          const node = ReactEditor.toDOMNode(editor as any, child);
          node.scrollIntoView({ behavior: "smooth", block: "start" });
          return;
        }
        index += 1;
      }
    },
    [canvasRef, editor]
  );

  const setHighlight = useCallback(
    (requirements: string[], blocks: number[]) => {
      setHighlightedRequirements(requirements);
      setHighlightedBlocks(blocks);
    },
    [setHighlightedBlocks, setHighlightedRequirements]
  );

  const setActiveRightPanel = useCallback(
    (panel: RightPanel | null, highlight = false) => {
      doSetActiveRightPanel((prev) => {
        if (prev === panel) {
          setHighlightRightPanel(highlight);
          return prev;
        }
        return panel;
      });
    },
    [doSetActiveRightPanel, setHighlightRightPanel]
  );

  useEffect(() => {
    if (!highlightRightPanel) return;
    setTimeout(() => {
      setHighlightRightPanel(false);
    }, 500);
  }, [highlightRightPanel, setHighlightRightPanel]);

  return (
    <RequirementContentEditorContext.Provider
      value={{
        status,
        reconnect,
        containerRef,
        canvasRef,
        whileIgnoringChanges,
        lastChanged,
        configuringRegeneration,
        cursorFocusedSection: cursorFocusedSection,
        setConfiguringRegeneration,
        activeRightPanel,
        setActiveRightPanel,
        highlightRightPanel,
        activeLeftPanel,
        setActiveLeftPanel,
        highlightedRequirements,
        highlightedBlocks,
        setHighlight,
        scrollToElement,
        droppingSection,
        setDroppingSection,
        activeSection,
      }}
    >
      <CommentsProvider>
        <PresentUsersProvider>
          <RequirementContentEditorProviderContent yjsProvider={yjsProvider}>
            {children}
          </RequirementContentEditorProviderContent>
        </PresentUsersProvider>
      </CommentsProvider>
    </RequirementContentEditorContext.Provider>
  );
};

export const useEditorDocData = (): RequirementContentEditorData => {
  const data = useContext(RequirementContentEditorContext);

  if (data === undefined) {
    return {
      containerRef: React.createRef<HTMLDivElement>(),
      canvasRef: React.createRef<HTMLDivElement>(),
      lastChanged: "unknown",
      status: "connecting",
      reconnect: () => {},
      whileIgnoringChanges: async () => {},
      configuringRegeneration: false,
      setConfiguringRegeneration: () => {},
      activeSection: null,
      cursorFocusedSection: null,
      activeRightPanel: null,
      highlightRightPanel: false,
      setActiveRightPanel: () => {},
      activeLeftPanel: null,
      setActiveLeftPanel: () => {},
      droppingSection: null,
      setDroppingSection: () => {},
      highlightedRequirements: [],
      highlightedBlocks: [],
      scrollToElement: () => {},
      setHighlight: () => {},
    };
  }
  return data;
};

interface RequirementContentEditorProviderContentProps {
  children: ReactNode;
  yjsProvider: HocuspocusProvider | null;
}

const RequirementContentEditorProviderContent: React.FC<
  RequirementContentEditorProviderContentProps
> = ({ children, yjsProvider }) => {
  const setCommentsYjsProvider = useSetCommentsYjsProvider();
  const setAwareness = useSetAwareness();

  useEffect(() => {
    // Apply the Yjs provider to the comments and suggestions providers
    setCommentsYjsProvider(yjsProvider);

    if (yjsProvider) {
      setAwareness(yjsProvider.awareness);
    } else {
      setAwareness(null);
    }

    return () => {
      setCommentsYjsProvider(null);
    };
  }, [yjsProvider, setCommentsYjsProvider, setAwareness]);

  return <>{children}</>;
};

const getFocusedSection = (
  editor: PlateEditor<Value>,
  selection: BaseSelection
): SectionInfo | null => {
  const path = selection?.focus?.path;
  if (!path) return null;

  // First, check if the path is directly in a heading
  let index = path[0];
  // Find the closest heading before the selection
  while (index >= 0) {
    const [element] = editor.node([index]);
    const sectionInfo = getSectionInfoFromElement(element);
    if (sectionInfo) {
      return sectionInfo;
    }
    index -= 1;
  }

  return null;
};

export default RequirementContentEditorProvider;
