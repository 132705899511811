import Button from "components/common/Button";
import Columns from "components/common/containers/Columns";
import { FlowContainerRef } from "components/common/containers/flow-container/FlowContainer";
import Rows from "components/common/containers/Rows";
import Spacer from "components/common/containers/Spacer";
import TextArea from "components/common/forms/TextArea";
import useFetchedData from "hooks/useFetchedData";
import { useApiClient } from "providers/ApiClientProvider";
import { FC, useEffect } from "react";

interface ApproachFlowStepProps {
  rfpId: string;
  approach: string;
  setApproach: (value: string) => void;
  flowRef: FlowContainerRef | null;
}

const ApproachFlowStep: FC<ApproachFlowStepProps> = ({
  rfpId,
  approach,
  setApproach,
  flowRef,
}) => {
  const apiClient = useApiClient();
  const [rfpDetails, , { refresh }] = useFetchedData(async () => {
    const response = await apiClient.rfp.rfpRfpRead(rfpId);
    return response.data;
  }, [rfpId]);

  useEffect(() => {
    if (!flowRef) return;

    const handleStepChange = (step: number) => {
      console.log("refresh");
      refresh();
    };

    flowRef.observeCurrentStep(handleStepChange);
    return () => {
      flowRef.unobserveCurrentStep(handleStepChange);
    };
  }, [flowRef, refresh]);

  return (
    <Rows className="gap-lg w-full max-w-[600px] grow max-h-[260px] mt-lg">
      <TextArea
        value={approach}
        onChange={setApproach}
        className="grow resize-none px-2m py-md"
        placeholder={
          rfpDetails?.start_questions && rfpDetails.start_questions.length > 0
            ? "You may consider: " + rfpDetails.start_questions[0]
            : "What will make your company stand out?"
        }
      />
      <Columns className="items-center gap-md shrink-0">
        <Spacer />
        {!approach && <Button text="Skip" onClick={() => flowRef?.goNext()} />}
        <Button
          text="Continue"
          variant="solid"
          disabled={!approach}
          size="large"
          className="w-[150px]"
          onClick={() => flowRef?.goNext()}
        />
      </Columns>
    </Rows>
  );
};

export default ApproachFlowStep;
