import { useEditorRef, useEditorSelection } from "@udecode/plate-common";
import { isEmptyContent, isOdoHeading, isOdoRefine } from "odo";
import { getElement } from "providers/DocElementRefProvider";
import { useEditorDocData } from "providers/RequirementContentEditorProvider";
import { FC, useEffect, useState } from "react";
import SectionMenu, { getSectionMenuHeight } from "./Menus/SectionMenu";
import { useDroppable } from "@dnd-kit/core";
import { cn } from "lib/utils";

interface SectionMenuOverlayProps {}

interface PendingSection {
  id: string;
  firstElement: HTMLElement;
  hasContent: boolean;
  isGenerating: boolean;
  isActive: boolean;
}

export interface SectionMenuInfo extends PendingSection {
  lastElement: HTMLElement;
}

const SectionMenuOverlay: FC<SectionMenuOverlayProps> = () => {
  const [sectionsWithMenu, setSectionsWithMenu] = useState<SectionMenuInfo[]>(
    []
  );

  const editor = useEditorRef();
  const { containerRef, activeSection } = useEditorDocData();
  const selection = useEditorSelection();
  const { active: isDragging } = useDroppable({ id: "section-menu" });
  const [scrollOffset, setScrollOffset] = useState(0);

  useEffect(() => {
    if (!containerRef.current) return;
    const scrollable = getScrollableParent(containerRef.current);
    if (!scrollable) return;

    const onScroll = () => {
      setScrollOffset(scrollable.scrollTop);
    };

    scrollable.addEventListener("scroll", onScroll);
    return () => {
      scrollable.removeEventListener("scroll", onScroll);
    };
  }, [containerRef]);

  useEffect(() => {
    // Get the sections that should have menus
    // 1. If the section is active
    // 2. If the section has a refine element (is being written to)

    let sectionsWithMenu: SectionMenuInfo[] = [];

    let activeSectionId: string | null = null;
    if (activeSection && !isDragging) {
      switch (activeSection.mode) {
        case "above":
        case "below":
          break;
        case "into":
        case "active":
          activeSectionId = activeSection.id;
      }
    }

    // Loop through eacdh child of the editor, tracking the 1st and last elements of
    // each section and adding them to the sectionsWithMenu array when appropriate
    let pendingSection: PendingSection | null = null;
    let previousElementId: string | null = null;
    for (const child of editor.children) {
      if (isOdoHeading(child)) {
        // We found a new section

        // Check if there is already a pending section
        if (pendingSection && previousElementId) {
          // We need to add this pending section to the sectionsWithMenu array
          const lastElement = getElement(editor, previousElementId);
          if (lastElement) {
            sectionsWithMenu.push({
              ...pendingSection,
              lastElement,
            });
          }
        }

        // Create a new pending section
        pendingSection = null;
        if (child.id) {
          const element = getElement(editor, child.id);
          if (element) {
            pendingSection = {
              id: child.id,
              firstElement: element,
              hasContent: false,
              isGenerating: false,
              isActive: child.id === activeSectionId,
            };
          }
        }
      } else if (pendingSection) {
        // We found a non-heading child while we have a pending section
        // Check if we need to update any properties of the pending section
        if (isOdoRefine(child)) {
          pendingSection.isGenerating = true;
          pendingSection.hasContent = true;
        } else {
          pendingSection.hasContent =
            pendingSection.hasContent || !isEmptyContent([child]);
        }
      }

      previousElementId = (child.id ?? null) as string;
    }

    if (pendingSection && previousElementId) {
      const lastElement = getElement(editor, previousElementId);
      if (lastElement) {
        sectionsWithMenu.push({
          ...pendingSection,
          lastElement,
        });
      }
    }

    setSectionsWithMenu(sectionsWithMenu);
  }, [activeSection, editor, selection, isDragging]);

  // Get the rects within the container
  const container = containerRef.current;
  return (
    <div className="absolute -left-[50px]">
      {container &&
        sectionsWithMenu &&
        sectionsWithMenu
          .filter((section) => section.isActive || section.isGenerating)
          .map((section) => {
            const rect = getRectForElements(
              section.firstElement,
              section.lastElement,
              container,
              "right"
            );
            return (
              <>
                <div
                  className={cn(
                    "border-r-[1px] border-ashed absolute bottom-0 right-0 border-primary"
                  )}
                  style={rect}
                ></div>
                <SectionMenu
                  sectionInfo={section}
                  className={cn("absolute  -top-sm -right-[21px]")}
                  top={Math.max(
                    Math.min(
                      scrollOffset,
                      rect.top +
                        rect.height -
                        getSectionMenuHeight(section.isGenerating)
                    ),
                    rect.top - 8
                  )}
                  hasContent={section.hasContent}
                />
              </>
            );
          })}
    </div>
  );
};

const getRectForElements = (
  topElement: HTMLElement,
  bottomElement: HTMLElement,
  container: HTMLElement,
  mode: "overlay" | "right"
) => {
  const containerRect = container.getBoundingClientRect();
  const topElementRect = topElement.getBoundingClientRect();
  const bottomElementRect = bottomElement.getBoundingClientRect();

  let top = Math.min(topElementRect.top, bottomElementRect.top);
  let left = Math.min(topElementRect.left, bottomElementRect.left);
  const bottom = Math.max(topElementRect.bottom, bottomElementRect.bottom);
  const right = Math.max(topElementRect.right, bottomElementRect.right);

  let height = bottom - top;
  let width = right - left;

  // Convert to relative position
  top -= containerRect.top;
  left -= containerRect.left;

  // Add padding
  const padding = 8;
  top -= 10;
  // top -= padding;
  left -= padding;
  width += padding * 2;

  switch (mode) {
    case "overlay":
      return { top, left, height, width };
    case "right":
      return { top, left: 0, height, width: 1 };
  }
};

function getScrollableParent(element: HTMLElement | null): HTMLElement | null {
  // If there is no element, return null.
  if (!element) {
    return null;
  }

  // Define the regex to match overflow properties that allow scrolling.
  const overflowRegex = /(auto|scroll)/;

  // Traverse up the DOM tree.
  let parent: HTMLElement | null = element.parentElement;

  while (parent) {
    const style = window.getComputedStyle(parent);

    // Check if the element is scrollable by looking at its overflow properties.
    if (overflowRegex.test(style.overflowY)) {
      return parent;
    }

    parent = parent.parentElement;
  }

  // If no scrollable parent is found, return the scrolling element of the document.
  return (
    (document.scrollingElement as HTMLElement | null) ||
    document.documentElement
  );
}

export default SectionMenuOverlay;
