import {
  LeftPanel,
  useEditorDocData,
} from "providers/RequirementContentEditorProvider";
import { FC } from "react";
import OutlineNavPanel from "./OutlineNavPanel";
import Rows from "components/common/containers/Rows";
import Button from "components/common/Button";
import { cn } from "lib/utils";
import UserChoicesPanel from "./UserChoicesPanel";
import { useAuthenticatedUser } from "providers/AuthenticatedUserProvider";

export const LeftEditorToolbar: FC = () => {
  const { activeLeftPanel, setActiveLeftPanel } = useEditorDocData();
  const { isWriter } = useAuthenticatedUser();

  const getClickHandler = (panel: LeftPanel) => () => {
    if (activeLeftPanel === panel) {
      setActiveLeftPanel(null);
    } else {
      setActiveLeftPanel(panel);
    }
  };

  return (
    <Rows className="shrink-0 border-r">
      <Button
        tooltip="Outline"
        icon="list"
        size="large"
        variant="toolbar"
        className={cn(
          activeLeftPanel === "outline-nav" &&
            "border-l-primary hover:border-l-primary"
        )}
        onClick={getClickHandler("outline-nav")}
      />
      {isWriter && (
        <Button
          tooltip="User Choices"
          icon="clipboard-list"
          size="large"
          variant="toolbar"
          className={cn(
            activeLeftPanel === "user-choices" &&
              "border-l-primary hover:border-l-primary admin"
          )}
          onClick={getClickHandler("user-choices")}
        />
      )}
    </Rows>
  );
};

export const useLeftEditorContent = () => {
  const { activeLeftPanel } = useEditorDocData();

  let activePanelComponent = null;
  switch (activeLeftPanel) {
    case "outline-nav":
      activePanelComponent = <OutlineNavPanel />;
      break;
    case "user-choices":
      activePanelComponent = <UserChoicesPanel />;
      break;
    default:
      break;
  }

  return activePanelComponent;
};
